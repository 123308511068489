import React from 'react';
import { UseControllerMethods } from 'contexts';
import { FormControlProps } from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { Color } from 'types';
import CheckboxControlLabel from './CheckboxControlLabel';
import FormInputControl from '../FormInputControl';

export interface FormCheckboxProps extends UseControllerMethods {
  variant?: Color;
  helpText?: string;
  error?: string;
  margin?: FormControlProps['margin'];
  [prop: string]: any;
}

const FormCheckbox = React.memo(
  ({
    field,
    meta,
    label,
    error,
    variant = 'primary',
    helpText,
    HelperTextProps,
    children,
    margin = 'normal',
    ...props
  }: FormCheckboxProps) => (
    <FormInputControl
      meta={meta}
      margin={margin}
      name={field.name}
      error={error}
      helpText={helpText}
      HelperTextProps={HelperTextProps}
    >
      <FormGroup>
        <CheckboxControlLabel
          id={field.name}
          // variant={variant}
          label={label}
          {...(props || {})}
          {...(field || {})}
        />
      </FormGroup>
    </FormInputControl>
  ),
);

export default FormCheckbox;
