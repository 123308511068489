import React from 'react';
import Typography from 'components/Typography';

interface CellProps {
  value: string;
}

const Cell = ({ value = '' }: CellProps) => (
  <Typography variant="body1" align="left">
    {value || 'N/A'}
  </Typography>
);

export default Cell;
