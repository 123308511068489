import React from 'react';
import { ReactForm } from 'contexts';
import { DependencyField, SwitchInput } from 'components/inputs';
import AccessibilityPopup from './AccessibilityPopover';

const AccessibilityMode = React.memo(() => (
  <ReactForm onSubmit={() => null}>
    <SwitchInput
      color="secondary"
      label="Accessibility Mode"
      labelPlacement="start"
      name="accessibility_mode"
    />
    <DependencyField fields={['accessibility_mode']}>
      <AccessibilityPopup />
    </DependencyField>
  </ReactForm>
));

export default AccessibilityMode;
