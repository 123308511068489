import { MouseEvent } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { ReactComponent as TickIcon } from 'assets/tick.svg';
import { GetOptionProps, GroupedOption } from './ChoicesView';

export interface SelectOptionsProps {
  index: number;
  selectedGroup: GroupedOption;
  option: Record<string, any>;
  getOptionProps: GetOptionProps;
  getChoiceText: (choice: any) => string;
  closeInput: () => void;
}

const SelectOptions = ({
  index,
  selectedGroup,
  option,
  getOptionProps,
  getChoiceText,
  closeInput,
}: SelectOptionsProps) => {
  const { onClick, ...props } = getOptionProps({
    index: selectedGroup.index + index,
    option: option,
  });

  const onSelectOption = (event: MouseEvent) => {
    closeInput();
    onClick(event);
  };

  return (
    <ListItem button {...props} onClick={onSelectOption}>
      <ListItemText primary={getChoiceText(option)} />
      {props['aria-selected'] && (
        <ListItemSecondaryAction>
          <TickIcon />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default SelectOptions;
