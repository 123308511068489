import { applyMiddleware, createStore as createReduxStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Persistor } from 'redux-persist/es/types';
import createSagaMiddleware from 'redux-saga';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import Rollbar from 'rollbar';

import { rollbarConfig } from 'utils';
import { History } from 'history';

import rootReducer from './reducers';
import rootSaga from './sagas';
import ImmutableReducerTransform from './ImmutableReducerTransform';
import { AuthInitialState } from './reducers/auth';
import { AccessibilityInitialState } from './reducers/accessibility';
import { BasketInitialState } from './reducers/basket';
import rollbarMiddleware from './middleware';

const persistConfig = {
  storage,
  key: 'root',
  transforms: [
    ImmutableReducerTransform(AuthInitialState, 'auth', [
      'isLoading',
      'isSubmitting',
      'errors',
    ]),
    ImmutableReducerTransform(AccessibilityInitialState, 'accessibility'),
    ImmutableReducerTransform(BasketInitialState, 'basket'),
  ],
  whitelist: ['auth', 'accessibility', 'basket'], // only auth, accessibility and basket will be persisted
};

interface CreateStoreResult {
  persistor: Persistor;
  history: History;
  store: Store;
}

const createStore = (history: History): CreateStoreResult => {
  const middlewares = [thunk, routerMiddleware(history)];
  let rollbar: Rollbar | undefined;

  if (rollbarConfig && rollbarConfig.accessToken) {
    rollbar = new Rollbar(rollbarConfig);
    middlewares.push(rollbarMiddleware(rollbar));
  }

  const sagaMiddleware = createSagaMiddleware();
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const middleware = applyMiddleware(sagaMiddleware, ...middlewares);
  const enhancers =
    process.env.NODE_ENV !== 'production'
      ? composeWithDevTools(middleware)
      : middleware;
  const store = createReduxStore(persistedReducer, enhancers);
  const persistor = persistStore(store);
  [rootSaga, routinePromiseWatcherSaga].forEach((saga) =>
    sagaMiddleware.run(saga),
  );
  return { history, store, persistor };
};

export default createStore;
