import Checkbox from 'components/inputs/Checkbox/Checkbox';
import React from 'react';
import { Hooks } from 'react-table';

const useSelection =
  <T extends object>(selectable: boolean) =>
  (hooks: Hooks<T>) => {
    if (selectable) {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: '_selector',
          disableResizing: true,
          minWidth: 36,
          width: 36,
          maxWidth: 36,
          Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const props = getToggleAllRowsSelectedProps();
            return (
              <Checkbox
                noPadding
                onChange={props.onChange}
                checked={props.checked}
              />
            );
          },
          Cell: ({ row }: any) => {
            const props = row.getToggleRowSelectedProps();
            return (
              <Checkbox onChange={props.onChange} checked={props.checked} />
            );
          },
        },
        ...columns.map((column) => ({ width: 100, ...column })),
      ]);
    }
  };

export default useSelection;
