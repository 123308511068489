import React, { ElementType } from 'react';
import { Location } from 'history';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import { Navigation, NavigationItem } from 'types';
import { ReactComponent as CollapseIcon } from 'assets/arrow_down.svg';
import DevelopmentRibbon from 'components/DevelopmentRibbon';
import LinkWrapper from './LinkWrapper';
import useNavItem from './useNavItem';

export interface NavListItemProps extends ListItemProps {
  spacing?: number;
  component: ElementType;
}

const NavListItem = styled(ListItem)<NavListItemProps>(
  ({ spacing = 0, theme }) => ({
    color: 'inherit',
    padding: theme.spacing(0),
    justifyContent: 'center',
    height: theme.spacing(5.5),
    marginTop: theme.spacing(5 * spacing),
  }),
);

const NavListItemBtn = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.75, 1),
}));

const NavListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  minWidth: 32,
  padding: 0,
  marginRight: theme.spacing(1),
}));

export interface NavItemProps {
  location: Location;
  item: NavigationItem;
  nested: boolean;
  pushNavigation: (nav: Navigation) => void;
  extendedMode: boolean;
}

const NavItem = ({
  extendedMode,
  nested,
  location,
  item,
  pushNavigation,
}: NavItemProps) => {
  const Icon = item.icon;
  const { isActive, isOpen, hasItems, handleClick } = useNavItem({
    location,
    item,
    pushNavigation,
  });

  const iconClass = React.useMemo(() => {
    return nested
      ? {
          fill: '#4C4C5A',
        }
      : {
          fill: '#BDBDBD',
        };
  }, [nested]);

  return (
    <React.Fragment>
      <NavListItem
        component="li"
        id={item.anchor}
        key={item.title}
        spacing={item.spacing}
        selected={isActive(item.link)}
        onClick={handleClick}
      >
        <NavListItemBtn>
          <LinkWrapper anchor={item.anchor} to={item.link || ''}>
            {Icon && (
              <NavListItemIcon>
                {item.inDevelopment ? (
                  <DevelopmentRibbon>
                    <Icon
                      style={
                        isActive(item.link)
                          ? {
                              fill: '#EA5D4B',
                            }
                          : iconClass
                      }
                    />
                  </DevelopmentRibbon>
                ) : (
                  <Icon
                    style={
                      isActive(item.link)
                        ? {
                            fill: '#EA5D4B',
                          }
                        : iconClass
                    }
                  />
                )}
              </NavListItemIcon>
            )}
            {extendedMode && <ListItemText primary={item.title} />}
            {extendedMode && hasItems && <CollapseIcon />}
          </LinkWrapper>
        </NavListItemBtn>
      </NavListItem>
      {hasItems && (
        <Collapse in={isOpen} timeout="auto">
          <List
            disablePadding
            component="ul"
            key={`${item.title}-list`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {item.subItems?.map((subItem, idx: number) => (
              <NavItem
                key={idx}
                nested={nested}
                pushNavigation={pushNavigation}
                extendedMode={extendedMode}
                location={location}
                item={subItem}
              />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default compose<
  NavItemProps,
  Pick<NavItemProps, 'item' | 'pushNavigation'> & Partial<NavItemProps>
>(withRouter)(NavItem);
