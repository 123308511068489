import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { BreachPrimary } from 'types';

export interface BreachPrimaryPayload extends PageQuery {
  breach_primary?: string;
  id: number;
}

const list = (query: BreachPrimaryPayload, token: string) =>
  request<Empty, PageResponse<BreachPrimary>, Empty>(
    `wm-objects/breach-primary/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const breachesPrimaryServices = {
  list,
};

export default breachesPrimaryServices;
