import { useContext, useEffect } from 'react';
import CollapseContext from './CollapseContext';

const useCollapseToggle = (id: string, defaultValue?: boolean) => {
  const { append, toggle, isExpanded } = useContext(CollapseContext);
  useEffect(() => {
    append && append(id, defaultValue);
  }, [id, defaultValue, append]);

  return {
    toggle: toggle ? toggle(id) : undefined,
    expanded: isExpanded ? isExpanded(id) : false,
  };
};

export default useCollapseToggle;
