import Grid from 'components/Grid';
import Pagination from 'components/Pagination';
import { PaginationProps as SamplePaginationProps } from 'types';

export interface TablePaginationProps {
  pageCount: number;
  pageIndex: number;
  gotoPage: (page: number) => void;
  PaginationProps?: SamplePaginationProps;
}

const TablePagination = ({
  pageCount,
  pageIndex,
  gotoPage,
  PaginationProps,
}: TablePaginationProps) => (
  <Grid
    xs
    item
    container
    stickyBottom
    alignItems="flex-end"
    justifyContent="center"
  >
    {pageCount && pageCount > 1 ? (
      <Pagination
        pageCount={pageCount}
        currentPage={pageIndex + 1}
        onPageClick={({ page }) => gotoPage(page - 1)}
        {...PaginationProps}
      />
    ) : null}
  </Grid>
);

export default TablePagination;
