import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import { ReducerState } from 'store/reducers';
import { authSelectors } from 'store/selectors';
import { JWTToken } from 'types';

interface AuthData {
  email: string;
  token: JWTToken;
  isAuthenticated: boolean;
}

interface UsePermissionCheckProps {
  check: (authData: AuthData) => boolean;
  redirectTo: string | ((token: JWTToken, location: Location) => string);
}

const usePermissionCheck = ({ check, redirectTo }: UsePermissionCheckProps) => {
  const history = useHistory();
  const location = useLocation();
  const authData = useSelector((state: ReducerState) => ({
    email: authSelectors.email(state) || '',
    token: authSelectors.jwtToken(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
  }));

  useEffect(() => {
    if (authData.isAuthenticated && !check(authData)) {
      history.push(
        typeof redirectTo === 'string'
          ? redirectTo
          : redirectTo(authData.token, location),
      );
    } else if (!authData.isAuthenticated) {
      history.replace('/sign-in');
    }
  }, [history, authData, check, redirectTo, location]);
};

export default usePermissionCheck;
