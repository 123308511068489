import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { GroupedCountry } from './types';

const Flag = styled('img')(() => ({
  width: 24,
  height: 'auto',
}));

interface CountryOptionProps {
  country: GroupedCountry;
  shortRender?: boolean;
}

const onError = (evt: React.SyntheticEvent<HTMLImageElement>) => {
  (evt.target as HTMLImageElement).style.display = 'none';
};

const CountryOption = ({ country, shortRender }: CountryOptionProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Flag
          src={country.flag_base64}
          alt={country.country}
          onError={onError}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1">+ {country.calling_code}</Typography>
      </Grid>
      {!shortRender && (
        <Grid item>
          <Typography variant="body1">{country.country}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CountryOption;
