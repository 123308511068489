const parseJwtToken = (token: string) => {
  const tokenBody = token.split('.');
  const signature = token[tokenBody.length - 1];

  const [header, payload] = tokenBody
    .slice(0, -1)
    .map((part) => JSON.parse(window.atob(part)));

  return { header, payload, signature };
};

export default parseJwtToken;
