import React from 'react';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';

export interface AvatarProps extends Omit<MuiAvatarProps, 'children'> {
  color?: string;
  content?: React.ReactNode;
}

const Avatar = ({
  color = '#757575',
  content = '',
  style,
  ...props
}: AvatarProps) => (
  <MuiAvatar style={{ ...style, backgroundColor: color }} {...props}>
    {content}
  </MuiAvatar>
);

export default Avatar;
