import React from 'react';
import debounce from 'lodash/debounce';
import { useInput } from 'contexts';
import Grid from 'components/Grid';
import Chip from 'components/chip';
import Loader from 'components/loader';
import Autocomplete, { AutocompleteProps } from './Autocomplete';
import useChoices from '../useChoices';

type ArrayAutocompleteProps = Omit<AutocompleteProps, 'many'>;

const ArrayAutocomplete = ({
  name,
  rules,
  filters,
  defaultValue = [],
  choices = [],
  optionValue = 'id',
  optionText = 'name',
  isLoading,
  ChipProps,
  debounceTimeout = 5e2,
  ...props
}: ArrayAutocompleteProps) => {
  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
  });

  const { field, meta, error } = useInput({ name, rules, defaultValue });
  const getSuggestionFromValue = React.useCallback(
    (value: unknown) => {
      return choices.find(
        (item: Record<string, any>) =>
          getChoiceValue(item).toString() === (value as string).toString(),
      );
    },
    [choices, getChoiceValue],
  );

  const selectedItems = React.useMemo(
    () =>
      field.value && Array.isArray(field.value)
        ? field.value.map(getSuggestionFromValue)
        : [],
    [getSuggestionFromValue, field],
  );

  const handleDelete = React.useCallback(
    (item: unknown) =>
      debounce(() => {
        const newSelectedItems = [...selectedItems];
        newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
        field.onChange(newSelectedItems.map(getChoiceValue));
      }, debounceTimeout),
    [debounceTimeout, selectedItems, field, getChoiceValue],
  );

  return (
    <React.Fragment>
      <Autocomplete
        many
        name={name}
        field={field}
        meta={meta}
        error={error}
        filters={filters}
        choices={choices}
        optionValue={optionValue}
        optionText={optionText}
        {...props}
      />
      {isLoading ? (
        <Loader />
      ) : (
        selectedItems.length > 0 && (
          <Grid container spacing={2}>
            {selectedItems.map((item: Record<string, any>, idx: number) => (
              <Grid item key={`${idx}`}>
                <Chip
                  onDelete={handleDelete(item)}
                  label={getChoiceText(item)}
                  {...ChipProps}
                />
              </Grid>
            ))}
          </Grid>
        )
      )}
    </React.Fragment>
  );
};

export default ArrayAutocomplete;
