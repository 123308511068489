import Immutable from 'immutable';
import { ResourceRecord, ResourceReducer, Note, Errors } from 'types';
import resources from 'store/resources';
import { createResourceReducer } from './utils';
import { updateDocumentNotesAction } from '../actions/documentNote';

export type NoteState = ResourceRecord<Note>;

export const InitialNoteState = Immutable.Record<ResourceReducer<Note>>({
  filters: {},
  sort: [],
  isLoading: false,
  isManyLoading: false,
  count: 0,
  pagination: {
    perPage: 25,
    page: 1,
  },
  currentItem: null,
  autocomplete: [],
  results: [],
  errors: {},
});

const updateDocumentNotesActionFailure = (state: NoteState, errors: Errors) =>
  state.merge({
    isLoading: false,
    errors,
  });

const updateDocumentNotesActionTrigger = (state: NoteState) =>
  state.merge({
    isLoading: true,
    errors: {},
    results: [],
  });

const updateDocumentNotesActionSuccess = (state: NoteState, note: Note[]) =>
  state.merge({
    isLoading: false,
  });

export default createResourceReducer({
  resource: resources.NOTES,
  initialState: new InitialNoteState(),
  handlers: {
    [updateDocumentNotesAction.TRIGGER]: updateDocumentNotesActionTrigger,
    [updateDocumentNotesAction.FAILURE]: updateDocumentNotesActionFailure,
    [updateDocumentNotesAction.SUCCESS]: updateDocumentNotesActionSuccess,
  },
});
