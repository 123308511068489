import {
  Fragment,
  Children,
  ReactNode,
  cloneElement,
  ReactElement,
} from 'react';
import MuiButtonGroup, {
  buttonGroupClasses,
  ButtonGroupProps,
} from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import MuiPopper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import { styled, Theme } from '@mui/material/styles';
import { Color, Handler } from 'types';
import { Button, ButtonProps } from 'components/buttons';
import useInteractionGroupButton from './useInteractionGroupButton';

export interface Options {
  value: string;
  children: ReactElement;
}

export interface GroupButtonProps
  extends Omit<ButtonGroupProps, 'color' | 'onClick'> {
  render?: ReactNode;
  id?: string;
  children: ReactNode;
  color?: Color;
  onClick: (selectValue: string) => Handler | undefined;
}

const Popper = styled(MuiPopper)({
  zIndex: 1,
});

const ArrowDropDown = styled(Button)({
  width: 'auto',
});

const Action = styled(Button, {
  shouldForwardProp: (propName: string) => propName !== 'singleChildren',
})<ButtonProps>(
  ({ theme, disabled, singleChildren }: ButtonProps & { theme: Theme }) => ({
    ...(disabled && {
      border: `0 solid ${theme.palette.grey.A100} !important`,
    }),
    ...(singleChildren && {
      padding: theme.spacing(1.8),
    }),
    width: 'auto',
    '&:active': {
      fontWeight: 'normal',
    },
  }),
);

const ButtonGroup = styled(MuiButtonGroup)<Partial<GroupButtonProps>>(
  ({ theme, ...ownerState }) => ({
    '& > button': {
      minWidth: 40,
      ...(ownerState.variant === 'contained' && {
        boxShadow: theme.shadows[2],
      }),
      ...(ownerState.disableElevation && {
        boxShadow: 'none',
      }),
    },
    '& > *:not(:first-of-type)': {
      ...(ownerState.orientation === 'horizontal' && {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }),
      ...(ownerState.orientation === 'vertical' && {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
      }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.orientation === 'horizontal' && {
          marginLeft: -1,
        }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.orientation === 'vertical' && {
          marginTop: -1,
        }),
    },
    '& > *:not(:last-of-type)': {
      ...(ownerState.orientation === 'horizontal' && {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }),
      ...(ownerState.orientation === 'vertical' && {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      }),
      ...(ownerState.variant === 'text' &&
        ownerState.orientation === 'horizontal' && {
          borderRight: `1px solid ${
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.23)'
              : 'rgba(255, 255, 255, 0.23)'
          }`,
        }),
      ...(ownerState.variant === 'text' &&
        ownerState.orientation === 'vertical' && {
          borderBottom: `1px solid ${
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.23)'
              : 'rgba(255, 255, 255, 0.23)'
          }`,
        }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.orientation === 'horizontal' && {
          borderRightColor: 'transparent',
        }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.orientation === 'vertical' && {
          borderBottomColor: 'transparent',
        }),
      ...(ownerState.variant === 'contained' &&
        ownerState.orientation === 'horizontal' && {
          borderRight: `1px solid ${theme.palette.grey[400]}`,
          [`&.${buttonGroupClasses.disabled}`]: {
            borderRight: `1px solid ${theme.palette.action.disabled}`,
          },
        }),
      ...(ownerState.variant === 'contained' &&
        ownerState.orientation === 'vertical' && {
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
          [`&.${buttonGroupClasses.disabled}`]: {
            borderBottom: `1px solid ${theme.palette.action.disabled}`,
          },
        }),
    },
  }),
);

const GroupButton = ({
  render,
  children,
  onClick,
  id = 'group-button',
  orientation = 'horizontal',
  color = 'primary',
  variant = 'outlined',
  ...props
}: GroupButtonProps) => {
  const {
    anchor,
    open,
    selectedValue,
    handleMenuItemClick,
    handleClose,
    handleToggle,
    getPropsAction,
  } = useInteractionGroupButton({ id, children, onClick });

  const ownerState = {
    id,
    orientation,
    variant,
    ...props,
  };

  if (!selectedValue) return null;

  const propsAction = getPropsAction();
  const hasMultipleModes = Children.toArray(children).length > 1;

  return render ? (
    <ButtonGroup ref={anchor} {...ownerState}>
      {render}
    </ButtonGroup>
  ) : (
    <Fragment>
      <ButtonGroup size="small" ref={anchor} {...ownerState}>
        <Action
          singleChildren={!hasMultipleModes}
          variant={variant}
          color={color}
          size="small"
          {...propsAction}
        >
          {propsAction.label}
        </Action>
        {hasMultipleModes && (
          <ArrowDropDown
            variant={variant}
            color={color}
            size="small"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </ArrowDropDown>
        )}
      </ButtonGroup>
      <Popper
        transition
        disablePortal
        open={open}
        anchorEl={anchor.current}
        role={undefined}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {Children.map(children, (child, index) =>
                    child
                      ? cloneElement(child as ReactElement, {
                          ...(child as ReactElement).props,
                          key: index,
                          selected:
                            (child as ReactElement).props.value ===
                            selectedValue,
                          onModeClick: () =>
                            handleMenuItemClick(
                              (child as ReactElement).props.value,
                            ),
                        })
                      : null,
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default GroupButton;
