import React, { Suspense } from 'react';
import { hotjar } from 'react-hotjar';
import { Redirect, Route, Switch } from 'react-router-dom';
import LogoLoader from 'components/LogoLoader';
import NotFound from 'components/NotFound';
import PrivateRoute from 'routingComponents/PrivateRoute';
import Auth0Route from 'routingComponents/Auth0Route';
import AuthRoute from 'routingComponents/AuthRoute';
import { Container } from 'components/ui';

import config from 'config';
import { JWTToken } from 'types';

const ActivateUserAccount = React.lazy(() => import('./ActivateUserAccount'));
const Auth0Login = React.lazy(() => import('./Auth0Login'));
const SignIn = React.lazy(() => import('./SignIn'));
const MfaRegistration = React.lazy(() => import('./MfaRegistration'));
const MfaSignIn = React.lazy(() => import('./MfaSignIn'));
const StaticCodes = React.lazy(() => import('./StaticCodes'));
const SignUp = React.lazy(() => import('./SignUp'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const SetPassword = React.lazy(() => import('./SetPassword'));
const DashboardPage = React.lazy(() => import('./Dashboard'));
const SearchPage = React.lazy(() => import('./Search'));
const SavedSearchPage = React.lazy(() => import('./SavedSearches'));
const FoldersPage = React.lazy(() => import('./Folders'));
const GroupsAdminPage = React.lazy(() => import('./GroupsAdmin'));
const PivotTablePage = React.lazy(() => import('./PivotReport'));
const PivotTemplatePage = React.lazy(() => import('./ReportTemplates'));
const OrganisationsAdminPage = React.lazy(() => import('./OrganisationsAdmin'));
const WebhooksPage = React.lazy(() => import('./Webhooks'));
const PolicyMappingPage = React.lazy(() => import('./PolicyMapping'));
const RegulatoryCalendarPage = React.lazy(() => import('./RegulatoryCalendar'));
const DocumentBundlePage = React.lazy(() => import('./DocumentBundle'));
const UsersAdminPage = React.lazy(() => import('./UsersAdmin'));
const UserOrganizationPage = React.lazy(() => import('./UserOrganization'));
const DocumentViewerPage = React.lazy(() => import('./DocumentViewer'));
const WorkflowsPage = React.lazy(() => import('./Workflows'));
const WorkflowReportPage = React.lazy(() => import('./WorkflowReport'));
const AccessibilityPage = React.lazy(() => import('./Accessibility'));
const ConfirmEmailPage = React.lazy(() => import('./ConfirmEmail'));
const TasksPage = React.lazy(() => import('./Tasks'));
const UploadPage = React.lazy(() => import('./UploadPage'));
const DocumentJsonViewerPage = React.lazy(() => import('./DocumentJsonViewer'));
const OrganizationLogsPage = React.lazy(() => import('./OrganizationLogs'));
const DocumentComparison = React.lazy(() => import('./DocumentComparison'));

const limitedAccess = (email: string, jwtToken: JWTToken) =>
  !jwtToken.payload.file_uploads_access_only;

const limitedAccessOnly = (email: string, jwtToken: JWTToken) =>
  jwtToken.payload.file_uploads_access_only ||
  jwtToken.payload.is_superuser ||
  jwtToken.payload.is_staff;

const profileAccess = (email: string, jwtToken: JWTToken) =>
  limitedAccess(email, jwtToken) && !jwtToken.payload.is_sso_only;

const legacyFeatures = (email: string, jwtToken: JWTToken) =>
  !jwtToken.payload.is_sso_only ||
  jwtToken.payload.is_superuser ||
  jwtToken.payload.is_staff;

const hasHorizonScanningAccess = (email: string, jwtToken: JWTToken) =>
  !jwtToken.payload.file_uploads_access_only &&
  (jwtToken.payload.horizon_scanning ||
    // Temporary fix to support older tokens, to be removed after 38 days
    jwtToken.payload.horizontal_scanning ||
    jwtToken.payload.is_staff ||
    jwtToken.payload.is_superuser);

const Root = () => {
  React.useEffect(() => {
    if (config.hotJar.id && config.hotJar.sv) {
      hotjar.initialize(parseInt(config.hotJar.id), parseInt(config.hotJar.sv));
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Suspense fallback={<LogoLoader />}>
        <Switch>
          <Auth0Route path="/auth0" title="Auth0 Sign In">
            <Auth0Login />
          </Auth0Route>
          <AuthRoute path="/sign-in" title="Sign In">
            <SignIn />
          </AuthRoute>
          <AuthRoute path="/mfa-registration" title="MFA Sign In">
            <MfaRegistration />
          </AuthRoute>
          <AuthRoute path="/mfa-sign-in" title="MFA Sign In">
            <MfaSignIn />
          </AuthRoute>
          <AuthRoute path="/static-codes" title="Static Codes">
            <StaticCodes />
          </AuthRoute>
          <AuthRoute path="/sign-up" title="Sign Up">
            <SignUp />
          </AuthRoute>
          <AuthRoute path="/confirm" title="Activate User Account">
            <ActivateUserAccount />
          </AuthRoute>
          <AuthRoute
            path="/confirm-email"
            title="Activate User Account"
            urlParam="token"
            redirectTo="/sign-in"
          >
            <ConfirmEmailPage />
          </AuthRoute>
          <AuthRoute path="/reset-password" title="Reset Password">
            <ResetPassword />
          </AuthRoute>
          <AuthRoute path="/reset" title="Forgot Password">
            <SetPassword />
          </AuthRoute>
          <PrivateRoute
            path="/dashboard"
            title="Welcome to AscentHorizon!"
            test={limitedAccess}
          >
            <DashboardPage />
          </PrivateRoute>
          <PrivateRoute path="/search" title="Search" test={limitedAccess}>
            <SearchPage />
          </PrivateRoute>
          <PrivateRoute
            path="/saved-searches"
            title="Saved Searches"
            test={hasHorizonScanningAccess}
          >
            <SavedSearchPage />
          </PrivateRoute>
          <PrivateRoute
            path="/folders"
            title="Folders"
            test={hasHorizonScanningAccess}
          >
            <FoldersPage />
          </PrivateRoute>
          <PrivateRoute
            path="/document-viewer/:id"
            title="Document Viewer"
            test={limitedAccess}
          >
            <DocumentViewerPage />
          </PrivateRoute>
          <PrivateRoute
            path="/organisations"
            title="Organisations"
            permissions={['is_superuser', 'is_org_creator', 'is_staff']}
          >
            <OrganisationsAdminPage />
          </PrivateRoute>
          <PrivateRoute
            path="/tasks"
            permissions={['is_superuser', 'is_staff', 'are_workflows_allowed']}
            title="Tasks"
          >
            <TasksPage />
          </PrivateRoute>
          <PrivateRoute
            path="/regulatory-calendar"
            title="Regulatory Calendar"
            test={hasHorizonScanningAccess}
          >
            <RegulatoryCalendarPage />
          </PrivateRoute>
          <PrivateRoute
            path="/document-bundles"
            title="Document Bundles"
            permissions={['is_staff', 'is_superuser']}
          >
            <DocumentBundlePage />
          </PrivateRoute>
          <PrivateRoute
            path="/workflow-report"
            permissions={['is_superuser', 'is_staff', 'are_workflows_allowed']}
            title="Workflow based report"
          >
            <WorkflowReportPage />
          </PrivateRoute>
          <PrivateRoute
            path="/users"
            title="Users"
            permissions={['is_superuser', 'is_staff']}
          >
            <UsersAdminPage />
          </PrivateRoute>
          <PrivateRoute
            path="/groups"
            title="Groups"
            permissions={['is_superuser', 'is_staff']}
          >
            <GroupsAdminPage />
          </PrivateRoute>
          <PrivateRoute
            path="/pivot-table"
            title="Pivot Table"
            permissions={['is_superuser', 'is_staff']}
          >
            <PivotTablePage />
          </PrivateRoute>
          <PrivateRoute
            path="/report-templates"
            title="Report Templates"
            permissions={['is_superuser', 'is_staff', 'are_workflows_allowed']}
          >
            <PivotTemplatePage />
          </PrivateRoute>
          <PrivateRoute
            path="/workflows"
            title="Workflows"
            permissions={['is_superuser', 'is_staff', 'are_workflows_allowed']}
          >
            <WorkflowsPage />
          </PrivateRoute>
          <PrivateRoute
            path="/organisation"
            title="Organisation"
            permissions={['is_org_admin']}
          >
            <UserOrganizationPage />
          </PrivateRoute>
          <PrivateRoute path="/upload" test={limitedAccessOnly}>
            <UploadPage />
          </PrivateRoute>
          <PrivateRoute
            path="/accessibility"
            title="Accessibility"
            test={profileAccess}
          >
            <AccessibilityPage />
          </PrivateRoute>
          <PrivateRoute
            path="/webhooks"
            title="Webhooks"
            permissions={['is_org_admin', 'is_superuser', 'is_staff']}
            test={legacyFeatures}
          >
            <WebhooksPage />
          </PrivateRoute>
          <PrivateRoute
            path="/policy-mapping"
            permissions={['is_staff', 'is_superuser']}
            title="Policy Mapping"
          >
            <PolicyMappingPage />
          </PrivateRoute>
          <PrivateRoute
            path="/document-json-viewer"
            title="Document Json Viewer"
            permissions={['is_staff', 'is_superuser']}
          >
            <DocumentJsonViewerPage />
          </PrivateRoute>
          <PrivateRoute
            path="/organisation-logs"
            title="Logs"
            permissions={['is_org_admin', 'is_superuser', 'is_staff']}
            test={legacyFeatures}
          >
            <OrganizationLogsPage />
          </PrivateRoute>
          <PrivateRoute
            path="/documents-comparison"
            title="Document Comparison"
            test={limitedAccess}
          >
            <DocumentComparison />
          </PrivateRoute>
          <Route exact path="/">
            <Redirect to="/sign-in" />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Container>
  );
};

export default Root;
