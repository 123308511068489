import React from 'react';

import AscentLogo from 'assets/ascent-Icon-blue_4x.png';

const LogoLoader = () => (
  <div className="full-page-loader">
    <img width="192" src={AscentLogo} alt="Ascent Reg Tech logo" />
  </div>
);

export default LogoLoader;
