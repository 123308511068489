import Immutable from 'immutable';
import { notificationActions } from 'store/actions';

import { NotificationPayload } from 'store/actions/notification';
import resources from 'store/resources';
import { Notification, ResourceReducer } from 'types';
import { createResourceReducer } from './utils';

export type NotificationsState = Immutable.Record<
  ResourceReducer<Notification>
>;

export const NotificationInitialState = Immutable.Record<
  ResourceReducer<Notification>
>({
  filters: {},
  sort: [],
  isLoading: false,
  count: 0,
  inAppNotifications: Immutable.List<NotificationPayload>(),
  pagination: {
    page: 1,
    perPage: 25,
  },
  currentItem: null,
  results: [],
  autocomplete: [],
  errors: {},
});

export default createResourceReducer<Notification>({
  resource: resources.NOTIFICATIONS,
  initialState: NotificationInitialState(),
  ignoreCleanup: true,
  handlers: {
    [notificationActions.showNotificationAction.TRIGGER]: (
      state: NotificationsState,
      payload: NotificationPayload,
    ) =>
      state.set(
        'inAppNotifications',
        state.get('inAppNotifications').push(payload),
      ),
    [notificationActions.hideNotification.TRIGGER]: (
      state: NotificationsState,
    ) => state.set('inAppNotifications', state.get('inAppNotifications').pop()),
  },
});
