import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { OperationalData } from 'types';

interface DocumentComparisonPayload extends PageQuery {
  id: (string | number)[];
}

const list = (
  { sanctions, ...query }: DocumentComparisonPayload,
  token: string,
) =>
  request<DocumentComparisonPayload, PageResponse<OperationalData>, Empty>(
    'wm-objects/operational-data/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const documentComparisonServices = {
  list,
};

export default documentComparisonServices;
