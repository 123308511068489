import { MouseEvent, useEffect, useRef, useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import { useWatch } from 'contexts';
import { Button } from 'components/buttons';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import useChoices from '../useChoices';
import SelectOptions from './SelectOptions';

export interface GroupedOption extends Record<string, any> {
  key: number;
  index: number;
  group: string;
  options: Array<Record<string, any>>;
}

interface GetOptionArgs {
  index: number;
  option: Record<string, any>;
}

export type GetOptionProps = (args: GetOptionArgs) => {
  id: string;
  key: number;
  role: string;
  onMouseOver: (evt: any) => void;
  onClick: (evt: any) => void;
  onTouchStart: () => void;
  'aria-selected': boolean;
};

interface ChoicesViewProps {
  anchor?: HTMLElement | null;
  choices: Array<GroupedOption>;
  getOptionProps: GetOptionProps;
  optionText: string;
  optionValue: string;
  onChange: (event: MouseEvent, values: any) => void;
  name: string;
  closeInput: () => void;
}

const emptyGroup = {
  group: '',
  index: 0,
  key: 1,
  options: [],
} as GroupedOption;

const getSafeGroup = (groupName: string, choices: GroupedOption[]) => {
  if (!choices.length) {
    return emptyGroup;
  }
  const group = choices.find((choice) => choice.group === groupName);

  if (!group) {
    return choices[0];
  }

  return group;
};

const StyledPopover = styled(Popover)(() => ({
  overflow: 'hidden',
  '.MuiPaper-root': {
    overflow: 'hidden',
    maxHeight: '40vh',
    height: '100%',
    borderRadius: 0,
  },
}));

const ChoicesView = ({
  choices,
  anchor,
  optionValue,
  optionText,
  getOptionProps,
  onChange,
  name,
  closeInput,
}: ChoicesViewProps) => {
  const values = useWatch(name);
  const [currentGroup, setCurrentGroup] = useState<string>('');
  const { getChoiceText, getChoiceValue } = useChoices({
    optionValue,
    optionText,
  });
  const choicesViewOpen = useRef(false);

  const openOption = useCallback(
    (option: GroupedOption) => () => {
      setCurrentGroup(option.group);
    },
    [],
  );

  const selectedGroup = getSafeGroup(currentGroup, choices);

  useEffect(() => {
    if (anchor !== null && !choicesViewOpen.current) {
      choicesViewOpen.current = true;
    }
  }, [anchor]);

  const getOptions = () => {
    if (isEmpty(values)) return [];
    return choices.reduce(
      (optionValues: Record<string, unknown>[], choice: GroupedOption) => {
        optionValues.push(
          ...choice.options.reduce(
            (
              options: Record<string, unknown>[],
              option: Record<string, unknown>,
            ) => {
              if (values.indexOf(getChoiceValue(option)) !== -1) {
                options.push(option);
              }
              return options;
            },
            [],
          ),
        );
        return optionValues;
      },
      [],
    );
  };

  const selectAll = (event: MouseEvent) => {
    const currentOptions = getOptions();
    onChange(event, [
      ...new Set([...currentOptions, ...selectedGroup.options]),
    ]);

    closeInput();
  };

  const deselectAll = (event: MouseEvent) => {
    const currentValues = getOptions();
    const groupOptions = new Set(selectedGroup.options);

    onChange(
      event,
      [...new Set(currentValues)].filter((option) => !groupOptions.has(option)),
    );

    closeInput();
  };

  return (
    <StyledPopover
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      elevation={0}
      container={document.body}
      open={anchor !== null}
      onClose={closeInput}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {isEmpty(choices) ? (
        <Grid
          container
          fullHeight
          minWidth={260}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography>No results</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container fullHeight>
          <Grid fullHeight container item xs={6} overflow="y-only">
            <List disablePadding>
              {choices.map((choice: GroupedOption) => (
                <ListItem
                  button
                  key={choice.key}
                  onClick={openOption(choice)}
                  selected={selectedGroup.group === choice.group}
                >
                  <ListItemText primary={choice.group} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid fullHeight item xs={6} overflow="y-only">
            {!isEmpty(choices) && anchor !== null && (
              <Grid container wrap="nowrap">
                <Grid item>
                  <Button
                    sx={{ paddingTop: 1 }}
                    onClick={selectAll}
                    variant="text"
                    size="small"
                  >
                    Select all
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ paddingTop: 1 }}
                    onClick={deselectAll}
                    variant="text"
                    size="small"
                  >
                    Deselect all
                  </Button>
                </Grid>
              </Grid>
            )}

            <List disablePadding>
              {selectedGroup.options.map((option, index) => (
                <SelectOptions
                  key={index}
                  index={index}
                  selectedGroup={selectedGroup}
                  option={option}
                  getOptionProps={getOptionProps}
                  getChoiceText={getChoiceText}
                  closeInput={closeInput}
                />
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </StyledPopover>
  );
};

export default ChoicesView;
