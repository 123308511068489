import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { SavedSearch } from 'types';

const list = (query: PageQuery, token: string) =>
  request<PageQuery, PageResponse<SavedSearch>, Empty>(
    'search/saved-search/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const create = (payload: SavedSearch, token: string) =>
  request<SavedSearch, SavedSearch, Empty>(
    `search/saved-search/`,
    {
      payload,
      method: 'POST',
    },
    token,
  );

interface DetailPayload {
  id: number | string;
}

const detail = ({ id }: DetailPayload, token: string) =>
  request<SavedSearch, SavedSearch, Empty>(
    `search/saved-search/${id}`,
    {
      method: 'GET',
    },
    token,
  );

const update = (payload: SavedSearch, token: string) =>
  request<SavedSearch, SavedSearch, Empty>(
    `search/saved-search/${payload.id}/`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );

const share = ({ id, ...payload }: SavedSearch, token: string) =>
  request<Partial<SavedSearch>, Empty, Empty>(
    `search/saved-search/${id}/share/`,
    {
      method: 'POST',
      payload,
    },
    token,
  );

const remove = ({ id }: DetailPayload, token: string) =>
  request<SavedSearch, Empty, Empty>(
    `search/saved-search/${id}/`,
    {
      method: 'DELETE',
    },
    token,
  );

const savedSearchServices = {
  list,
  create,
  detail,
  update,
  share,
  remove,
};

export default savedSearchServices;
