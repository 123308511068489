import React from 'react';
import { ControllerFieldState as InputState } from 'react-hook-form';

import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as TickIcon } from 'assets/tick.svg';

type ValidationAdornmentProps = InputState;

const ValidationAdornment = ({
  isTouched,
  isDirty,
  invalid,
}: ValidationAdornmentProps) => (
  <InputAdornment position="start">
    {Boolean(isTouched && invalid) && <CloseIcon />}
    {Boolean(isTouched && !invalid) && <TickIcon />}
  </InputAdornment>
);

export default ValidationAdornment;
