import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import MuiBox, { BoxProps } from '@mui/material/Box';
import Grid, { GridProps } from 'components/Grid';
import Typography from 'components/Typography';

import { colorsList } from 'containers/Accessibility/components/SelectColorForm';

export interface TopBarProps extends GridProps {
  topBar: boolean;
}

const RootGrid = styled(Grid, {
  shouldForwardProp: (propName: string) => propName !== 'topBar',
})<TopBarProps>(({ theme, topBar }) => ({
  padding: topBar ? theme.spacing(2) : theme.spacing(4),
  boxShadow: '0px 8px 40px 4px rgba(0, 0, 0, 0.06)',
  height: '100%',
  border: '1px solid transparent',
  justifyContent: 'center',
}));

const Box = styled(MuiBox)(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: 4,
  background: alpha(theme.palette.info.light, 0.1),
  '& >*': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const TitleGrid = styled(Grid)<TopBarProps>(({ topBar, theme }) => ({
  padding: topBar ? theme.spacing(0, 0, 1) : theme.spacing(1),
}));

export interface ColorBoxProps extends BoxProps {
  topBar?: boolean;
}

const ColorBox = styled(MuiBox)<ColorBoxProps>(({ topBar, theme }) => ({
  width: topBar ? theme.spacing(2) : theme.spacing(5.5),
  height: topBar ? theme.spacing(2) : theme.spacing(5.5),
  borderRadius: '50%',
}));

export interface ColorCardProps {
  item: typeof colorsList[number];
  isTopBar?: boolean;
}

const ColorCard = ({ item, isTopBar = false }: ColorCardProps) => {
  return (
    <RootGrid container spacing={2} topBar={isTopBar}>
      {!isTopBar && (
        <Grid item xs={12} md={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography component="p">A</Typography>
          </Box>
        </Grid>
      )}
      <TitleGrid item xs={12} md={isTopBar ? 12 : 10} topBar={isTopBar}>
        <Typography>{item.title}</Typography>
      </TitleGrid>
      <Grid
        container
        spacing={2}
        justifyContent={isTopBar ? 'flex-start' : 'center'}
      >
        {item.colors.map((i, idx) => (
          <Grid item key={idx}>
            <ColorBox style={{ backgroundColor: i }} />
          </Grid>
        ))}
      </Grid>
    </RootGrid>
  );
};

export default ColorCard;
