import React from 'react';
import { useInput } from 'contexts';
import Autocomplete, { AutocompleteProps } from './Autocomplete';

const ControlledAutocomplete = ({
  name,
  many,
  rules,
  defaultValue = many ? [] : null,
  ...props
}: Omit<AutocompleteProps, 'field' | 'meta' | 'error'>) => {
  const { field, meta, error } = useInput({
    name,
    rules,
    defaultValue,
  });
  return (
    <Autocomplete
      name={name}
      many={many}
      field={field}
      meta={meta}
      error={error}
      {...props}
    />
  );
};

export default ControlledAutocomplete;
