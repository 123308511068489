import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import get from 'lodash/get';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { AppliedFilters, Query, ResourceRecord, Sort } from 'types';
import { useFormValuesControl } from 'contexts';
import useSelect from '../useSelect';
import usePaginationState from '../usePaginationState';
import useFilterState from '../useFilterState';

interface UseReferenceInputProps {
  resource: ResourceRecord;
  getMany: (query: Query, callback?: Function) => void;
  queryField: string;
  idField: string;
  name: string;
  debounceTimeout?: number;
  filters?: AppliedFilters;
  page?: number;
  perPage?: number;
  sort?: Sort[];
  fetchByIds?: boolean;
  onChange?: (value: Record<string, any>) => void;
  prepareChoices?: (choices: Record<string, any>[]) => Record<string, any>[];
}

export const useReferenceArrayInput = ({
  filters = {},
  fetchByIds = true,
  queryField,
  idField,
  name,
  page: initialPage = 1,
  perPage: initialPerPage = 100,
  debounceTimeout = 5e2,
  sort = [],
  getMany,
  resource,
  onChange,
  prepareChoices = (choices) => choices,
}: UseReferenceInputProps) => {
  // pagination logic
  const { pagination, setPagination, page, setPage, perPage, setPerPage } =
    usePaginationState({ page: initialPage, perPage: initialPerPage });

  // filter logic
  const { filter: currentFilters, setFilterValue } = useFilterState({
    permanentFilters: filters,
    queryField,
  });
  const prefetchLoading = React.useRef<boolean>(false);

  const isLoading = resource.get('isLoading');
  const isManyLoading = resource.get('isManyLoading');
  const count = resource.get('count');
  const choices = resource.get('results');
  const totalCountResult = resource.get('totalCountResult');

  const { getValues, setValue } = useFormValuesControl();

  const value: Array<string | number> = React.useMemo(() => {
    return getValues()[name] || [];
  }, [getValues, name]);
  const inputValue = React.useRef<Array<string | number>>(value);
  const [idsToFetch, setIdsToFetch] =
    React.useState<Array<string | number>>(value);
  const [idsToGetFromStore, setIdsToGetFromStore] = React.useState<
    Array<string | number>
  >([]);
  const selectedRecords = React.useMemo(() => {
    const mappedChoices: Record<string, Record<string, any>> = choices.reduce(
      (
        mappedData: Record<string, Record<string, any>>,
        record: Record<string, any>,
      ) => {
        return {
          ...mappedData,
          [record[idField].toString()]: record,
        };
      },
      {},
    );
    return idsToGetFromStore.map(
      (id: string | number) => mappedChoices[id.toString()],
    );
  }, [choices, idField, idsToGetFromStore]);

  React.useEffect(() => {
    // Only fetch new ids
    const newIdsToFetch = difference(value, inputValue.current);
    // Only get from store ids selected and already fetched
    const newIdsToGetFromStore = difference(value, newIdsToFetch);

    // Change states each time input values changes to avoid keeping previous values no more selected
    if (!isEqual(idsToFetch, newIdsToFetch)) {
      setIdsToFetch(newIdsToFetch);
    }
    if (!isEqual(idsToGetFromStore, newIdsToGetFromStore)) {
      setIdsToGetFromStore(newIdsToGetFromStore as Array<string>);
    }

    inputValue.current = value;
  }, [
    idsToFetch,
    idsToGetFromStore,
    value,
    setIdsToFetch,
    setIdsToGetFromStore,
  ]);

  const { onSelect, onToggleItem, onUnselectItems } = useSelect({
    name,
    setValue,
    value,
  });

  const getManyDebounce = React.useMemo(
    () =>
      debounce((query: Query) => {
        getMany(query);
        prefetchLoading.current = false;
      }, debounceTimeout),
    [debounceTimeout, getMany, prefetchLoading],
  );

  const onChangeHandler = React.useCallback(
    (value: string | number, optionValue: string) => {
      if (onChange) {
        onChange(
          choices.find(
            (record) =>
              get(record, optionValue).toString() === value.toString(),
          )!,
        );
      }
    },
    [choices, onChange],
  );

  const requestSignature = JSON.stringify({ pagination, sort, filters });
  React.useEffect(() => {
    getMany(
      { pagination, sort, filters: currentFilters },
      () => (prefetchLoading.current = false),
    );
    prefetchLoading.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestSignature]);

  React.useEffect(() => {
    if (fetchByIds && idsToFetch.length > 0) {
      getMany(
        {
          filters: { ...filters, [idField]: idsToFetch },
          pagination: { page: 1, perPage: idsToFetch.length },
          sort,
        },
        () => (prefetchLoading.current = false),
      );
    }
  }, [filters, idsToFetch, idField, getMany, fetchByIds, sort]);

  // retrieve data by currentFilters changes
  React.useEffect(() => {
    if (!isLoading && !isEmpty(currentFilters)) {
      getManyDebounce({
        filters: currentFilters,
        pagination,
        sort,
      });
      prefetchLoading.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ pagination, sort, currentFilters })]);
  return {
    name,
    // pagination
    pagination,
    setPagination,
    perPage,
    page,
    setPage,
    setPerPage,
    // sort
    // sort,
    // filters
    filters: currentFilters,
    setFilterValue,
    // data
    choices: prepareChoices(choices),
    isLoading:
      isLoading ||
      Object.values(selectedRecords).filter((value) => value === undefined)
        .length > 0,
    isPrefetchLoading: prefetchLoading.current || isManyLoading,
    count,
    totalCountResult,
    onChange: onChangeHandler,
    selectedRecords,
    onSelect,
    onToggleItem,
    onUnselectItems,
  };
};

export default useReferenceArrayInput;
