import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from 'components/Typography';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import MaterialChip, { MaterialChipProps } from './MaterialChip';
import DeleteIconButton from './DeleteIconButton';

export type Size = 'small' | 'medium';

export interface ChipProps extends MaterialChipProps {
  className?: string;
  avatar?: React.ReactElement;
  deleteIcon?: React.ReactElement;
  disabled?: boolean;
  onDelete?: React.EventHandler<any>;
  component?: React.ElementType;
  minWidth?: number;
  labelLength?: number;
  mr?: number;
}

const Chip = <T extends {}>({
  color = 'info',
  deleteIcon,
  variant = 'default',
  opacity = true,
  labelLength = 25,
  label,
  ...rest
}: ChipProps & T) => {
  const formattedLabel = React.useMemo(() => {
    if (!label || typeof label !== 'string') return label;

    const cuttingLabel =
      label.length > labelLength
        ? `${label.substring(0, labelLength)}...`
        : label;

    if (cuttingLabel && cuttingLabel !== label) {
      return (
        <Tooltip title={label}>
          <Typography variant="body2" aria-label={label}>
            {cuttingLabel}
          </Typography>
        </Tooltip>
      );
    }
    return label;
  }, [label, labelLength]);
  return (
    <MaterialChip
      {...rest}
      color={color}
      variant={variant}
      opacity={opacity}
      deleteIcon={
        deleteIcon || (
          <DeleteIconButton>
            <CloseIcon />
          </DeleteIconButton>
        )
      }
      label={formattedLabel}
    />
  );
};

export default Chip;
