import { Provider, ProviderProps } from '@rollbar/react';
import { Configuration } from 'rollbar';
import { ReactElement } from 'react';

export interface RollbarProviderProps extends ProviderProps {
  config: Configuration;
  children: ReactElement;
}

const LoggerProvider = ({ config, children }: RollbarProviderProps) => {
  return config.accessToken ? (
    <Provider config={config}>{children}</Provider>
  ) : (
    children
  );
};

export default LoggerProvider;
