import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const LoaderWrapper = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  // backgroundColor: theme.palette.grey.A200,
}));

const Loader = () => (
  <LoaderWrapper>
    <CircularProgress />
  </LoaderWrapper>
);

export default Loader;
