import Grid, { GridProps } from 'components/Grid';
import { styled } from '@mui/material/styles';

export interface DrawerGridProps extends GridProps {
  openWidth: number;
  closedWidth: number;
  opened: boolean;
}

const DrawerGrid = styled(Grid, {
  shouldForwardProp: (prop: string) => {
    return ['openWidth', 'closedWidth', 'opened'].indexOf(prop) === -1;
  },
})<DrawerGridProps>(({ theme, opened, closedWidth, openWidth }) => ({
  backgroundColor: '#363740',
  width: opened ? openWidth : closedWidth,
  padding: 8,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  ...(!opened && {
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
  }),
}));

export default DrawerGrid;
