import { ElementType, useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppliedFilters, FilterConf } from 'types';
import { NOT_FOUND } from 'contstants';
import NotFound from 'components/NotFound';
import Loader from 'components/loader';

export interface DetailControllerProps
  extends RouteComponentProps<{ id: string }> {
  filtersConf: FilterConf;
  filters: AppliedFilters;
  baseFilters?: AppliedFilters;
  isLoading: boolean;
  currentItem: any;
  id: string | number;
  component: ElementType;
  retrieve: (values: any) => any;
  update: (values: any, partial: boolean) => any;
  remove: (
    values: Record<string, any>,
    onSuccess?: Record<string, any>,
    onFailure?: Record<string, any>,
  ) => void;
  resourceName: string;
  errors: Record<string, string[]>;
  [prop: string]: any;
}

const DetailController = ({
  filtersConf,
  filters,
  isLoading,
  location,
  retrieve,
  id,
  currentItem,
  update,
  history,
  resourceName,
  component: Component,
  remove,
  match,
  baseFilters = {},
  errors,
  ...restProps
}: DetailControllerProps) => {
  const { internalFilters } = useMemo(() => {
    const { id, ...restProps } = match.params;
    return {
      internalFilters: restProps,
    };
  }, [match.params]);

  const removeItem = useCallback(
    (onSuccess: Record<string, any>, onFailure: Record<string, any>) => {
      remove(currentItem, onSuccess, onFailure);
    },
    [remove, currentItem],
  );

  const refresh = useCallback(() => {
    retrieve({ id, ...internalFilters, ...baseFilters });
  }, [retrieve, id, internalFilters, baseFilters]);

  useEffect(() => {
    if ((id === 'custom' && currentItem) || isLoading) return;

    if (!currentItem || currentItem.id.toString() !== id) {
      refresh();
    }
  }, [currentItem, retrieve, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const back = useCallback(() => {
    history.replace(`/${resourceName}`);
  }, [history, resourceName]);

  if (errors && errors['http_error'] && errors['http_error'][0] === NOT_FOUND) {
    return <NotFound />;
  }

  return currentItem ? (
    <Component
      {...restProps}
      location={location}
      match={match}
      errors={errors}
      isLoading={isLoading}
      filters={{ ...internalFilters, ...baseFilters }}
      filtersConf={filtersConf}
      currentItem={currentItem}
      onUpdate={update}
      refresh={refresh}
      remove={removeItem}
      history={history}
      back={back}
    />
  ) : (
    <Loader />
  );
};

export default withRouter(DetailController);
