import { Fragment, ReactNode } from 'react';
import { useToggle } from 'hooks';
import config from 'config';
import DebugControllerContextProvider from './DebugControllerContextProvider';

export interface DebugControllerProviderProps {
  children: ReactNode;
}

const DebugControllerProvider = ({
  children,
}: DebugControllerProviderProps) => {
  const { isMarked, toggle } = useToggle(
    JSON.parse(localStorage.getItem('onDebug') || 'false'),
  );

  const changeDebugState = (value: boolean) => {
    localStorage.setItem('onDebug', `${value}`);
    toggle(value);
  };

  return config.isProduction() ? (
    <Fragment>{children}</Fragment>
  ) : (
    <DebugControllerContextProvider
      value={{
        isDebugMode: isMarked,
        changeDebugState,
      }}
    >
      {children}
    </DebugControllerContextProvider>
  );
};

export default DebugControllerProvider;
