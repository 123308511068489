import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { BurdenClass } from 'types';

export interface BurdenClassPayload extends PageQuery {
  id: number;
  name?: string;
}

const list = (query: BurdenClassPayload, token: string) =>
  request<Empty, PageResponse<BurdenClass>, Empty>(
    `wm-objects/burden-class/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const burdenClassesServices = {
  list,
};

export default burdenClassesServices;
