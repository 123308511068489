import { styled } from '@mui/material/styles';
import MuiCircularProgress, {
  CircularProgressProps as BaseCircularProgressProps,
} from '@mui/material/CircularProgress';
import { Color } from 'types';
import { StyledComponent } from '@mui/styles';

export interface CircularProgressProps
  extends Omit<BaseCircularProgressProps, 'color'> {
  contrastMode?: boolean;
  color?: Color;
}

const CircularProgress = styled(MuiCircularProgress, {
  shouldForwardProp: (propName: string) =>
    ['contrastMode'].indexOf(propName) === -1,
})<CircularProgressProps>(({ theme, ...ownerState }) => {
  const focusColor = {
    primary: theme.palette.primary.contrastText,
    info: theme.palette.info.contrastText,
    success: theme.palette.success.contrastText,
    secondary: theme.palette.secondary.contrastText,
    warning: theme.palette.grey.A100,
    error: theme.palette.grey.A100,
    secondaryInfo: theme.palette.warning.contrastText,
  }[ownerState.color as Color];

  return {
    ...(ownerState.contrastMode && {
      color: focusColor,
    }),
  };
});

export default CircularProgress as StyledComponent<CircularProgressProps>;
