import { styled, Theme } from '@mui/material/styles';
import { endBorderStyle, startBorderStyle } from './DateRangeDay';

export interface DateRangePickerDaysProps {
  isPreviewing: boolean;
  isEndOfPreviewing: boolean;
  isStartOfPreviewing: boolean;
  isStartOfWeek: boolean;
  isEndOfWeek: boolean;
}

const DateRangePickerDays = styled('div', {
  shouldForwardProp: (propName: string) => {
    return (
      [
        'isPreviewing',
        'isEndOfPreviewing',
        'isStartOfPreviewing',
        'isStartOfWeek',
        'isEndOfWeek',
      ].indexOf(propName) === -1
    );
  },
})<DateRangePickerDaysProps>(
  ({ theme, ...ownerState }: DateRangePickerDaysProps & { theme: Theme }) => ({
    // replace default day component margin with transparent border to avoid jumping on preview
    border: '2px solid transparent',
    ...(ownerState.isPreviewing && {
      borderRadius: 0,
      border: `2px dashed ${theme.palette.divider}`,
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      ...(ownerState.isStartOfPreviewing && {
        borderLeftColor: theme.palette.divider,
        ...startBorderStyle,
      }),
      ...(ownerState.isEndOfPreviewing && {
        borderRightColor: theme.palette.divider,
        ...endBorderStyle,
      }),
    }),
    ...(ownerState.isStartOfWeek &&
      ownerState.isPreviewing && {
        ...startBorderStyle,
        borderLeftColor: theme.palette.divider,
      }),
    ...(ownerState.isEndOfWeek &&
      ownerState.isPreviewing && {
        ...endBorderStyle,
        borderRightColor: theme.palette.divider,
      }),
  }),
);

export default DateRangePickerDays;
