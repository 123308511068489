import moment from 'moment';
import config from '../config';

const validateDate = (minDate: Date | string) => (value: unknown) => {
  const date = value ? new Date(value as string) : value;
  if (value && !(date as Date).getTime()) {
    return 'Invalid Date';
  }

  if (date && minDate) {
    return (
      moment(date as Date | string).isAfter(moment(minDate)) ||
      `Date must be greater than or equal to ${moment(minDate).format(
        config.dateFormat,
      )}`
    );
  }

  return true;
};

export default validateDate;
