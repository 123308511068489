import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';

import { accessibilityActions } from 'store/actions';
import { ReducerState } from 'store/reducers';
import { accessibilitySelectors } from 'store/selectors';
import { ReactForm, ReactFormProps } from 'contexts';
import Grid from 'components/Grid';
import { RadioInput, RadioControlLabel } from 'components/inputs';
import ColorCard from 'components/Accessibility/ColorCard';
import Typography from 'components/Typography';

export const colorsList = [
  {
    title: 'Default color scheme',
    value: 'default',
    colors: ['#A60011', '#4C4C5A', '#16865B', '#FFB50D', '#EA5D4B', '#3656C1'],
  },
  {
    title: 'Achromatopsia (you see everything as a shade of black and white)',
    value: 'Achromatopsia',
    colors: ['#252525', '#4D4D4D', '#6B6B6B', '#B9B9B9', '#7A7A7A', '#575757'],
  },
  {
    title: 'Tritanopia (blue-yellow color blindness)',
    value: 'Tritanopia',
    colors: ['#A40E00', '#4C4E54', '#377F89', '#FFABB5', '#EA5A60', '#006970'],
  },
  {
    title: 'Deuteranopia (red-green color blindness)',
    value: 'Deuteranopia',
    colors: ['#684D00', '#504C5B', '#887062', '#F7B710', '#AB8343', '#0061A5'],
  },
  {
    title: 'Protanopia (red-blue-green color blindness)',
    value: 'Protanopia',
    colors: ['#5B5225', '#4C4E5B', '#7E7555', '#DBC219', '#968A5B', '#005BBF'],
  },
];

const Form = styled(ReactForm)(({ theme }) => ({
  '& label': {
    margin: theme.spacing(1),
    height: '100%',
    width: 'calc(100% - 16px)',
    '& >span:first-child': {
      display: 'none',
    },
    '& >span:last-child': {
      height: '100%',
    },
    '& .Mui-checked + span>div': {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(4.7),
}));

type SelectColorFormProps = Omit<ReactFormProps, 'children'>;

const SelectColorForm = ({ ...props }: SelectColorFormProps) => (
  <Form align="center" mode="onChange" {...props}>
    <Title variant="h3" component="p" align="center">
      <b>Do you suffer from any color deficiency?:</b>
    </Title>
    <RadioInput name="colorTheme">
      <Grid container spacing={2} alignItems="stretch" justifyContent="center">
        {colorsList.map((item, idx) => (
          <Grid item xs={12} md={4} key={idx}>
            <RadioControlLabel
              variant="info"
              value={item.value}
              label={<ColorCard item={item} />}
            />
          </Grid>
        ))}
      </Grid>
    </RadioInput>
  </Form>
);

export default connect(
  (state: ReducerState) => ({
    defaultValues: {
      colorTheme: accessibilitySelectors.colorTheme(state),
    },
  }),
  (dispatch) =>
    bindActionCreators(
      {
        onSubmit: (values) => accessibilityActions.accessibilitySet(values),
      },
      dispatch,
    ),
)(SelectColorForm);
