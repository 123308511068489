import { Fragment, useMemo } from 'react';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import MuiInputLabel, {
  InputLabelProps as BaseInputLabelProp,
} from '@mui/material/InputLabel';
import { styled, Theme } from '@mui/material/styles';
import { useInput } from 'contexts';

import Grid, { GridProps as BaseGridProps } from 'components/Grid';
import Typography from 'components/Typography';
import { GteMode, LteMode } from './types';
import KeyboardDateInput from './KeyboardDateInput';
import DatePickerPopover from './DatePicker';
import useDateRange from './useDateRange';

export interface DateRangePickerProps {
  name: string;
  [prop: string]: any;
}

interface GridProps extends BaseGridProps {
  inputContainer?: boolean;
  spanContainer?: boolean;
}

interface InputLabelProps extends BaseInputLabelProp {
  labelError: boolean;
  labelSuccess: boolean;
}

const InputLabel = styled(MuiInputLabel, {
  shouldForwardProp: (propName: string) =>
    ['labelError', 'labelSuccess'].indexOf(propName) === -1,
})<InputLabelProps>(
  ({ theme, ...ownerState }: InputLabelProps & { theme: Theme }) => ({
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    focused: {
      color: `${theme.palette.warning.contrastText} !important`,
      '&:after': {
        borderBottomColor: `${theme.palette.warning.contrastText} !important`,
      },
    },
    ...(ownerState.labelError && {
      color: theme.palette.error.main,
    }),
    ...(ownerState.labelSuccess && {
      color: theme.palette.success.main,
    }),
  }),
);

const StyledGrid = styled(Grid, {
  shouldForwardProp: (propName: string) =>
    ['inputContainer', 'spanContainer'].indexOf(propName) === -1,
})<GridProps>(({ theme, ...ownerState }: GridProps & { theme: Theme }) => ({
  ...(ownerState.inputContainer && {
    flex: 1,
  }),
  ...(ownerState.spanContainer && {
    flex: '0 0 30px',
    justifyContent: 'center',
    display: 'flex',
  }),
}));

const DateRangePicker = ({ name, rules, label }: DateRangePickerProps) => {
  const { field, meta, setError, clearErrors, error } = useInput({
    name,
    rules,
    defaultValue: {},
  });
  const { isTouched, invalid } = meta;

  const {
    lte,
    gte,
    handleClick,
    handleClose,
    onChange,
    mode,
    anchor,
    renderWrappedWeekDay,
  } = useDateRange({ field });

  const errors = useMemo(() => JSON.parse(error || '{}'), [error]);

  const onSetError = (newError: Record<string, string>) => {
    setError(name, {
      message: JSON.stringify({ ...errors, ...newError }),
      type: 'focus',
    });
  };

  const onClearErrors = (mode: string) => {
    if (!isEmpty(errors)) {
      if (Object.keys(errors).length === 1) {
        clearErrors(name);
        return;
      }
      setError(name, {
        message: JSON.stringify(omit(errors, mode)),
        type: 'focus',
      });
    }
  };

  return (
    <Fragment>
      <Grid container direction="column" sx={{ marginTop: 2, marginBottom: 1 }}>
        <Grid item>
          <InputLabel
            shrink={true}
            htmlFor={name}
            labelError={isTouched && invalid}
            labelSuccess={isTouched && !invalid}
          >
            {label}
          </InputLabel>
        </Grid>
        <Grid item container alignItems="flex-start">
          <StyledGrid item inputContainer>
            <KeyboardDateInput
              error={errors[GteMode]}
              onClearErrors={onClearErrors}
              onSetError={onSetError}
              mode={GteMode}
              value={gte}
              maxValue={lte || undefined}
              onClick={handleClick}
              onChange={onChange}
            />
          </StyledGrid>
          <StyledGrid item spanContainer>
            <Typography variant="body1">to</Typography>
          </StyledGrid>
          <StyledGrid item inputContainer>
            <KeyboardDateInput
              error={errors[LteMode]}
              onClearErrors={onClearErrors}
              onSetError={onSetError}
              mode={LteMode}
              value={lte}
              minValue={gte || undefined}
              onClick={handleClick}
              onChange={onChange}
            />
          </StyledGrid>
        </Grid>
      </Grid>
      <DatePickerPopover
        anchor={anchor}
        value={mode === GteMode ? gte : lte}
        onChange={onChange}
        renderDay={renderWrappedWeekDay}
        onClose={handleClose}
      />
    </Fragment>
  );
};

export default DateRangePicker;
