import { chunkRequest, PageQuery } from 'services/request';
import { DataSource } from 'types';

export interface DataSourcePayload extends PageQuery {
  name?: string;
  id: number | Array<number>;
}

const list = (query: DataSourcePayload, token: string) =>
  chunkRequest<DataSource, DataSourcePayload>(
    `wm-objects/data-source/`,
    'id',
    query,
    token,
  );

const dataSourcesServices = {
  list,
};

export default dataSourcesServices;
