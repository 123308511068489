import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { GovernmentDepartment } from 'types';

export interface GovernmentDepartmentPayload extends PageQuery {
  government_department?: string;
  id?: number;
}

const list = (query: GovernmentDepartmentPayload, token: string) =>
  request<Empty, PageResponse<GovernmentDepartment>, Empty>(
    `wm-objects/government-department/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const governmentDepartmentsServices = {
  list,
};

export default governmentDepartmentsServices;
