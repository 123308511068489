import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { GeographicalExtent } from 'types';

export interface GeographicalExtentPayload extends PageQuery {
  geographical_extent?: string;
  id?: number;
  name?: string;
}

const list = (query: GeographicalExtentPayload, token: string) =>
  request<Empty, PageResponse<GeographicalExtent>, Empty>(
    `wm-objects/country/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const geographicalExtentsServices = {
  list,
};

export default geographicalExtentsServices;
