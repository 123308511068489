import { Organisation } from 'types';
import { Empty, PageQuery, PageResponse, request } from 'services/request';

export interface OrganizationLogQuery extends PageQuery {
  organization_id: string | number;
}

const list = (query: OrganizationLogQuery, token: string) =>
  request<OrganizationLogQuery, PageResponse<Organisation>, Empty>(
    `organization-logs/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const organizationLogServices = {
  list,
};

export default organizationLogServices;
