import React from 'react';
import { Meta, Row } from 'react-table';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from 'components/Grid';
import TableRow, { TableRowProps } from './TableRow';
import Typography from '../Typography';

export type PrepareRow = (row: Row<any>, meta?: Meta<any>) => void;

interface TableBodyProps
  extends Omit<TableRowProps, 'row' | 'classes' | 'key'> {
  isLoading: boolean;
  rows: Array<Row<any>>;
  prepareRow: PrepareRow;
  showEmptyText?: boolean;
}

const TableBody = ({
  isLoading,
  rows,
  align,
  prepareRow,
  variant,
  showEmptyText = true,
}: TableBodyProps) =>
  isLoading ? (
    <Grid xs item container justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  ) : showEmptyText && rows.length === 0 ? (
    <Grid xs item container justifyContent="center" alignItems="center">
      <Typography>No results</Typography>
    </Grid>
  ) : (
    <Grid item container alignItems="flex-start">
      {rows.map((row: any) => {
        prepareRow(row);
        return (
          <TableRow
            row={row}
            align={align}
            variant={variant}
            {...row.getRowProps()}
          />
        );
      })}
    </Grid>
  );

export default TableBody;
