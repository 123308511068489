import React from 'react';
import { styled } from '@mui/material/styles';

const Error = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
}));

export interface ErrorTextProps {
  error?: string;
}

const ErrorText = ({ error }: ErrorTextProps) =>
  error ? <Error>{error}</Error> : null;

export default ErrorText;
