import { Dispatch } from 'redux';
import services from 'store/services';
import { parseJwtToken } from 'utils';
import { createRoutine } from './utils';
import { CallbackSideEffect } from 'store/sagas/sideEffects/callback';
import omit from 'lodash/omit';

export const signIn = createRoutine('SIGN_IN');
export const signInWithAuth0 = createRoutine('SIGN_IN_WITH_AUTH0');
export const logoutToAuth0 = createRoutine('LOGOUT_TO_AUTH0');
export const signUp = createRoutine('SIGN_UP');
export const logout = createRoutine('LOGOUT');
export const activateUserAccount = createRoutine('ACTIVATE_USER_ACCOUNT');
export const userDetailGet = createRoutine('USER_DETAIL_GET');
export const userDetailUpdate = createRoutine('USER_DETAIL_UPDATE');
export const resetPassword = createRoutine('RESET_PASSWORD');
export const resetPasswordConfirm = createRoutine('RESET_PASSWORD_CONFIRM');
export const refresh = createRoutine('REFRESH');
export const activateUserDetail = createRoutine('ACTIVATE_USER_DETAIL');

export interface SignInPayload {
  username: string;
  password: string;
}

export interface SignInWithAuth0Payload {
  token: string;
}

export interface SignUpPayload extends SignInPayload {
  email: string;
  first_name: string;
  last_name: string;
}

export interface UserDetailPayload {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}

const activateAccount = (
  data: Record<string, unknown>,
  dispatch: Dispatch,
  onFailure: Record<string, unknown>,
  onSuccess?: {
    callback: CallbackSideEffect;
  },
) =>
  activateUserAccount({
    ...omit(data, 'mfa_required'),
    meta: {
      service: services.ACTIVATE_USER_ACCOUNT,
      onFailure,
      onSuccess: {
        redirectTo: (type: string, payload?: any) => {
          if (payload?.access) {
            const { payload: userData } = parseJwtToken(payload.access);

            return userData?.file_uploads_access_only
              ? '/upload'
              : '/dashboard';
          }

          return '/dashboard';
        },
        notification: {
          level: 'success',
          message: 'Your account is now verified. Thank you for registration.',
        },
        callback:
          onSuccess?.callback || (data.token && !data.email)
            ? authActions.activateUserDetail
            : authActions.signIn,
        extra: { isAction: true },
      },
    },
  });

const authActions = {
  activateAccount,
  activateUserAccount,
  activateUserDetail,
  logout,
  logoutToAuth0,
  refresh,
  resetPassword,
  resetPasswordConfirm,
  signIn,
  signInWithAuth0,
  signUp,
  userDetailGet,
  userDetailUpdate,
};

export default authActions;
