import { styled } from '@mui/material/styles';

const HuePointer = styled('div')(({ theme }) => ({
  width: 18,
  height: 18,
  borderRadius: 12,
  transform: 'translate(-9px, -1px)',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
}));

export default HuePointer;
