import config from 'config';

export const getPublicApiJson = async (
  id: string | number,
  token: string | null,
) =>
  await fetch(`${config.baseUrl}public/document-processed/${id}/`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const download = async (
  fileName: string,
  content: string | Blob | MediaSource,
) => {
  const href =
    typeof content === 'string' ? content : await URL.createObjectURL(content);
  const link = document.createElement('a');
  link.setAttribute('href', href);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadPublicApiJSON = async (
  id: number | string,
  token: string,
) => {
  const response = await getPublicApiJson(id, token);
  const blob = await response.blob();
  download(id.toString(), blob);
};

export default download;
