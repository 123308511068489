import React from 'react';
import { HeaderGroup } from 'react-table';
import { styled } from '@mui/material/styles';
import Grid, { GridProps } from 'components/Grid';
import TableHeaderGroup, { TableHeaderGroupProps } from './TableHeaderGroup';

import { Variant } from './types';

export interface HeaderGridProps extends GridProps {
  stripped?: boolean;
}

const HeaderGrid = styled(Grid, {
  shouldForwardProp: (propName: string) => propName !== 'stripped',
})<HeaderGridProps>(({ theme, stripped }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2, 1),
  userSelect: 'none',
  color: theme.palette.warning.contrastText,
  ...(stripped && {
    fontWeight: 500,
  }),
}));

interface TableHeaderProps {
  headerGroups: Array<HeaderGroup<any>>;
  variant: Variant;
  align: TableHeaderGroupProps['align'];
}

const TableHeader = ({ headerGroups, align, variant }: TableHeaderProps) => (
  <HeaderGrid
    item
    container
    stripped={variant === 'striped'}
    direction="row"
    wrap="nowrap"
    alignItems="center"
  >
    {headerGroups.map((headerGroup: any) => (
      <TableHeaderGroup
        headerGroup={headerGroup}
        align={align}
        {...headerGroup.getHeaderGroupProps()}
      />
    ))}
  </HeaderGrid>
);

export default TableHeader;
