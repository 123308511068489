export const daysInMonth = (month: number, year: number | null = null) => {
  if (!year) {
    return [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
  }
  return new Date(year, month, 0).getDate();
};

const validateDateString = (value: string, isAllowed = false) => {
  const [dayStr, monthStr, yearStr] = value
    .split('/')
    .map((v: string) => v.trim());
  const [day, month, year] = [dayStr, monthStr, yearStr].map((v: string) =>
    parseInt(v),
  );
  const dayIsValid = dayStr && dayStr.length === 2;
  const monthIsValid = monthStr && monthStr.length === 2;
  const yearIsValid = yearStr && yearStr.length === 4;

  if (!dayIsValid || !monthIsValid || !yearIsValid) {
    return false;
  }

  if (dayIsValid && (day < 1 || day > 31)) {
    return true;
  }

  if (
    dayIsValid &&
    monthIsValid &&
    (month < 1 || month > 12 || daysInMonth(month) < day)
  ) {
    return true;
  }

  if (
    isAllowed &&
    yearIsValid &&
    (year < 1 || daysInMonth(month, year) < day)
  ) {
    return true;
  }

  if (!isAllowed && yearIsValid && dayIsValid && monthIsValid) {
    return true;
  }

  return false;
};

export default validateDateString;
