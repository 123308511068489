import { useCallback, useEffect, useMemo, useRef } from 'react';
import uniq from 'lodash/uniq';
import { ControllerRenderProps } from 'react-hook-form';

interface UseManyProps {
  field: ControllerRenderProps;
  many: boolean;
  getValue: (...args: any) => any;
  defaultValue?: any | null;
  choices?: Record<string, any>[];
}

export const useMany = ({
  field,
  many,
  getValue,
  defaultValue = null,
  choices,
}: UseManyProps) => {
  const changedField = useRef<boolean>(false);
  const onChange = useCallback(
    (...args: unknown[]) => {
      const value = getValue(...args);
      if (many) {
        const currentValue = field.value || [];
        const nextValue = uniq([...currentValue, value]);
        changedField.current = true;
        field.onChange(nextValue);
        return;
      }
      changedField.current = true;
      field.onChange(value);
    },
    [field, many, getValue],
  );

  useEffect(() => {
    if (changedField.current) {
      changedField.current = false;
      field.onBlur && field.onBlur();
    }
  }, [field, changedField]);

  const value = useMemo(() => {
    if (many) {
      return defaultValue;
    }
    if (choices && choices.length && field.value) {
      return (
        choices.find((option: any) =>
          getValue(option)
            ? getValue(option).toString() === field.value.toString()
            : field.value === getValue(option),
        ) || defaultValue
      );
    }
    return field.value;
  }, [getValue, many, field, defaultValue, choices]);

  return {
    value,
    onChange,
  };
};

export default useMany;
