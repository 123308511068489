import * as yup from 'yup';

declare module 'yup' {
  interface ArraySchema<T> {
    unique(
      message: any,
      property: string,
      subProperty: string,
      mapper: (a: T) => T,
    ): ArraySchema<T>;
  }
}

yup.addMethod<yup.AnySchema>(
  yup.array,
  'unique',
  function (
    message: string,
    property: string,
    subProperty: string,
    mapper: (val: any) => any = (a) => a,
  ) {
    return this.test('unique', message, function (list) {
      if (!list) return true;
      const set = new Set(list.map(mapper));
      const isUnique = list.length === set.size;
      if (isUnique) return true;
      const idx = list.map(mapper).findIndex((value: yup.AnyObjectSchema) => {
        if (set.has(value)) {
          set.delete(value);
          return false;
        }
        return true;
      });
      if (idx === -1) {
        return true;
      }
      return this.createError({
        path: `${property}[${idx}].${subProperty}`,
        message,
      });
    });
  },
);

export default yup;
