import React from 'react';

import DeactivateDialog from './DeactivateDialog';
import ConfirmDialog from './ConfirmDialog';
import EmptyBasketDialog from './EmptyBasketDialog';
import DndListDialog from './DndListDialog';
import InfoDialog from './InfoDialog';

const Modals = () => (
  <React.Fragment>
    <ConfirmDialog />
    <DeactivateDialog />
    <EmptyBasketDialog />
    <DndListDialog />
    <InfoDialog />
  </React.Fragment>
);

export default Modals;
