import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import Grid, { GridProps } from 'components/Grid';
import Typography from 'components/Typography';

import { fontsList } from 'containers/Accessibility/components/SelectFontForm';

export interface RootGridProps extends GridProps {
  topBar: boolean;
}

const RootGrid = styled(Grid, {
  shouldForwardProp: (propName: string) => propName !== 'topBar',
})<RootGridProps>(({ theme, topBar }) => ({
  minWidth: topBar ? 300 : 'inherit',
  padding: topBar ? theme.spacing(1) : theme.spacing(4),
  boxShadow: '0px 8px 40px 4px rgba(0, 0, 0, 0.06)',
  height: '100%',
  border: '1px solid transparent',
}));

const Box = styled(MuiBox)(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: 4,
  background: alpha(theme.palette.info.light, 0.1),
  '& >*': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3.5),
  fontWeight: theme.typography.fontWeightRegular,
}));

export interface FontSizeCardProps {
  item: typeof fontsList[number];
  isTopBar?: boolean;
}

const FontSizeCard = ({ item, isTopBar = false }: FontSizeCardProps) => {
  return (
    <RootGrid container spacing={2} topBar={isTopBar}>
      {!isTopBar && (
        <Grid item xs={12} md={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography component="p" style={{ fontSize: `${item.value}px` }}>
              A
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={isTopBar ? 12 : 10}>
        <Typography style={{ fontSize: `${item.value}px` }}>
          {`${item.title} (${item.value}pt)`}
        </Typography>
        <Description component="p" style={{ fontSize: `${item.value}px` }}>
          {item.description}
        </Description>
      </Grid>
    </RootGrid>
  );
};

export default FontSizeCard;
