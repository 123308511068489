import MuiInputLabel, {
  InputLabelProps as MuiInputLabelProps,
} from '@mui/material/InputLabel';
import { StyledComponent } from '@mui/styles';
import { styled, Theme } from '@mui/material/styles';
import { Color } from 'types';

export interface InputLabelProps extends Omit<MuiInputLabelProps, 'color'> {
  labelError?: boolean;
  labelSuccess?: boolean;
  color?: Color | 'black' | 'white';
}

const InputLabel = styled(MuiInputLabel, {
  shouldForwardProp: (propName: string) => {
    return (
      ['focusedView', 'labelError', 'labelSuccess', 'color'].indexOf(
        propName,
      ) === -1
    );
  },
})<InputLabelProps>(
  ({ theme, ...ownerState }: InputLabelProps & { theme: Theme }) => ({
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.75)',
    },
    ...(ownerState.color === 'black' && {
      color: theme.palette.common.black,
    }),
    ...(ownerState.color === 'info' && {
      color: theme.palette.info.main,
    }),
    ...(ownerState.color === 'success' && {
      color: theme.palette.success.main,
    }),
    ...(ownerState.color === 'secondary' && {
      color: theme.palette.secondary.main,
    }),
    ...(ownerState.color === 'warning' && {
      color: theme.palette.warning.main,
    }),
    ...(ownerState.color === 'secondaryInfo' && {
      color: theme.palette.warning.contrastText,
    }),
    ...(ownerState.color === 'error' && {
      color: theme.palette.error.main,
    }),
    ...(ownerState.color === 'white' && {
      color: theme.palette.common.white,
    }),
    ...(ownerState.labelError && {
      color: theme.palette.error.main,
    }),
    ...(ownerState.labelSuccess && {
      color: theme.palette.success.main,
    }),
  }),
) as StyledComponent<InputLabelProps>;

export default InputLabel;
