import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Sanction } from 'types';

export interface SanctionPayload extends PageQuery {
  id: number;
  sanction?: string;
}

const list = (query: PageQuery, token: string) =>
  request<PageQuery, PageResponse<Sanction>, Empty>(
    'wm-objects/sanction/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const sanctionServices = {
  list,
};

export default sanctionServices;
