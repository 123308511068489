import React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { styled } from '@mui/material/styles';
import {
  generateUtilityClass,
  unstable_composeClasses as composeClasses,
} from '@mui/material';

import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { ReactComponent as UserGroup } from 'assets/user_group.svg';

const AvatarGridContainer = styled(Grid)(({ theme }) => ({
  // maxWidth: 'fit-content',
  '& .MuiAvatar-root': {
    border: `2px solid ${theme.palette.background.default}`,
    boxSizing: 'content-box',
    marginLeft: -8,
    '&:last-child': {
      marginLeft: 0,
    },
  },
}));

const StyledUserGroup = styled(UserGroup)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

export interface AvatarGroupProps extends React.RefAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  classes?: Record<string, string>;
  max?: number;
}

const useUtilityClasses = (classes?: Record<string, string>) => {
  const slots = {
    root: ['root'],
    avatar: ['avatar'],
  };

  const getAvatarGroupUtilityClass = (slot: string) =>
    generateUtilityClass('MuiAvatarGroup', slot);

  return composeClasses(slots, getAvatarGroupUtilityClass, classes);
};

const SPACING = -22;

const AvatarGroup: React.FC<AvatarGroupProps> = React.forwardRef(
  (props, ref: React.Ref<HTMLDivElement>) => {
    const { children: childrenProp, max = 5 } = props;
    const clampedMax = max < 2 ? 2 : max;
    const children = React.Children.toArray(childrenProp).filter((child) =>
      React.isValidElement(child),
    ) as Array<React.ReactElement>;
    const classes = useUtilityClasses(props.classes);
    return (
      <AvatarGridContainer container alignItems="center">
        {children
          .slice(0, clampedMax)
          .reverse()
          .map((child: React.ReactElement, idx: number) =>
            React.cloneElement(child, {
              ...child.props,
              key: idx,
              className: clsx(child.props.className, classes.avatar),
              style: {
                marginLeft: idx ? SPACING : 0,
                backgroundColor: get(child.props, 'color', '#757575'),
                ...child.props.style,
              },
            }),
          )}
        <Grid xs item container ml={1} alignItems="center">
          <StyledUserGroup />
          <Typography variant="body1">{children.length}</Typography>
        </Grid>
      </AvatarGridContainer>
    );
  },
);

export default AvatarGroup;
