import { PivotTableTemplate } from 'types';
import { Empty, PageQuery, PageResponse, request } from 'services/request';

const create = (payload: PivotTableTemplate, token: string) =>
  request<PivotTableTemplate, PivotTableTemplate, Empty>(
    `pivot-table/templates`,
    {
      payload,
      method: 'POST',
    },
    token,
  );

const list = (query: PageQuery, token: string) =>
  request<PageQuery, PageResponse<PivotTableTemplate>, Empty>(
    `pivot-table/templates`,
    {
      query,
      method: 'GET',
    },
    token,
  );

const detail = ({ id }: PivotTableTemplate, token: string) =>
  request<Empty, PageResponse<PivotTableTemplate>, Empty>(
    `pivot-table/templates/${id}/`,
    {
      method: 'GET',
    },
    token,
  );

const pivotTemplateServices = {
  create,
  list,
  detail,
};

export default pivotTemplateServices;
