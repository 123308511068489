import omit from 'lodash/omit';
import Immutable from 'immutable';
import { createTransform } from 'redux-persist';

const ImmutableReducerTransform = (
  ImmutableReducerFactory: Immutable.Record.Factory<any>,
  reducerName: string,
  omitFields: string[] = [],
) =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: Immutable.Record<any>, key) => {
      // convert AuthState to an plain json object without some fields.
      return omit(inboundState.toObject(), omitFields);
    },
    // transform state being rehydrated
    (outboundState, key) => {
      return new ImmutableReducerFactory(outboundState);
    },
    // define which reducers this transform gets called for.
    { whitelist: [reducerName] },
  );

export default ImmutableReducerTransform;
