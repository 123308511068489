import React, { FormEventHandler } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { arrayRange } from 'utils';
import Grid, {
  GridContentAlignment,
  GridDirection,
  GridSpacing,
} from 'components/Grid';
import Loader from 'components/loader';
import ErrorText from './ErrorText';

const generatePaddingClasses = (theme: Theme, start: number, end: number) => {
  return arrayRange(start, end).reduce(
    (prevClasses: Record<string, any>, spacing: number) => ({
      ...prevClasses,
      [`pd${spacing}`]: {
        padding: theme.spacing(spacing),
      },
    }),
    {} as Record<string, any>,
  );
};

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      width: '100%',
      // overflow: 'auto',
      flex: 1,
    },
    loadingOverflow: {
      position: 'relative',
      '&:after': {
        content: '""',
        background: theme.palette.info.main,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.7,
      },
    },
    ...generatePaddingClasses(theme, 1, 10),
  });

export interface FormProps {
  children?: React.ReactNode;
  direction?: GridDirection;
  align?: GridContentAlignment;
  spacing?: GridSpacing;
  isLoading?: boolean;
  className?: string;
  error?: string;
  onSubmit?: FormEventHandler;
}

export const Form = React.memo(
  ({
    children,
    classes,
    isLoading,
    spacing,
    className,
    error,
    onSubmit,
    direction = 'column',
    align = 'flex-start',
  }: FormProps & WithStyles<typeof styles>) => (
    <form
      className={clsx(
        className,
        classes.form,
        (classes as Record<string, string>)[`pd${spacing}`],
        {
          [classes.loadingOverflow]: isLoading,
        },
      )}
      style={{ flexDirection: direction, alignItems: align }}
      onSubmit={onSubmit}
    >
      <ErrorText error={error} />
      {children}
      {isLoading && (
        <Grid
          container
          sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <Loader />
        </Grid>
      )}
    </form>
  ),
);

export default withStyles(styles)(Form) as React.FC<FormProps>;
