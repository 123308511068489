import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { accessibilityActions } from 'store/actions';
import { ReducerState } from 'store/reducers';
import { accessibilitySelectors } from 'store/selectors';
import { ReactForm, ReactFormProps } from 'contexts';
import Grid from 'components/Grid';
import { RadioInput, RadioControlLabel } from 'components/inputs';
import FontSizeCard from 'components/Accessibility/FontSizeCard';
import Typography from 'components/Typography';

export const fontsList = [
  {
    id: 'default',
    title: 'Default',
    value: '16',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas urna diam viverra turpis eu porttitor odio. Ullamcorper urna tellus volutpat morbi amet. Semper arcu erat amet, nunc sapien mauris nulla molestie accumsan. Sit nec enim rutrum felis volutpat fermentum nullam pellentesque.',
  },
  {
    id: 'large',
    title: 'Large',
    value: '18',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas urna diam viverra turpis eu porttitor odio. Ullamcorper urna tellus volutpat morbi amet. Semper arcu erat amet, nunc sapien mauris nulla molestie accumsan. Sit nec enim rutrum felis volutpat fermentum nullam pellentesque.',
  },
  {
    id: 'extralarge',
    title: 'Extralarge',
    value: '20',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas urna diam viverra turpis eu porttitor odio. Ullamcorper urna tellus volutpat morbi amet. Semper arcu erat amet, nunc sapien mauris nulla molestie accumsan. Sit nec enim rutrum felis volutpat fermentum nullam pellentesque.',
  },
];

const Form = styled(ReactForm)(({ theme }) => ({
  '& label': {
    margin: theme.spacing(1),
    height: '100%',
    '& >span:first-child': {
      display: 'none',
    },
    '& >span:last-child': {
      height: '100%',
    },
    '& .Mui-checked + span>div': {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(4.8),
}));

type SelectFontFormProps = Omit<ReactFormProps, 'children'>;

const SelectFontForm = ({ ...props }: SelectFontFormProps) => (
  <Form align="center" mode="onChange" {...props}>
    <Title variant="h3" component="p" align="center">
      <b>Select a font size:</b>
    </Title>
    <RadioInput name="fontSize">
      <Grid container spacing={2} alignItems="stretch">
        {fontsList.map((item) => (
          <Grid item xs={12} md={4} key={item.id}>
            <RadioControlLabel
              variant="info"
              value={item.id}
              label={<FontSizeCard item={item} />}
            />
          </Grid>
        ))}
      </Grid>
    </RadioInput>
  </Form>
);

export default connect(
  (state: ReducerState) => ({
    defaultValues: {
      fontSize: accessibilitySelectors.fontSize(state),
    },
  }),
  (dispatch) =>
    bindActionCreators(
      {
        onSubmit: (values) => accessibilityActions.accessibilitySet(values),
      },
      dispatch,
    ),
)(SelectFontForm);
