import React from 'react';

export interface UseScrollProps {
  behavior?: ScrollBehavior;
}

const smoothScroll = (
  elem: HTMLElement,
  options: ScrollIntoViewOptions = {
    behavior: 'smooth',
  },
) => {
  return new Promise<void>((resolve) => {
    let same = 0; // a counter
    let lastPos: number | null = null; // last known Y position
    const check = () => {
      // check our current position
      const newPos = elem.getBoundingClientRect().top;
      if (newPos === lastPos) {
        // same as previous
        if (same++ > 2) {
          return resolve();
        }
      } else {
        same = 0;
        lastPos = newPos;
      }
      requestAnimationFrame(check);
    };
    elem.scrollIntoView(options);
    requestAnimationFrame(check);
  });
};

const useScroll = (
  { behavior = 'smooth' }: UseScrollProps = { behavior: 'smooth' },
) => {
  const documentContainerRef = React.useRef<HTMLDivElement>(null);
  const scrollToElementById = React.useCallback(
    (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        smoothScroll(element, { behavior }).then(() => {
          element.classList.remove('background-highlight');
          setTimeout(() => {
            element.classList.add('background-highlight');
          });
        });
      }
    },
    [behavior],
  );
  return {
    documentContainerRef,
    scrollToElementById,
  };
};

export default useScroll;
