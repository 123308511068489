import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { ReactComponent as OpenMenu } from 'assets/open_menu.svg';
import { ReactComponent as CloseMenu } from 'assets/close_menu.svg';

const IconButton = styled(MuiIconButton, {
  shouldForwardProp: (propName) => propName !== 'collapsed',
})<{ collapsed: boolean }>(({ theme, collapsed }) => ({
  color: collapsed ? theme.palette.info.main : theme.palette.common.white,
  padding: theme.spacing(1.75, 1),
}));

export interface MenuBtnProps {
  collapsed: boolean;
  onClick: () => void;
}

const MenuBtn = ({ collapsed, onClick }: MenuBtnProps) => (
  <IconButton
    collapsed={collapsed}
    title={collapsed ? 'open' : 'close'}
    onClick={onClick}
    size="large"
  >
    {collapsed ? <OpenMenu /> : <CloseMenu />}
  </IconButton>
);

export default MenuBtn;
