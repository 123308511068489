import { useState, useMemo, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { debounce } from '@mui/material/utils';
import { AppliedFilters, Pagination, Sort } from 'types';
import { getPrimitiveSafeValue, parseRoute, stringifyQuery } from 'utils';

export interface Query {
  filters: AppliedFilters;
  sort: Array<Sort>;
  pagination: Pagination;
}

export interface UseListParams extends RouteComponentProps {
  isStatic?: boolean;
  debounceWait?: number;
  perPage?: number;
  baseFilters?: AppliedFilters;
  fetch: (values: Record<string, any>) => void;
  isDoFetch: (values: Query) => boolean;
  isLoading: boolean;
  count: number;
}

const useListParams = ({
  baseFilters,
  fetch,
  isDoFetch,
  isLoading,
  location,
  history,
  count,
  isStatic = false,
  perPage = 25,
  debounceWait = 500,
}: UseListParams) => {
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [staticQuery, setStaticQuery] = useState<Query>({
    filters: {
      ...baseFilters,
    },
    sort: [],
    pagination: {
      perPage,
      page: 1,
    },
  });
  const query = useMemo(() => {
    if (isStatic) {
      return staticQuery;
    }
    const { pagination, filters, sort } = parseRoute(location, perPage);

    if (baseFilters && !isEmpty(baseFilters)) {
      const formattedBaseFilters = Object.keys(baseFilters).reduce(
        (resultFilters: Record<string, unknown>, keyFilter) => {
          const value = baseFilters[keyFilter];
          return {
            ...resultFilters,
            [keyFilter]: Array.isArray(value)
              ? value.map((value: any) => getPrimitiveSafeValue(value))
              : getPrimitiveSafeValue(value),
          };
        },
        {},
      );
      return {
        filters: {
          ...formattedBaseFilters,
          ...filters,
        },
        sort,
        pagination,
      };
    }

    return {
      filters: {
        ...baseFilters,
        ...filters,
      },
      sort,
      pagination,
    };
  }, [perPage, isStatic, staticQuery, baseFilters, location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetch = useCallback(
    debounce(({ filters, pagination, sort }: Query) => {
      fetch({
        filters,
        sort,
        pagination,
        doRequest: isDoFetch({ filters, pagination, sort }),
      });
      setInitialLoading(false);
    }, debounceWait),
    [fetch, setInitialLoading, debounceWait, isDoFetch],
  );

  const changeRoute = useCallback(
    (query: Query) => {
      if (isStatic) {
        setStaticQuery(query);
      } else {
        history.replace({
          search: `?${stringifyQuery(query)}`,
        });
      }
      debounceFetch(query);
    },
    [isStatic, setStaticQuery, history, debounceFetch],
  );

  const updateFilters = useCallback(
    (filters: AppliedFilters) => {
      const appliedFilters = Object.keys(filters).reduce(
        (result: Record<string, unknown>, filterName: string) => {
          if (
            !(
              (!(filters[filterName] instanceof Date) &&
                typeof filters[filterName] === 'object' &&
                isEmpty(filters[filterName])) ||
              !filters[filterName]
            )
          ) {
            return { ...result, [filterName]: filters[filterName] };
          }
          return result;
        },
        {},
      );

      changeRoute({
        ...query,
        filters: appliedFilters,
        pagination: {
          ...query.pagination,
          page: 1,
        },
      });
    },
    [query, changeRoute],
  );

  const updateSort = useCallback(
    (sort: Array<Sort>) => {
      changeRoute({ ...query, sort });
    },
    [query, changeRoute],
  );

  const updatePaginate = useCallback(
    (pagination: Pagination) => {
      changeRoute({ ...query, pagination });
    },
    [query, changeRoute],
  );

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      debounceFetch(query);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const maxPage = Math.ceil(count / perPage);
    if (maxPage !== 0 && maxPage < query.pagination.page) {
      changeRoute({
        ...query,
        pagination: { ...query.pagination, page: maxPage },
      });
    }
  }, [changeRoute, count, perPage, query]);

  return {
    updateFilters,
    updatePaginate,
    updateSort,
    query,
    loading: initialLoading || isLoading,
  };
};

export default useListParams;
