import React from 'react';
import get from 'lodash/get';
import {
  PluginHook,
  Row,
  SortingRule,
  TableOptions,
  TableState,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable as useReactTable,
} from 'react-table';
import { AppliedFilters, Pagination as IPagination, Sort } from 'types';
import Cell from './Cell';

type SelectedRows = Record<string | number, boolean>;

interface UseTableProps<T> {
  columns: Array<any>;
  data: Array<T>;
  initialSortBy?: Array<SortingRule<T>>;
  initialSelectedIds?: SelectedRows;
  totalItems?: number;
  itemsPerPage?: number;
  currentPage?: number;
  filters?: Record<string, any>;
  onFilterChange?: (filters: AppliedFilters) => any;
  onPageChange?: (pagination: IPagination) => any;
  onSortChange?: (sort: Array<Sort>) => any;
  onSelect?: (items: Array<T>) => any;
  rowIdField?: string;
  updateInPlace?: (item: T) => void;
  [restProp: string]: any;
}

const useTable = <T extends { id: number }>(
  {
    columns,
    data,
    initialSortBy = [],
    initialSelectedIds = {},
    totalItems,
    itemsPerPage,
    currentPage,
    filters,
    onFilterChange,
    onPageChange,
    onSortChange,
    onSelect,
    updateInPlace,
    rowIdField = 'id',
    ...restProps
  }: UseTableProps<T>,
  ...hooks: Array<PluginHook<T>>
) => {
  const [selectedRowIds, setSelectedRowIds] =
    React.useState<SelectedRows>(initialSelectedIds);
  const hooksToAdd: Array<PluginHook<T>> = [useFlexLayout];
  if (onPageChange) {
    hooksToAdd.push(useGlobalFilter);
  }
  if (onSortChange) {
    hooksToAdd.push(useSortBy);
  }
  if (onPageChange) {
    hooksToAdd.push(usePagination);
  }
  if (onSortChange) {
    hooksToAdd.push(useSortBy);
  }
  if (onSelect) {
    hooksToAdd.push(useRowSelect);
  }
  hooksToAdd.push(...hooks);
  const unspecifiedParams = [
    !isNaN(currentPage!),
    onPageChange !== undefined,
    !isNaN(itemsPerPage!),
    !isNaN(totalItems!),
  ].filter((v) => v).length;
  if (unspecifiedParams !== 0 && unspecifiedParams < 4) {
    throw new Error(
      'If you specify any of this item currentPage, onPageChange, itemsPerPage, totalItems you have to specify rest',
    );
  }

  const page = isNaN(currentPage!) ? 0 : currentPage! - 1;
  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    setGlobalFilter,
    // pagination
    pageCount,
    gotoPage,
    selectedFlatRows,
    state: {
      pageIndex,
      sortBy,
      globalFilter,
      selectedRowIds: tableSelectedRowIds,
    },
  } = useReactTable<T>(
    {
      columns,
      data,
      initialState: {
        pageIndex: page,
        pageSize: itemsPerPage,
        sortBy: initialSortBy,
        globalFilter: filters,
        selectedRowIds,
      } as TableState,
      getRowId: (item: T) => get(item, rowIdField),
      manualSorting: true,
      manualPagination: true,
      manualGlobalFilter: true,
      defaultColumn: {
        Cell,
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      },
      pageCount:
        isNaN(totalItems!) || isNaN(itemsPerPage!)
          ? 1
          : Math.ceil(totalItems! / itemsPerPage!),
      updateInPlace: (rowIndex: number, columnId: string, value: any) => {
        updateInPlace &&
          updateInPlace({
            ...data[rowIndex],
            [columnId]: value,
          });
      },
      ...restProps,
    } as TableOptions<any>,
    ...hooksToAdd,
  ) as any;

  if (JSON.stringify(globalFilter) !== JSON.stringify(filters)) {
    setGlobalFilter(filters);
    gotoPage(0);
  }

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    if (
      onPageChange &&
      pageIndex !== currentPage! - 1 &&
      pageCount > pageIndex
    ) {
      onPageChange!({
        page: pageIndex + 1,
        perPage: itemsPerPage!,
      });
    }
  }, [currentPage, pageIndex, itemsPerPage, onPageChange, pageCount]);

  // Listen for changes in sort
  React.useEffect(() => {
    if (
      JSON.stringify(initialSortBy) !== JSON.stringify(sortBy) &&
      onSortChange
    ) {
      onSortChange(
        sortBy.map((sortField: SortingRule<any>) => ({
          order: sortField.desc ? 'desc' : 'asc',
          field: sortField.id,
        })) as Array<Sort>,
      );
    }
  }, [initialSortBy, sortBy, onSortChange]);

  // Listen for changes in filters
  React.useEffect(() => {
    if (
      JSON.stringify(filters) !== JSON.stringify(globalFilter) &&
      onFilterChange
    ) {
      onFilterChange(globalFilter);
    }
  }, [filters, onFilterChange, globalFilter]);

  // Listen for changes in selected rows
  React.useEffect(() => {
    if (onSelect && selectedFlatRows.length) {
      onSelect(selectedFlatRows.map((flatRow: Row<T>) => flatRow.original));
    }
  }, [selectedFlatRows, onSelect]);

  React.useEffect(() => {
    setSelectedRowIds(tableSelectedRowIds);
  }, [setSelectedRowIds, tableSelectedRowIds]);

  return {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    pageCount,
    gotoPage,
    pageIndex,
    sortBy,
    globalFilter,
  };
};

export default useTable;
