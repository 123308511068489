import {
  FocusEvent,
  useCallback,
  forwardRef,
  useState,
  useRef,
  ChangeEvent,
  useEffect,
} from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import config from 'config';
import validateDateString from 'utils/validateDateString';

interface DateFormatInputProps extends NumberFormatProps {
  [prop: string]: any;
}

const DateFormatInput = forwardRef(
  ({ onChange, name, valid, ...rest }: DateFormatInputProps, ref) => {
    const inputMode = useRef<boolean>(false);
    const [numberFormatValues, setNumberFormatValues] =
      useState<NumberFormatValues>({
        floatValue: undefined,
        formattedValue: '',
        value: rest.value as string,
      });
    const isAllowed = useCallback((values: NumberFormatValues) => {
      return !validateDateString(values.formattedValue, true);
    }, []);

    const onValueChange = useCallback(
      (values: NumberFormatValues) => {
        setNumberFormatValues(values);
        if (onChange && inputMode.current) {
          onChange({
            target: {
              name: name,
              value: values.formattedValue,
            },
          } as ChangeEvent<HTMLInputElement>);
        }
      },
      [setNumberFormatValues, name, onChange, inputMode],
    );

    const onBlur = useCallback(
      (evt: FocusEvent<HTMLInputElement>) => {
        inputMode.current = false;
        if (rest.onBlur) {
          let blurEvt = evt;
          if (numberFormatValues.value.length !== 8) {
            blurEvt = {
              ...evt,
              target: {
                ...evt.target,
                value: (rest.value as string) || '',
              },
            };
          }
          rest.onBlur(blurEvt);
        }
      },
      [inputMode, rest, numberFormatValues],
    );
    const onFocus = useCallback(
      (evt: FocusEvent<HTMLInputElement>) => {
        inputMode.current = true;
        if (rest.onFocus) {
          rest.onFocus(evt);
        }
      },
      [inputMode, rest],
    );

    useEffect(() => {
      if (inputMode.current) return;
      if (valid && rest.value !== numberFormatValues.formattedValue) {
        setNumberFormatValues({
          ...numberFormatValues,
          value: (rest.value as string).split('/').join(),
          formattedValue: rest.value as string,
        });
      }
    }, [inputMode, setNumberFormatValues, numberFormatValues, rest, valid]);

    return (
      <NumberFormat
        {...rest}
        isAllowed={isAllowed}
        value={numberFormatValues.value}
        getInputRef={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={onValueChange}
        format="##/##/####"
        placeholder={config.dateFormat}
      />
    );
  },
);

export default DateFormatInput;
