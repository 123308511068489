import { ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Typography from 'components/Typography';
import { ButtonProps } from 'components/buttons/base';

export interface ModeProps extends ButtonProps {
  label: string;
  value: string;
  icon?: ReactNode;
  onModeClick?: () => void;
  selected?: boolean;
}

const Mode = ({ label, value, icon, selected, onModeClick }: ModeProps) => {
  return (
    <MenuItem value={value} selected={selected} onClick={onModeClick}>
      {icon}
      <Typography pl={1}>{label}</Typography>
    </MenuItem>
  );
};

export default Mode;
