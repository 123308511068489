import { WAYMARK_TEMP_STORAGE } from 'contstants';

const get = (key: string) => {
  let store = {} as Record<string, any>;

  const item = localStorage.getItem(WAYMARK_TEMP_STORAGE);
  store = item ? JSON.parse(item) : {};

  return store[key];
};

const save = (key: string, value: Record<string, any>) => {
  const currentStorage = JSON.parse(
    localStorage.getItem(WAYMARK_TEMP_STORAGE)!,
  );
  const itemToSave = { ...currentStorage, [key]: value };
  localStorage.setItem(WAYMARK_TEMP_STORAGE, JSON.stringify(itemToSave));
};

const waymarkStorage = { get, save };

export default waymarkStorage;
