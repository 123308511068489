const getRelativeBackLink = (path: string, backSize = 1) => {
  return path[path.length - 1] === '/'
    ? path
        .split('/')
        .slice(0, -1 * (backSize + 1))
        .join('/')
    : path
        .split('/')
        .slice(0, -1 * backSize)
        .join('/');
};

export default getRelativeBackLink;
