import { ReactElement } from 'react';
import {
  ErrorBoundary as RollbarErrorBoundary,
  ErrorBoundaryProps as BaseErrorBoundaryProps,
} from '@rollbar/react';
import { FallbackProps } from 'react-error-boundary';
import config from 'config';
import { Color } from 'types';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { Button } from 'components/buttons';

export interface ErrorFallbackProps extends Omit<FallbackProps, 'error'> {
  error: Error | null;
  title: string;
  labelAction?: string;
  color?: Color;
}

export interface ErrorBoundaryProps extends BaseErrorBoundaryProps {
  children: ReactElement | null;
  title: string;
  labelAction?: string;
  color?: Color;
}

const ErrorFallback = ({
  resetErrorBoundary,
  error,
  title,
  labelAction,
  color = 'secondaryInfo',
}: ErrorFallbackProps) => {
  console.error(error);
  return (
    <Grid
      fullHeight
      container
      bg="#fff"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs="auto">
        <Typography variant="body1" color={color}>
          {title}
        </Typography>
        {labelAction && (
          <Button
            variant="contained"
            color="primary"
            onClick={resetErrorBoundary}
          >
            {labelAction}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const ErrorBoundary = ({
  children,
  title,
  labelAction,
  color = 'secondaryInfo',
}: ErrorBoundaryProps) => {
  return config.rollbarAccessToken ? (
    <RollbarErrorBoundary
      fallbackUI={({ error, resetError }) => (
        <ErrorFallback
          title={title}
          labelAction={labelAction}
          error={error}
          color={color}
          resetErrorBoundary={resetError}
        />
      )}
      callback={() => window.location.reload()}
    >
      {children}
    </RollbarErrorBoundary>
  ) : (
    children
  );
};

export default ErrorBoundary;
