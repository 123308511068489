import { createContext } from 'react';

export interface CollapseContextProps {
  expanded: boolean;
  append: (uuid: string, defaultValue?: boolean) => void;
  toggle: (uuid: string) => (nextState?: unknown) => void;
  isExpanded: (uuid: string) => boolean;
  toggleAll: (nextState?: unknown) => void;
}

const CollapseContext = createContext<CollapseContextProps>({
  expanded: false,
  append: () => null,
  toggle: () => () => null,
  isExpanded: () => false,
  toggleAll: () => null,
});

export default CollapseContext;
