import React from 'react';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { alpha, styled } from '@mui/material/styles';
import { Color } from 'types';
import { ControllerProps } from './types';

interface RadioProps extends MuiRadioProps {
  noIcon?: boolean;
}

const Radio = styled(MuiRadio)<RadioProps>(({ noIcon, theme }) => ({
  ...(noIcon && {
    display: 'none',
  }),
}));

export interface RadioIconProps {
  disabled?: boolean;
  variant?: Color;
  checked?: boolean;
  color?: Color;
}

const OPACITY_VALUE = 0.4;

const RadioIcon = styled('span', {
  shouldForwardProp: (propName: string) =>
    ['checked', 'color'].indexOf(propName) === -1,
})<RadioIconProps>(({ theme, disabled, variant, color, checked = false }) => ({
  color: theme.palette.text.disabled,
  backgroundColor: disabled
    ? theme.palette.grey.A100
    : theme.palette.text.disabled,
  width: 24,
  height: 24,
  borderRadius: 12,
  '&:hover': {
    backgroundColor: theme.palette.text.disabled,
    padding: 0,
  },
  ...(color === 'primary' && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(color === 'secondary' && {
    backgroundColor: theme.palette.secondary.main,
  }),
  ...(color === 'success' && {
    backgroundColor: theme.palette.success.main,
  }),
  ...(color === 'warning' && {
    backgroundColor: theme.palette.warning.main,
  }),
  ...(color === 'error' && {
    backgroundColor: theme.palette.error.main,
  }),
  ...(color === 'info' && {
    backgroundColor: theme.palette.info.main,
  }),
  ...(variant === 'primary' && {
    backgroundColor: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.primary.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'info' && {
    backgroundColor: theme.palette.info.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.info.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'secondary' && {
    backgroundColor: theme.palette.secondary.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.secondary.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'success' && {
    backgroundColor: theme.palette.success.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.success.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'warning' && {
    backgroundColor: theme.palette.warning.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.warning.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'error' && {
    backgroundColor: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.error.main, OPACITY_VALUE),
    },
  }),
  ...(checked && {
    '&:after': {
      content: '""',
      width: 10,
      height: 10,
      borderRadius: 6,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      top: 16,
      left: 16,
    },
  }),
}));

export interface RadioControllerProps extends ControllerProps {
  noIcon?: boolean;
}

const RadioController = ({
  variant = 'primary',
  disabled = false,
  noIcon = false,
  className,
  color,
  ...props
}: RadioControllerProps) => (
  <Radio
    {...props}
    color="primary"
    icon={<RadioIcon color={color} disabled={disabled} />}
    checkedIcon={<RadioIcon checked color={color} variant={variant} />}
  />
);

export default RadioController;
