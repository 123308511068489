import { RegulatoryCalendar } from 'types';
import { ResponseData, Empty, request, PageQuery } from 'services/request';
import { getResponseList } from 'services/utils';

interface Payload {
  month: string;
  year: string;
}

const list = (query: Payload, token: string) =>
  request<PageQuery, RegulatoryCalendar, Empty>(
    `wm-objects/get-regulatory-calendars/`,
    {
      method: 'GET',
      query,
    },
    token,
  ).then(({ response, error }) =>
    getResponseList<RegulatoryCalendar>({
      response: {
        ...response,
        data: response?.data?.results as RegulatoryCalendar[],
      } as ResponseData<RegulatoryCalendar[]>['response'],
      error,
    }),
  );

const regulatoryCalendarServices = {
  list,
};

export default regulatoryCalendarServices;
