import { ReducerState } from 'store/reducers';

const basketState = (state: ReducerState) => state.basket;

const documents = (state: ReducerState) => basketState(state).get('documents');

const basketSelectors = {
  documents,
};

export default basketSelectors;
