import { useEffect, useRef, useContext } from 'react';
import get from 'lodash/get';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormContext } from './FormContext';
import parseServerError from 'contexts/Form/parseServerError';

const useInput = ({
  name,
  defaultValue = '',
  ...props
}: Omit<UseControllerProps, 'control'>) => {
  const {
    control,
    errors,
    serverErrors,
    formState,
    setError,
    clearErrors,
    stepperForm,
  } = useContext(FormContext);
  const hideServerError = useRef<boolean>(false);
  const submitCount = useRef<number>(0);

  const { field, fieldState: meta } = useController({
    ...props,
    name,
    control,
    defaultValue,
  });
  const messageServerError = serverErrors
    ? parseServerError(name, serverErrors)
    : undefined;

  const error = get(
    errors,
    name,
    serverErrors
      ? {
          message: messageServerError,
        }
      : undefined,
  );

  useEffect(() => {
    if (
      hideServerError.current &&
      formState.submitCount !== submitCount.current
    ) {
      hideServerError.current = false;
    }
    submitCount.current = formState.submitCount;
  }, [formState]);

  useEffect(() => {
    if (
      formState.submitCount === 0 &&
      error?.message &&
      error.message === messageServerError
    ) {
      hideServerError.current = true;
    }
  }, [error, formState, messageServerError]);

  const onChange = (value: unknown) => {
    if (error?.message && error.message === messageServerError) {
      hideServerError.current = true;
    }

    field.onChange(value);
  };

  return {
    field: { ...field, onChange },
    meta,
    error: error
      ? (!stepperForm || formState.dirtyFields[name]) &&
        hideServerError.current &&
        error.message === messageServerError
        ? undefined
        : (error.message as string)
      : undefined,
    setError,
    clearErrors,
  };
};

export default useInput;
