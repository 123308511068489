import React from 'react';
import {
  FieldValues,
  FieldName,
  SetFieldValue,
  SetValueConfig,
} from 'react-hook-form';

interface UseSelectProps {
  name: string;
  value: Array<string | number>;
  setValue: (
    name: FieldName<FieldValues>,
    value: SetFieldValue<FieldValues>,
    config?: SetValueConfig,
  ) => void;
}

const useSelect = ({ name, value, setValue }: UseSelectProps) => {
  const onSelect = React.useCallback(
    (newIds: Array<string | number>) => {
      const newValue = new Set(value);
      newIds.forEach((newId) => {
        newValue.add(newId);
      });

      setValue(name, Array.from(newValue));
    },
    [name, setValue, value],
  );

  const onUnselectItems = React.useCallback(() => {
    setValue(name, []);
  }, [name, setValue]);

  const onToggleItem = React.useCallback(
    (id: string | number) => {
      if (value.some((selectedId) => selectedId === id)) {
        setValue(
          name,
          value.filter((selectedId) => selectedId !== id),
        );
      } else {
        setValue(name, [...value, id]);
      }
    },
    [name, value, setValue],
  );

  return {
    onSelect,
    onUnselectItems,
    onToggleItem,
  };
};

export default useSelect;
