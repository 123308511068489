import React from 'react';
import MuiFormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from './Checkbox';

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  marginLeft: -9,
  marginRight: 0,
  '& .label': {
    color: theme.palette.warning.contrastText,
    fontSize: 16,
  },
}));

interface CheckboxControlLabelProps {
  // variant?: CheckboxProps['variant'];
  checked?: CheckboxProps['checked'];
  edge?: CheckboxProps['edge'];
  onClick?: CheckboxProps['onClick'];
  onChange?: CheckboxProps['onChange'];
  disabled?: CheckboxProps['disabled'];
  disableRipple?: CheckboxProps['disableRipple'];
  label?: FormControlLabelProps['label'];
  name?: CheckboxProps['name'];
  value?: CheckboxProps['value'];
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  id?: CheckboxProps['id'];
}

const CheckboxControlLabel = React.forwardRef(
  (
    {
      label = '',
      value,
      labelPlacement,
      id,
      ...props
    }: CheckboxControlLabelProps,
    ref,
  ) => (
    <FormControlLabel
      htmlFor={id}
      control={
        <Checkbox
          id={id}
          ref={ref as React.MutableRefObject<HTMLButtonElement>}
          checked={value === true}
          {...(props || {})}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  ),
);

export default CheckboxControlLabel;
