import { ReactNode } from 'react';
import DebugControllerContext, {
  DebugControllerContextValue,
} from './DebugControllerContext';

export interface DebugControllerContextProviderProps {
  children: ReactNode;
  value: DebugControllerContextValue;
}

const DebugControllerContextProvider = ({
  children,
  value,
}: DebugControllerContextProviderProps) => (
  <DebugControllerContext.Provider value={value}>
    {children}
  </DebugControllerContext.Provider>
);

export default DebugControllerContextProvider;
