import { useState } from 'react';
import isBoolean from 'lodash/isBoolean';

const useToggle = (initial = false) => {
  const [isMarked, setState] = useState(initial);
  const toggle = (nextState: unknown = null) => {
    setState((currentState) =>
      nextState !== null && isBoolean(nextState) ? nextState : !currentState,
    );
  };
  return {
    isMarked,
    toggle,
  };
};

export default useToggle;
