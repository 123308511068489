import { Location } from 'history';

const slicePathname = (location: Location, keepSearch = false, slice = 1) => {
  const parts = location.pathname.split('/');
  const redirectPath = parts
    .slice(
      0,
      location.pathname.endsWith('/')
        ? parts.length - (slice + 1)
        : parts.length - slice,
    )
    .join('/');
  if (keepSearch) {
    return `${redirectPath}${location.search}`;
  }
  return redirectPath;
};

export default slicePathname;
