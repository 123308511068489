import { ReactChild } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from 'components/Tooltip';
import { ReactComponent as InfoRoundedIcon } from 'assets/info_rounded.svg';

const TipWrapper = styled('div')(() => ({
  textAlign: 'right',
}));

const StyledInfoRoundedIcon = styled(InfoRoundedIcon)(({ theme }) => ({
  '&:hover': {
    fill: theme.palette.primary.main,
  },
}));

export interface TipIconProps {
  title: ReactChild;
}

const InputTooltip = ({ title }: TipIconProps) => (
  <TipWrapper>
    <Tooltip title={title}>
      <StyledInfoRoundedIcon />
    </Tooltip>
  </TipWrapper>
);

export default InputTooltip;
