import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { useInput } from 'contexts';
import { useToggle } from 'hooks';

import { ReactComponent as ViewIcon } from 'assets/view.svg';
import { ReactComponent as ViewCloseIcon } from 'assets/view_close.svg';
import Input, { InputProps } from '../Input/Input';
import { IconButton } from 'components/ui';

interface PasswordProps
  extends Omit<InputProps, 'classes' | 'field' | 'meta' | 'error'> {
  name: string;
  helpText?: string;
}

const PasswordInput = ({ name, rules, ...props }: PasswordProps) => {
  const { isMarked, toggle } = useToggle();
  const { field, meta, error } = useInput({ name, rules });
  return (
    <Input
      {...props}
      field={field}
      meta={meta}
      error={error}
      type={isMarked ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="start">
          <IconButton
            toggleButton
            title={isMarked ? 'hide password' : 'show password'}
            disableRipple={true}
            aria-label="toggle password visibility"
            onClick={toggle}
            size="large"
          >
            {field.value && (isMarked ? <ViewIcon /> : <ViewCloseIcon />)}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PasswordInput;
