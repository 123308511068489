import { PageResponse, ResponseData } from 'services/request';

export const getResponseList = <T>(
  response: ResponseData<T[]>,
): ResponseData<PageResponse<T>> => {
  const results = (response.response?.data || []) as T[];

  return {
    error: response.error,
    response: {
      ...response.response,
      data: {
        results,
        count: response.response?.data?.length,
      },
    },
  } as ResponseData<PageResponse<T>>;
};
