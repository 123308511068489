import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useUserDetails } from 'hooks';
import Grid from 'components/Grid';
import { Button } from 'components/buttons';
import Typography from 'components/Typography';

const NotFoundTypography = styled(Typography)(() => ({
  fontSize: 350,
}));

const NotFound = () => {
  const { isAuthenticated } = useUserDetails();

  return (
    <Grid
      container
      item
      xs
      direction="column"
      justifyContent="center"
      spacing={5}
    >
      <Grid item>
        <NotFoundTypography
          color="primary"
          variant="h1"
          align="center"
          component="p"
        >
          404
        </NotFoundTypography>
      </Grid>
      <Grid item>
        <Typography variant="h1" component="p" color="info" align="center">
          Oops. Something went wrong.
        </Typography>
        <Typography variant="h1" component="p" color="info" align="center">
          Please try again in a moment.
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item>
          <Button
            component={Link}
            to={isAuthenticated ? '/dashboard' : '/sign-in'}
            variant="contained"
            color="primary"
          >
            GO BACK TO THE MAIN PAGE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotFound;
