import get from 'lodash/get';
import moment from 'moment';

const getValue = (name: string, defaultValue: any = null): any => {
  return get(process.env, `REACT_APP_${name}`, defaultValue);
};

export interface Filter {
  name: string;
  label: string;
  enabled: boolean;
  hidden?: boolean;
  type:
    | 'input'
    | 'autocomplete'
    | 'select'
    | 'checkbox'
    | 'radio'
    | 'date'
    | 'datepicker'
    | 'select_input';
  description?: string;
}

export interface FilterGroup {
  name: string;
  filters: Record<string, Filter>;
}

export interface Config {
  appMode: 'dev' | 'prod' | 'test';
  baseUrl: string;
  isProduction: () => boolean;
  dateFormat: string;
  auth0: {
    audience: string;
    clientId: string;
    domain: string;
    scope: string;
    enabled: boolean;
  };
  tokenRefreshThresholdMinutes: number;
  hotJar: {
    id: string | null;
    sv: string | null;
  };
  pusher: {
    appKey: string;
    cluster: string;
  };
  intercomAppId: string;
  alternativeDateFormat: string;
  flexmonsterLicenseKey?: string;
  supportEmail: string;
  dateTimeFormat: string;
  monthCommaYearFormat: string;
  isOpenFinApp: boolean;
  notificationTimeFormat: string;
  dataUploadMaxMemorySize: number;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  numberFormat: string;
  backendDateFormat: string;
  intercomEnabled: boolean;
  codeVersionJs: string;
  rollbarAccessToken?: string;
}

const config: Config = {
  appMode: getValue('MODE', 'dev'),
  baseUrl: getValue('URL', 'https://dev-api.waymark.tech/api/v1/'),
  isProduction: (): boolean => {
    return config.appMode === 'prod';
  },
  supportEmail: getValue('SUPPORT_EMAIL', 'support@ascentregtech.com'),
  intercomAppId: getValue('INTERCOM_APP_ID', ''),
  auth0: {
    audience: getValue('AUTH0_AUDIENCE', 'https://waymark-local.ascent.tools'),
    clientId: getValue('AUTH0_CLIENT_ID', 'cFGYLPACay4CDc8i204y2rUiadfL6xgy'),
    domain: getValue('AUTH0_DOMAIN', 'https://ascent-production.us.auth0.com'),
    scope: getValue('AUTH0_SCOPE', 'crud:*'),
    enabled: getValue('AUTH0_ENABLED', 'false') === 'true',
  },
  tokenRefreshThresholdMinutes: getValue('tokenRefreshThresholdMinutes', 15),
  hotJar: {
    id: getValue('HOTJAR_ID'),
    sv: getValue('HOTJAR_SNIPPET_VERSION'),
  },
  pusher: {
    appKey: getValue('PUSHER_APP_KEY'),
    cluster: getValue('PUSHER_APP_CLUSTER'),
  },
  flexmonsterLicenseKey: getValue('FLEXMONSTER_LICENSE_KEY', undefined),
  isOpenFinApp: getValue('IS_OPENFIN_APP', false) === true,
  dateFormat: 'DD/MM/YYYY',
  monthCommaYearFormat: 'MMMM, YYYY',
  alternativeDateFormat: 'DD.MM.YYYY',
  backendDateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'DD.MM.YYYY, HH:mm',
  notificationTimeFormat: 'D MMM',
  dataUploadMaxMemorySize: getValue(
    'DATA_UPLOAD_MAX_MEMORY_SIZE',
    1024 * 1024 * 10,
  ), // 10mb by default
  maxDate: moment(getValue('MAX_DATE', '12-31-2035'), 'MM-DD-YYYY'),
  minDate: moment(getValue('MIN_DATE', '01-01-2000'), 'MM-DD-YYYY'),
  numberFormat: getValue('NUMBER_FORMAT', 'en-GB'),
  intercomEnabled: getValue('INTERCOM_ENABLED', false) === true,
  rollbarAccessToken: getValue('ROLLBAR_ACCESS_TOKEN', undefined),
  codeVersionJs: getValue('CODE_VERSION_JS', '1.0.0'),
};

export default config;
