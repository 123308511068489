import { useState, useCallback, useRef, useEffect } from 'react';
import moment, { Moment } from 'moment';
import Popover from '@mui/material/Popover';
import config from 'config';
import { hasYearChanged } from 'utils';
import CalendarPicker from './CalendarPicker';
import { RenderDay } from './types';

export interface DatePickerPopoverProps {
  onClose: () => any;
  anchor: HTMLElement | null;
  value: Moment | null;
  onChange: (date: moment.Moment) => any;
  renderDay: RenderDay;
}

export type View = 'day' | 'month' | 'year';

const DatePickerPopover = ({
  anchor,
  value,
  onClose,
  onChange,
  renderDay,
}: DatePickerPopoverProps) => {
  const initValue = useRef<boolean>(false);
  const [internalValue, setInternalValue] = useState(value);
  const [currentView, setView] = useState<View>('day');

  const safeOnChange = useCallback(
    (date: unknown) => {
      let selectDate = date as moment.Moment | null;
      const value = moment(internalValue || new Date());
      if (
        selectDate &&
        !internalValue &&
        !value.isSame(selectDate) &&
        value.year() !== selectDate.year()
      ) {
        value.set('year', selectDate.year());
        selectDate = value;
      }
      if (selectDate) {
        if (hasYearChanged(moment(internalValue || new Date()), selectDate)) {
          if (currentView === 'year') {
            setView('day');
          }
          setInternalValue(selectDate);
          return;
        }
        onChange(selectDate);
      }
    },
    [internalValue, currentView, onChange],
  );

  useEffect(() => {
    if (!initValue.current && !!anchor) {
      setInternalValue(value);
      initValue.current = true;
    }
  }, [anchor, initValue, setInternalValue, value]);

  useEffect(() => {
    if (!anchor) {
      initValue.current = false;
    }
  }, [anchor]);

  return (
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <CalendarPicker
        view={currentView}
        onViewChange={setView}
        maxDate={config.maxDate}
        minDate={config.minDate}
        date={internalValue}
        onChange={safeOnChange}
        renderDay={renderDay}
      />
    </Popover>
  );
};

export default DatePickerPopover;
