import MuiContainer from '@mui/material/Container';
import { Theme, styled } from '@mui/material/styles';

const Container = styled(MuiContainer, {
  overridesResolver: (props, styles) => {
    return Object.keys(styles);
  },
})(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  flexDirection: 'column',
  ...Object.keys(theme.breakpoints.values).reduce(
    (acc, breakpoint) => ({
      ...acc,
      [`${theme.breakpoints.up(breakpoint as unknown as number)}`]: {
        maxWidth: 'none',
        paddingLeft: 0,
        paddingRight: 0,
      },
    }),
    {},
  ),
}));

export default Container;
