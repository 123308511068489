import React from 'react';
import { useInput } from 'contexts';
import SwitchInput, { SwitchInputProps } from './SwitchInput';

const ControlSwitchInput = ({
  name,
  rules,
  ...props
}: Omit<SwitchInputProps, 'field' | 'meta' | 'error'>) => {
  const { field, meta, error } = useInput({ name, rules, defaultValue: false });
  return (
    <SwitchInput
      name={name}
      field={field}
      meta={meta}
      error={error}
      {...props}
    />
  );
};

export default ControlSwitchInput;
