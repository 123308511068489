import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { styled, Theme } from '@mui/material/styles';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as BaseDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import MuiTextField, {
  TextFieldProps as BaseTextFieldProps,
} from '@mui/material/TextField';
import { useInput } from 'contexts';
import config from 'config';
import { hasYearChanged, validateDate } from 'utils';
import { useToggle } from 'hooks';

interface DateInputProps {
  name: string;
  margin?: BaseTextFieldProps['margin'];
  required?: BaseTextFieldProps['required'];
  views?: Array<'day' | 'month' | 'year'>;
  changeValidDate?: boolean;
  minDate?: Date | string;
  enableOutsideRange?: boolean;
  onValidateInputValue?: (value: unknown) => boolean;
  [prop: string]: any;
}

export interface DatePickerProps
  extends Omit<BaseDatePickerProps<unknown, unknown>, 'renderDay'> {
  renderDay?: (
    day: unknown,
    selectedDays: unknown[],
    pickersDayProps: PickersDayProps<unknown>,
  ) => ReactElement;
}

const DatePicker = styled(MuiDatePicker)<DatePickerProps>(({ theme }) => ({
  color: theme.palette.warning.contrastText,
}));

const Day = styled(PickersDay)<PickersDayProps<unknown>>(
  ({ theme, ...ownerState }: PickersDayProps<unknown> & { theme: Theme }) => ({
    width: theme.spacing(4.5),
    height: theme.spacing(4.4),
    fontSize: theme.typography.caption.fontSize,
    margin: theme.spacing(0, 0.25),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    background: 'transparent',
    transform: 'scale(1.1)',
    '& > *': {
      transform: 'scale(0.9)',
    },
    ...(ownerState.today && {
      color: theme.palette.primary.main,
    }),
  }),
);

interface TextFieldProps extends Omit<BaseTextFieldProps, 'color'> {
  inputError: boolean;
  inputSuccess: boolean;
}

const TextField = styled(MuiTextField, {
  shouldForwardProp: (propName: string) =>
    ['inputError', 'inputSuccess'].indexOf(propName) === -1,
})<TextFieldProps>(
  ({ theme, ...ownerState }: TextFieldProps & { theme: Theme }) => ({
    '.MuiInput-root': {
      ...(ownerState.inputError && {
        '&:before': {
          borderBottomColor: theme.palette.error.main,
        },
      }),
      ...(ownerState.inputSuccess && {
        '&:before': {
          borderBottomColor: theme.palette.success.main,
        },
      }),
      '&.Mui-focused': {
        color: `${theme.palette.warning.contrastText} !important`,
        '&:after': {
          borderBottomColor: `${theme.palette.warning.contrastText} !important`,
        },
      },
    },
    '.MuiFormHelperText-root': {
      ...(ownerState.inputError && {
        color: theme.palette.error.main,
      }),
      ...(ownerState.inputSuccess && {
        color: theme.palette.success.main,
      }),
    },
    '.MuiInputLabel-root': {
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1),
      ...(ownerState.inputError && {
        color: theme.palette.error.main,
      }),
      ...(ownerState.inputSuccess && {
        color: theme.palette.success.main,
      }),
      '&.Mui-focused': {
        color: `${theme.palette.warning.contrastText} !important`,
        '&:after': {
          borderBottomColor: `${theme.palette.warning.contrastText} !important`,
        },
      },
    },
    '.MuiButtonBase-root': {
      marginRight: 0,
    },
  }),
);

interface WeekenDayProps {
  weekday: boolean;
}

const WeekenDay = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'weekday',
})<WeekenDayProps>(
  ({ theme, ...ownerState }: WeekenDayProps & { theme: Theme }) => ({
    ...(ownerState.weekday && {
      backgroundColor: theme.palette.grey.A400,
      borderRadius: 0,
    }),
  }),
);

const DateInput = ({
  name,
  rules,
  label,
  required,
  enabled,
  margin = 'normal',
  format = config.dateFormat,
  formatValueAs,
  placeholder,
  minDate,
  onValidateInputValue,
  onChange: customChange,
  enableOutsideRange = true,
  defaultValue = null,
  changeValidFormat = false,
  ...props
}: DateInputProps) => {
  const { field, meta, error, setError } = useInput({
    name,
    rules: {
      validate: validateDate((minDate || config.minDate) as Date | string),
      ...rules,
    },
    defaultValue,
  });
  const { isMarked: open, toggle } = useToggle(false);
  const { isTouched, invalid } = meta;
  const { value, ref, ...restProps } = field;
  const [internalValue, setInternalValue] = useState<moment.Moment | null>(
    value,
  );

  const view = useRef<CalendarPickerView>('day');

  const changeViewMode = (newView: CalendarPickerView) => {
    view.current = newView;
  };

  useEffect(() => {
    if (!open) {
      setInternalValue(value);
    }
  }, [value, open]);

  const onChange = useCallback(
    (date: unknown) => {
      let day = date as moment.Moment | null;
      const value = moment(internalValue || new Date());
      const isYearViewMode = props.views
        ? props.views.length === 1 && props.views[0] === 'year'
        : false;
      if (
        !isYearViewMode &&
        day &&
        open &&
        !internalValue &&
        !value.isSame(day) &&
        value.year() !== day.year() &&
        view.current === 'year'
      ) {
        value.set('year', day.year());
        day = value;
      }

      if (
        !isYearViewMode &&
        open &&
        hasYearChanged(
          moment(internalValue || new Date()),
          moment(day || new Date()),
        )
      ) {
        setInternalValue(day);
        return;
      }
      if (day && !day.isValid()) {
        setError(name, { message: 'Invalid Date', type: 'invalid' });
      }
      if (changeValidFormat) {
        const validRegular = /^\d{2}\/\d{2}\/\d{4}$/;
        const dateString = day
          ? day.startOf('day').format(config.dateFormat)
          : null;
        if (dateString && !validRegular.test(dateString)) {
          return;
        }
      }
      setInternalValue(day);
      if (onValidateInputValue && !onValidateInputValue(day)) {
        return;
      }

      if (formatValueAs) return field.onChange(day ? formatValueAs(day) : day);

      field.onChange(
        day ? day.startOf('day').format(config.backendDateFormat) : day,
      );
      if (customChange) {
        customChange(
          day ? day.startOf('day').format(config.backendDateFormat) : day,
        );
      }
    },
    [
      internalValue,
      props.views,
      open,
      changeValidFormat,
      onValidateInputValue,
      formatValueAs,
      field,
      customChange,
      setError,
      name,
    ],
  );

  const inputValue = useMemo(() => {
    if (internalValue) {
      return moment(internalValue).format(format);
    }

    return internalValue;
  }, [internalValue, format]);

  const renderWrappedWeekDay = (
    day: unknown,
    selectedDates: Array<unknown>,
    pickersDayProps: PickersDayProps<unknown>,
  ) => {
    return (
      <WeekenDay weekday={(day as moment.Moment).weekday() >= 5}>
        <Day
          {...(pickersDayProps as PickersDayProps<unknown>)}
          hidden={false}
          disabled={
            !enableOutsideRange
              ? !(day as moment.Moment).isSameOrAfter(moment().startOf('day'))
              : pickersDayProps.outsideCurrentMonth
          }
        />
      </WeekenDay>
    );
  };

  return (
    <DatePicker
      open={open}
      onOpen={toggle}
      onClose={toggle}
      minDate={minDate || config.minDate}
      maxDate={config.maxDate}
      onViewChange={changeViewMode}
      label={label}
      inputFormat={format}
      renderDay={renderWrappedWeekDay}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          helperText={error}
          variant="standard"
          required={required}
          margin={margin}
          inputError={invalid || !!error}
          inputSuccess={isTouched && !invalid}
          inputProps={{
            ...params.inputProps,
            placeholder: placeholder || format,
            value: inputValue,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      inputRef={field.ref}
      {...restProps}
      {...props}
      value={field.value || null}
      onChange={onChange}
    />
  );
};

export default DateInput;
