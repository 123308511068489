const validateURL = (v?: string) => {
  if (!v) return false;

  try {
    const url = new URL(v);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
};

export default validateURL;
