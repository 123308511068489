import React from 'react';
import countryByCallingCode from 'country-json/src/country-by-calling-code.json';
import countryByFlag from 'country-json/src/country-by-flag.json';

import { styled } from '@mui/material/styles';
import useAutocomplete from '@mui/material/useAutocomplete';
import MaterialInput, { InputProps } from '@mui/material/Input';
import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItem';
import Popover, { PopoverProps } from '@mui/material/Popover';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import {
  CountriesByFlag,
  CountriesWithCallingCodeAndFlag,
  CountryByCallingCode,
  CountryByFlag,
  GroupedCountry,
} from './types';
import CountryOption from './CountryOption';

const BY_FLAG = (countryByFlag as Array<CountryByFlag>).reduce(
  (countriesByFlag: CountriesByFlag, country: CountryByFlag) =>
    ({
      ...countriesByFlag,
      [country.country]: country,
    } as CountriesByFlag),
  {} as CountriesByFlag,
);

const COUNTRY_CODES_WITH_FLAG = (
  countryByCallingCode as Array<CountryByCallingCode>
).reduce(
  (
    countriesByFlag: CountriesWithCallingCodeAndFlag,
    country: CountryByCallingCode,
  ) =>
    country.calling_code
      ? ({
          ...countriesByFlag,
          [country.country]: {
            ...country,
            ...BY_FLAG[country.country],
          },
        } as CountriesWithCallingCodeAndFlag)
      : countriesByFlag,
  {} as CountriesWithCallingCodeAndFlag,
);

const List = styled(MuiList)(() => ({
  maxHeight: 200,
  overflow: 'auto',
}));

interface CountryPopoverProps {
  open: boolean;
  anchorEl: HTMLDivElement | null;
  onClose: PopoverProps['onClose'];
  onChange: (
    event: React.ChangeEvent<{}>,
    value: GroupedCountry | null,
  ) => void;
}

const CountryPopover = ({
  open,
  onClose,
  onChange,
  anchorEl,
}: CountryPopoverProps) => {
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete<GroupedCountry>({
    options: Object.values(COUNTRY_CODES_WITH_FLAG),
    getOptionLabel: (option) => option.country,
    openOnFocus: true,
    onChange,
  });

  const renderListItem = (country: GroupedCountry, index: number) => {
    const options = getOptionProps({ option: country, index });
    return (
      <ListItem disablePadding key={options.itemID}>
        <ListItemButton {...options}>
          <CountryOption country={country} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      PaperProps={{
        ...getRootProps(),
        style: {
          width: `${anchorEl?.getBoundingClientRect().width}px`,
        },
      }}
    >
      <MaterialInput
        fullWidth
        autoFocus
        endAdornment={<SearchIcon />}
        {...(getInputProps() as InputProps)}
      />
      {groupedOptions.length > 0 ? (
        <List {...getListboxProps()}>
          {(groupedOptions as GroupedCountry[]).map(renderListItem)}
        </List>
      ) : null}
    </Popover>
  );
};

export default CountryPopover;
