const ALL = 'all';
const REVIEWED = 'true';
const NOT_REVIEWED = 'false';

export const reviewedChoices = [
  { id: ALL, name: 'All' },
  { id: REVIEWED, name: 'Reviewed' },
  { id: NOT_REVIEWED, name: 'Not Reviewed' },
];

const documentReviewed = {
  ALL,
  REVIEWED,
  NOT_REVIEWED,
};

export default documentReviewed;
