import { styled } from '@mui/material/styles';

export interface MainContainerProps {
  collapsed: boolean;
  collapsedWidth: number;
  expandedWidth: number;
}

const MainContainer = styled('main', {
  shouldForwardProp: (propName: string) =>
    ['collapsed', 'collapsedWidth', 'expandedWidth'].indexOf(propName) === -1,
})<MainContainerProps>(
  ({ theme, collapsed, collapsedWidth, expandedWidth }) => ({
    flex: 1,
    marginTop: 64,
    height: '100%',
    padding: theme.spacing(1, 1, 0, 1),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey.A100,
    position: 'relative',
    marginLeft: collapsed ? collapsedWidth : expandedWidth,
  }),
);

export default MainContainer;
