import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { S3Bucket } from 'types';

export interface S3BucketPayload extends PageQuery {
  bucket?: string;
  id?: number;
}

const list = (query: S3BucketPayload, token: string) =>
  request<Empty, PageResponse<S3Bucket>, Empty>(
    `organization-bucket/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const s3BucketServices = {
  list,
};

export default s3BucketServices;
