import { createRoutine } from './utils';
import { Action } from 'redux';

export const list = createRoutine('LIST');
export const getMany = createRoutine('GET_MANY');
export const listAppend = createRoutine('LIST_APPEND');
export const listRemove = createRoutine('LIST_REMOVE');
export const retrieve = createRoutine('RETRIEVE');
export const update = createRoutine('UPDATE');
export const create = createRoutine('CREATE');
export const remove = createRoutine('DELETE');
export const cleanup = createRoutine('CLEANUP');
export const resetError = createRoutine('RESET_ERROR');
export const listUpdate = createRoutine('LIST_UPDATE');

export interface ResourcePayload {
  [prop: string]: any;
}

export interface CRUDAction extends Action {
  payload: ResourcePayload;
  meta: {
    resource: string;
    resetCurrentItem: boolean;
    partial: boolean;
    onSuccess: {
      runListUpdate?: boolean;
      callback?: any;
      notification?: Record<string, any>;
      relatedRedirect?: boolean;
      redirectTo?: string;
      extra?: Record<string, any>;
    };
    metaList: {
      resourceList?: string;
      removedParentItemList?: boolean;
    };
  };
}

const crudActions = {
  list,
  getMany,
  listAppend,
  listRemove,
  create,
  retrieve,
  update,
  remove,
  cleanup,
  resetError,
  listUpdate,
};

export default crudActions;
