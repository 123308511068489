import React from 'react';

const usePaginationState = (
  initialPagination: { perPage?: number; page?: number } = {},
) => {
  const [pagination, setPagination] = React.useState({
    perPage: initialPagination.perPage || 100,
    page: initialPagination.page || 1,
  });
  const isFirstRender = React.useRef(true);

  const setPerPage = React.useCallback(
    (perPage: number) =>
      setPagination((prevState) => ({ ...prevState, perPage })),
    [setPagination],
  );
  const setPage = React.useCallback(
    (page: number) => setPagination((prevState) => ({ ...prevState, page })),
    [setPagination],
  );

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setPerPage(initialPagination.perPage || 100);
  }, [initialPagination.perPage, setPerPage]);

  return {
    pagination,
    page: pagination.page,
    perPage: pagination.perPage,
    setPage,
    setPerPage,
    setPagination,
  };
};

export default usePaginationState;
