import { styled } from '@mui/material/styles';
import MuiToolbar from '@mui/material/Toolbar';

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  alignItems: 'stretch',
  flexWrap: 'nowrap',
}));

export default Toolbar;
