import {
  ReactNode,
  ReactElement,
  cloneElement,
  useMemo,
  Children,
} from 'react';
import { Cell, SortingRule } from 'react-table';

import { AppliedFilters, Pagination as IPagination, Sort } from 'types';
import Table from './Table';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import { TableCellProps } from './TableCell';
import TablePagination from './TablePagination';

import useTable from './useTable';
import useSelection from './useSelection';

interface ExternalProps {
  columns?: Array<any>;
  data: Array<any>;
  children?: ReactNode;
  initialSortBy?: Array<Sort>;
  initialSelectedIds?: Record<string | number, boolean>;
  rowIdField?: string;
  totalItems?: number;
  itemsPerPage?: number;
  currentPage?: number;
  isLoading?: boolean;
  className?: string;
  filters?: Record<string, any>;
  variant?: 'striped' | 'default';
  align?: TableCellProps['align'];
  onFilterChange?: (filters: AppliedFilters) => any;
  onPageChange?: (pagination: IPagination) => any;
  onSortChange?: (sort: Array<Sort>) => any;
  onSelect?: (items: Array<any>) => any;
  updateInPlace?: (item: any) => void;
  // needs to put any custom props through table to cells
  [restProp: string]: any;
}

interface PaginatedTableProps extends ExternalProps {
  selectable?: boolean;
}

const FieldToCell =
  (Component: ReactElement) =>
  ({ row: { original } }: Cell<Record<string, any>>) => {
    return cloneElement(Component, { record: original });
  };

const PaginatedTable = ({
  columns,
  children,
  data,
  totalItems,
  itemsPerPage,
  currentPage,
  filters = {},
  isLoading = false,
  initialSortBy,
  initialSelectedIds,
  rowIdField,
  onFilterChange,
  onPageChange,
  onSortChange,
  onSelect,
  selectable = false,
  variant = 'default',
  align = 'center',
  showEmptyText = true,
  updateInPlace,
  PaginationProps,
  ...restProps
}: PaginatedTableProps) => {
  if ((!columns && !children) || (columns && children)) {
    throw new Error('Expected either columns or children');
  }
  const memoizedColumns = useMemo(() => {
    if (columns) return columns;

    return Children.map(children as ReactElement[], (element) => ({
      id: element.props.id,
      Header: element.props.label,
      Cell: FieldToCell(element),
      accessor: element.props.accessor
        ? element.props.accessor
        : typeof element.props.source === 'string'
        ? element.props.source
        : undefined,
      minWidth: element.props.minWidth || 30,
      width: element.props.width || 150,
      maxWidth: element.props.maxWidth || 400,
    }));
  }, [columns, children]);
  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    pageCount,
    gotoPage,
    pageIndex,
  } = useTable(
    {
      columns: memoizedColumns,
      data,
      totalItems,
      itemsPerPage,
      currentPage,
      filters,
      rowIdField,
      initialSortBy: initialSortBy?.map((sort: Sort) => ({
        id: sort.field,
        desc: sort.order === 'desc',
      })) as Array<SortingRule<any>>,
      initialSelectedIds,
      onFilterChange,
      onPageChange,
      onSortChange,
      onSelect,
      updateInPlace,
      ...restProps,
    },
    useSelection(selectable),
  );
  return (
    <Table {...getTableProps()}>
      <TableHeader
        headerGroups={headerGroups}
        align={align}
        variant={variant}
      />
      <TableBody
        showEmptyText={showEmptyText}
        align={align}
        isLoading={isLoading}
        rows={rows}
        variant={variant}
        prepareRow={prepareRow}
      />
      <TablePagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        PaginationProps={PaginationProps}
      />
    </Table>
  );
};

export default PaginatedTable;
