import { PivotTableTemplateHandlers } from 'types';
import { crudActions } from 'store/actions';
import resources from 'store/resources';

const loadPivotTemplate = (
  id: number | string,
  setPivotTemplate: PivotTableTemplateHandlers,
) =>
  crudActions.retrieve({
    id,
    meta: {
      resource: resources.PIVOT_TEMPLATES,
      onSuccess: {
        callback: ({ response }: Record<string, any>) =>
          setPivotTemplate(response),
      },
    },
  });

const pivotTemplateActions = {
  loadPivotTemplate,
};

export default pivotTemplateActions;
