import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { CreateOrEditDocumentBundle, DocumentBundle } from 'types';

const list = (query: PageQuery, token: string) =>
  request<PageQuery, PageResponse<DocumentBundle>, Empty>(
    'document_bundle/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const create = (payload: CreateOrEditDocumentBundle, token: string) =>
  request<CreateOrEditDocumentBundle, CreateOrEditDocumentBundle, Empty>(
    `document_bundle/`,
    {
      payload,
      method: 'POST',
    },
    token,
  );

interface DetailPayload {
  id: number | string;
}

const detail = ({ id }: DetailPayload, token: string) =>
  request<DocumentBundle, DocumentBundle, Empty>(
    `document_bundle/${id}`,
    {
      method: 'GET',
    },
    token,
  );

const update = (payload: CreateOrEditDocumentBundle, token: string) =>
  request<CreateOrEditDocumentBundle, CreateOrEditDocumentBundle, Empty>(
    `document_bundle/${payload.id}/`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );

const remove = ({ id }: DetailPayload, token: string) =>
  request<DocumentBundle, Empty, Empty>(
    `document_bundle/${id}/`,
    {
      method: 'DELETE',
    },
    token,
  );

const documentBundlesServices = {
  list,
  create,
  detail,
  update,
  remove,
};

export default documentBundlesServices;
