import React from 'react';
import { connect } from 'react-redux';
import { AccessibilityRecord, ReducerState } from 'store/reducers';
import { accessibilitySelectors } from 'store/selectors';
import { ThemeProvider as LibThemeProvider } from '@mui/material/styles';
import generateTheme from 'theme';

export interface ThemeProviderProps {
  themeStore: AccessibilityRecord;
}

const ThemeProvider = ({
  themeStore,
  children,
}: React.PropsWithChildren<ThemeProviderProps>) => {
  const theme = React.useMemo(() => {
    if (themeStore) {
      return generateTheme({
        typographyType: themeStore.get('fontSize'),
        paletteType: themeStore.get('colorTheme'),
      });
    }
    return generateTheme({});
  }, [themeStore]);
  return <LibThemeProvider theme={theme}>{children}</LibThemeProvider>;
};

export default connect((state: ReducerState) => {
  return {
    themeStore: accessibilitySelectors.accessibilityState(state),
  };
})(ThemeProvider);
