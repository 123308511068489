import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from 'components/buttons';
import Typography from 'components/Typography';
import { ReactComponent as ArrowBack } from 'assets/arrow_back.svg';

const ArrowBackIcon = styled(ArrowBack)(({ theme }) => ({
  maxWidth: 56,
  minWidth: 44,
  width: '100%',
  height: 40,
  padding: theme.spacing(1.5, 2),
  background: theme.palette.info.main,
  borderRadius: theme.spacing(0, 0.5, 0.5, 0),
  color: theme.palette.text.disabled,
  marginRight: theme.spacing(1),
}));

interface BackToMainMenuProps {
  onClick: ButtonProps['onClick'];
}

const BackToMainMenu = ({ onClick }: BackToMainMenuProps) => (
  <Button
    onClick={onClick}
    variant="text"
    color="info"
    textTransform="capitalize"
    sx={{ justifyContent: 'stretch' }}
  >
    <ArrowBackIcon />
    <Typography color="info">Back to the main menu</Typography>
  </Button>
);

export default BackToMainMenu;
