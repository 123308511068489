import { useCallback, useContext } from 'react';
import PopoverContext, { PopoverItem } from './PopoverContext';

const usePopover = (item: PopoverItem) => {
  const { showPopover, closePopover } = useContext(PopoverContext);
  const show = useCallback(() => {
    showPopover(item);
  }, [showPopover, item]);

  const close = useCallback(() => {
    closePopover(item);
  }, [closePopover, item]);

  return { show, close };
};

export default usePopover;
