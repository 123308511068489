import {
  create,
  addEventListener,
  removeEventListener,
  NotificationActionEvent,
} from 'openfin-notifications';
import { Notification } from 'types';

export interface OpenFinNotification {
  task: 'view-notification';
}

const NOTIFICATION_ACTION = 'notification-action';

type Unsubscribe = () => void;

class OpenFin {
  createNotification(notification: Notification) {
    create({
      title: notification.verb,
      body: '',
      category: 'Notification',
      customData: notification,
      onSelect: {
        task: 'view-notification',
      },
    });
  }

  subscribe(
    subscriptionCallback: (event: NotificationActionEvent) => void,
  ): Unsubscribe {
    addEventListener(NOTIFICATION_ACTION, subscriptionCallback);

    return () => {
      removeEventListener(NOTIFICATION_ACTION, subscriptionCallback);
    };
  }
}

export default new OpenFin();
