import React from 'react';
import { styled, Theme } from '@mui/material/styles';
import { endBorderStyle, startBorderStyle } from './DateRangeDay';

export interface DateRangeSelectDaysProps {
  isHighlighting: boolean;
  isEndOfHighlighting: boolean;
  isStartOfHighlighting: boolean;
  isStartOfWeek: boolean;
  isEndOfWeek: boolean;
  onMouseEnter?: (evt: React.MouseEvent<HTMLDivElement>) => any;
  onMouseLeave: () => void;
}

const DateRangeSelectDays = styled('div', {
  shouldForwardProp: (propName: string) => {
    return (
      [
        'isHighlighting',
        'isEndOfHighlighting',
        'isStartOfHighlighting',
        'isStartOfWeek',
        'isEndOfWeek',
      ].indexOf(propName) === -1
    );
  },
})<DateRangeSelectDaysProps>(
  ({ theme, ...ownerState }: DateRangeSelectDaysProps & { theme: Theme }) => ({
    ...(ownerState.isHighlighting && {
      borderRadius: 0,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    }),
    ...(ownerState.isEndOfHighlighting && {
      ...endBorderStyle,
      paddingRight: 0,
      // marginRight: 1,
    }),
    ...(ownerState.isStartOfHighlighting && {
      ...startBorderStyle,
      paddingLeft: 0,
      // marginLeft: 1,
    }),
    ...(ownerState.isStartOfWeek &&
      ownerState.isHighlighting && {
        ...startBorderStyle,
      }),
    ...(ownerState.isEndOfWeek &&
      ownerState.isHighlighting && {
        ...endBorderStyle,
      }),
  }),
);

export default DateRangeSelectDays;
