import Rollbar from 'rollbar';
import { Middleware, Dispatch, MiddlewareAPI } from 'redux';
import { CRUDAction } from './actions/crud';

const rollbarMiddleware =
  (rollbar: Rollbar): Middleware =>
  (store: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: CRUDAction) => {
    if (action.type.toLowerCase().includes('failure')) {
      next(action);
      return rollbar.error(action.payload, {
        action: JSON.stringify(action),
        state: JSON.stringify(store.getState()),
      });
    }

    return next(action);
  };

export default rollbarMiddleware;
