import get from 'lodash/get';
import omit from 'lodash/omit';
import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { UnifiedRoutine } from 'redux-saga-routines';
import HttpError from 'services/HttpError';

import { Action } from 'store/actions';
import { authSelectors } from 'store/selectors';

export const apiSagaFormHandler = (
  routine: UnifiedRoutine,
  apiService: (...args: any) => any,
  {
    authRequired,
    redirectUrl,
  }: {
    authRequired?: boolean;
    redirectUrl?: any;
  },
) =>
  function* (action: Action) {
    const { payload } = action;
    const redirectUri = get(payload, 'redirectUrl', redirectUrl);
    const cleanedPayload = omit(payload, 'redirectUrl');
    try {
      const callArgs: Array<any> = [cleanedPayload];
      if (authRequired) {
        const accessToken: string = yield select(authSelectors.accessToken);
        callArgs.push(accessToken);
      }
      const {
        response: { data },
      } = yield call(apiService, ...callArgs);
      yield put(routine.fulfill());
      yield put(routine.success({ ...data, ...cleanedPayload }));
      if (redirectUri) {
        const formattedRedirectUrl = Object.keys(cleanedPayload).reduce(
          (url: string, propertyName: string) =>
            url.replace(
              `:${propertyName}`,
              (cleanedPayload as Record<string, any>)[propertyName],
            ),
          redirectUri,
        );
        yield put(push(formattedRedirectUrl));
      }
    } catch (error) {
      if ((error as HttpError).body) {
        yield put(
          routine.failure({
            ...(error as HttpError).body,
            _error:
              (error as HttpError).body.detail ||
              (error as HttpError).body.status,
          }),
        );
        return;
      }
      yield put(routine.failure({ _error: (error as Error).message }));
    }
  };

export const sagaFetchHandler = (
  routine: UnifiedRoutine,
  apiService: (...args: any) => any,
  {
    redirectUrl,
  }: {
    redirectUrl?: any;
  } = {},
) =>
  function* (action: Action) {
    const { payload } = action;
    try {
      const accessToken: string = yield select(authSelectors.accessToken);
      const { response, error } = yield call(apiService, payload, accessToken);
      if (error) {
        // ToDo: add here notification about an error
        return;
      }
      const { data } = response;
      yield put(routine.success({ ...data, ...payload }));
      if (redirectUrl) {
        const formattedRedirectUrl = Object.keys(payload).reduce(
          (url: string, propertyName: string) =>
            url.replace(
              `:${propertyName}`,
              (data as Record<string, any>)[propertyName],
            ),
          redirectUrl,
        );
        yield put(push(formattedRedirectUrl));
      }
    } catch (error) {
      // ToDo: add here also notification about an error
      yield put(routine.failure(error));
    }
  };
