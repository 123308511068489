import { createRoutine } from './utils';

export const accessibilitySet = createRoutine('ACCESSIBILITY_SET');

export interface AccessibilityPayload {
  fontSize?: string;
  colorTheme?: string;
}

const accessibilityActions = {
  accessibilitySet,
};

export default accessibilityActions;
