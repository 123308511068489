import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { DocumentType } from 'types';

export interface DocumentTypePayload extends PageQuery {
  doc_type?: string;
  id: number;
}

const list = (query: DocumentTypePayload, token: string) =>
  request<Empty, PageResponse<DocumentType>, Empty>(
    `wm-objects/document-type/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const documentTypesServices = {
  list,
};

export default documentTypesServices;
