import { ShortUser } from 'types';

const formatUser = (user: ShortUser, appendEmail: boolean = false) => {
  let formattedUser = '';
  if (user.first_name) {
    formattedUser += user.first_name;
  }
  if (user.last_name) {
    formattedUser += (formattedUser.length ? ' ' : '') + user.last_name;
  }
  if (formattedUser === '') {
    return user.email;
  }
  if (!formattedUser.includes(user.email) && appendEmail) {
    formattedUser = `${formattedUser} [${user.email}]`;
  }
  return formattedUser;
};

export default formatUser;
