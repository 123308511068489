import get from 'lodash/get';
import isEmptyValue from 'lodash/isEmpty';

const isEmpty = (
  childProps: Record<string, unknown>,
  record: Record<string, unknown>,
) => {
  if (childProps && childProps.hideEmpty && childProps.source) {
    const value = get(record, childProps.source as string);
    if (
      value &&
      (Array.isArray(value) || typeof value === 'object') &&
      isEmptyValue(value)
    ) {
      return true;
    }
    return !value;
  }
  return false;
};

export default isEmpty;
