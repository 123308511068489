import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

interface Props {
  field: ControllerRenderProps;
  defaultValue: number;
}

const useStepper = ({ field, defaultValue }: Props) => {
  const increment = React.useCallback(() => {
    const value = parseInt(
      field.value !== undefined ? field.value : defaultValue,
    );
    field.onChange(value + 1);
  }, [field, defaultValue]);
  const decrement = React.useCallback(() => {
    const value = parseInt(field.value || defaultValue);
    if (value > 0) {
      field.onChange(value - 1);
    }
  }, [field, defaultValue]);
  return {
    increment,
    decrement,
  };
};

export default useStepper;
