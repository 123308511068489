import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const Link = styled(RouterLink)(() => ({
  textDecoration: 'none',
  color: 'inherit',
  position: 'relative',
  boxSizing: 'border-box',
  cursor: 'pointer',
  padding: 0,
  border: 0,
}));

export default Link;
