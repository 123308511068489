import { ReactNode, useCallback } from 'react';
import { connectModal, InjectedProps } from 'redux-modal';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { modals } from 'contstants';
import Grid from 'components/Grid';
import { Button } from 'components/buttons';

export interface InfoDialogProps extends InjectedProps {
  confirm?: () => void;
  title?: string;
  content?: ReactNode;
  btnText?: string;
}

export const InfoDialog = ({
  show,
  handleHide,
  confirm,
  content,
  btnText = 'OK',
  title = '',
}: InfoDialogProps) => {
  const confirmHandle = useCallback(() => {
    if (confirm) {
      confirm();
    }
    handleHide();
  }, [handleHide, confirm]);

  return (
    <Dialog fullWidth open={show} onClose={handleHide}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              onClick={confirmHandle}
              color="primary"
            >
              {btnText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default connectModal({
  name: modals.INFO_DIALOG,
  destroyOnHide: true,
})(InfoDialog);
