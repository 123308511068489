import { ChipProps as MuiChipProps } from '@mui/material/Chip/Chip';
import { alpha, styled, Theme } from '@mui/material/styles';
import { StyledComponent } from '@mui/styles';
import MuiChip from '@mui/material/Chip';
import { Color } from 'types';

export type Variant =
  | 'default'
  | 'outlined'
  | 'rounded'
  | 'roundedOutlined'
  | string;

export interface MaterialChipProps
  extends Omit<MuiChipProps, 'variant' | 'color' | 'opacity'> {
  color?: Color;
  opacity?: boolean;
  variant?: Variant;
  minWidth?: number;
  mr?: number;
}

const variantStyles = (
  variant: Variant,
  color: Color,
  opacity: boolean,
  theme: Theme,
) => {
  const backgroundColor = {
    primary: theme.palette.primary.main,
    info: theme.palette.text.disabled,
    success: theme.palette.success.main,
    secondary: theme.palette.secondary.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
    secondaryInfo: theme.palette.warning.contrastText,
  }[color];
  // const textColor = {
  //   primary: theme.palette.primary.contrastText,
  //   info: theme.palette.info.contrastText,
  //   success: theme.palette.success.contrastText,
  //   secondary: theme.palette.secondary.contrastText,
  //   warning: theme.palette.warning.contrastText,
  //   error: theme.palette.error.contrastText,
  //   secondaryInfo: theme.palette.info.main,
  // }[color];
  switch (variant) {
    case 'roundedOutlined': {
      const borderColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.text.disabled,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        secondaryInfo: theme.palette.warning.contrastText,
      }[color];
      return {
        color: theme.palette.info.main,
        borderRadius: 20,
        border: `1px solid ${borderColor}`,
        padding: theme.spacing(1, 2),
        backgroundColor: 'transparent',
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '& $label': {
          padding: 0,
        },
      };
    }
    case 'rounded': {
      return {
        borderRadius: 20,
        color: theme.palette.info.main,
        backgroundColor: alpha(backgroundColor, opacity ? 0.19 : 1),
        '&:focus': {
          backgroundColor: alpha(backgroundColor, 0.1),
        },
        '& .label': {
          padding: 0,
        },
      };
    }
    case 'outlined': {
      const borderColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.text.disabled,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        secondaryInfo: theme.palette.warning.contrastText,
      }[color];
      return {
        border: `1px solid ${borderColor}`,
        padding: theme.spacing(1, 2),
        color: theme.palette.info.main,
        backgroundColor: 'transparent',
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '& $label': {
          padding: 0,
        },
      };
    }
    case 'default':
    default: {
      return {
        color: theme.palette.info.main,
        backgroundColor: alpha(backgroundColor, opacity ? 0.19 : 1),
        '&:focus': {
          backgroundColor: alpha(backgroundColor, 0.1),
        },
      };
    }
  }
};

const MaterialChip = styled(MuiChip, {
  shouldForwardProp: (propName: string) => propName !== 'opacity',
})<MaterialChipProps>(
  ({
    theme,
    variant = 'default',
    color = 'info',
    opacity = false,
    minWidth,
    mr,
  }) => ({
    borderRadius: 4,
    ...variantStyles(variant, color, opacity, theme),
    ...(minWidth && minWidth >= 0 && { minWidth }),
    ...(mr && mr >= 0 && { marginRight: mr }),
    '& .label': {
      ...theme.typography.body2,
    },
    '& .MuiChip-deleteIcon': {
      padding: theme.spacing(0.625),
      color: theme.palette.info.main,
    },
    '& .MuiChip-deleteIcon:hover': {
      color: theme.palette.info.main,
      backgroundColor: theme.palette.text.disabled,
    },
  }),
);

export default MaterialChip as StyledComponent<MaterialChipProps>;
