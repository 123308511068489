import { ReactNode } from 'react';
import { LandingLayout } from 'layouts';
import Grid from 'components/Grid';
import TitleRoute, { TitleRouteProps } from 'routingComponents/TitleRoute';
import withRedirectController from 'hocs/withRedirectController';

interface AuthRouteProps extends TitleRouteProps {
  urlParam?: string;
  redirectTo?: string;
}

const AuthRoute = ({ children, ...rest }: AuthRouteProps) => {
  return (
    <TitleRoute {...rest}>
      <Grid container fullHeight overflow="auto">
        <LandingLayout>{children as ReactNode}</LandingLayout>
      </Grid>
    </TitleRoute>
  );
};

export default withRedirectController<AuthRouteProps>(AuthRoute);
