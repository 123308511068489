import { ReducerState } from 'store/reducers';

const notifications = (state: ReducerState) => state.notifications;

const inAppNotifications = (state: ReducerState) =>
  notifications(state).get('inAppNotifications');
const count = (state: ReducerState) => inAppNotifications(state).size;
const last = (state: ReducerState) => {
  const notifications = inAppNotifications(state);
  return notifications.get(notifications.size - 1);
};

const notificationSelectors = {
  notifications,
  count,
  last,
};

export default notificationSelectors;
