import get from 'lodash/get';

type Order = 'asc' | 'desc';

const sortBy =
  (name: string, order: Order = 'asc', insensitive: boolean = true) =>
  (opt1: Record<string, any>, opt2: Record<string, any>) => {
    if (insensitive) {
      const val1 = get(opt1, name) || '';
      const val2 = get(opt2, name) || '';
      return val1.localeCompare(val2, undefined, {
        numeric: true,
        sensitivity: insensitive ? 'base' : 'case',
      });
    }
    if (get(opt1, name) > get(opt2, name)) return order === 'asc' ? 1 : -1;
    if (get(opt1, name) < get(opt2, name)) return order === 'asc' ? -1 : 1;
    return 0;
  };

export default sortBy;
