import {
  Empty,
  PageQuery,
  PageResponse,
  request,
  ResponseData,
} from 'services/request';
import { RuleBreach } from 'types';

export interface RulesBreachesPayload extends PageQuery {
  documentId?: string;
}

const list = ({ documentId, ...query }: RulesBreachesPayload, token: string) =>
  request<Empty, PageResponse<RuleBreach>, Empty>(
    `wm-objects/operational-data/${documentId}/breached-rule/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

export interface DetailPayload {
  id: string;
}

export interface RulesBreachesDetail {
  id: string;
  rules_breaches: Array<RuleBreach>;
}

const detail = (
  { id }: DetailPayload,
  token: string,
): Promise<ResponseData<RulesBreachesDetail>> =>
  list({ documentId: id }, token).then(({ response, error }) => ({
    response: error
      ? undefined
      : {
          ...response!,
          data: {
            id,
            rules_breaches: response!.data!.results || [],
          },
        },
    error,
  }));

interface RulesBreachesUpdatePayload {
  documentId: string | number;
  rules_breaches: Array<RuleBreach>;
}

const update = (
  { documentId, rules_breaches }: RulesBreachesUpdatePayload,
  token: string,
) =>
  request<Empty, Array<RuleBreach>, Empty>(
    `wm-objects/operational-data/${documentId}/breached-rule/`,
    {
      method: 'POST',
      payload: rules_breaches,
    },
    token,
  ).then(({ response, error }) => ({
    response: response
      ? {
          ...response,
          data: {
            id: documentId,
            rules_breaches: response.data,
          },
        }
      : undefined,
    error,
  }));

const rulesBreachesServices = {
  list,
  detail,
  update,
};

export default rulesBreachesServices;
