import React from 'react';
import { connectModal, InjectedProps } from 'redux-modal';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { modals } from 'contstants';
import Grid from 'components/Grid';
import { Button } from 'components/buttons';
import throwAwayImage from 'assets/throw_away.svg';

export interface ConfirmDialogProps extends InjectedProps {
  confirm: () => void;
  title?: string;
  content?: React.ReactNode;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

export const ConfirmDialog = ({
  show,
  handleHide,
  confirm,
  content = <img src={throwAwayImage} alt="remove" />,
  confirmBtnText = 'Yes',
  cancelBtnText = 'Cancel',
  title = 'Are you sure that you want to delete it?',
}: ConfirmDialogProps) => {
  const confirmHandle = React.useCallback(() => {
    if (confirm) {
      confirm();
    }
    handleHide();
  }, [handleHide, confirm]);
  return (
    <Dialog
      fullWidth
      open={show}
      onClose={handleHide}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              onClick={confirmHandle}
              color="primary"
            >
              {confirmBtnText}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              onClick={handleHide}
              color="info"
            >
              {cancelBtnText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default connectModal({
  name: modals.CONFIRM_DIALOG,
  destroyOnHide: true,
})(ConfirmDialog);
