import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { BreachSupporting } from 'types';

export interface BreachSupportingPayload extends PageQuery {
  breach_supporting?: string;
  id: number;
}

const list = (query: BreachSupportingPayload, token: string) =>
  request<Empty, PageResponse<BreachSupporting>, Empty>(
    `wm-objects/breach-supporting/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const breachesSupportingServices = {
  list,
};

export default breachesSupportingServices;
