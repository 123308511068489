import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Cf } from 'types';

export interface CfPayload extends PageQuery {
  id: number;
  cf?: string;
}

const list = (query: CfPayload, token: string) =>
  request<Empty, PageResponse<Cf>, Empty>(
    `wm-objects/cf/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const cfServices = {
  list,
};

export default cfServices;
