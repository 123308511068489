import React from 'react';
import { connectModal } from 'redux-modal';

import { modals } from 'contstants';
import { ConfirmDialog, ConfirmDialogProps } from '../ConfirmDialog';

type DeactivateDialogProps = Omit<
  ConfirmDialogProps,
  'cancelBtnText' | 'confirmBtnText'
>;

const EmptyBasketDialog = ({
  show,
  handleHide,
  handleDestroy,
  confirm,
  content,
  title = 'Are you sure you want to empty basket?',
}: DeactivateDialogProps) => (
  <ConfirmDialog
    show={show}
    handleHide={handleHide}
    handleDestroy={handleDestroy}
    confirm={confirm}
    content={content}
    title={title}
    confirmBtnText="Clear"
  />
);

export default connectModal({
  name: modals.EMPTY_BASKET_DIALOG,
  destroyOnHide: true,
})(EmptyBasketDialog);
