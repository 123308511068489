import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { styled } from '@mui/material/styles';
import MuiSnackbar, { SnackbarProps } from '@mui/material/Snackbar';

import { notificationsSelectors } from 'store/selectors';
import { ReducerState } from 'store/reducers';
import { notificationActions } from 'store/actions';
import {
  NotificationPayload,
  NotificationType,
} from 'store/actions/notification';

export interface NotificationProps {
  lastNotification?: NotificationPayload;
  autoHideDuration?: number;
  count: number;
  hideNotification: () => any;
}

const Snackbar = styled(MuiSnackbar, {
  shouldForwardProp: (propName: string) => propName !== 'type',
})<SnackbarProps & { type: NotificationType }>(({ type, theme }) => {
  switch (type) {
    default:
    case 'info':
      return {
        '& .MuiSnackbarContent-root': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.info.contrastText,
        },
      };
    case 'success':
      return {
        '& .MuiSnackbarContent-root': {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
        },
      };
    case 'error':
      return {
        '& .MuiSnackbarContent-root': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.primary.contrastText,
        },
      };
    case 'warning':
      return {
        '& .MuiSnackbarContent-root': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
      };
    case 'undo':
      return {
        '& .MuiSnackbarContent-root': {
          color: theme.palette.primary.light,
        },
      };
  }
});

const Notification = ({
  autoHideDuration = 4e3,
  hideNotification,
  lastNotification,
}: NotificationProps) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(!!lastNotification);
  }, [lastNotification]);

  const handleRequestClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleExited = React.useCallback(() => {
    hideNotification();
  }, [hideNotification]);
  return (
    <Snackbar
      type={lastNotification?.type || 'info'}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      message={lastNotification?.message}
      autoHideDuration={
        lastNotification?.notificationOptions?.messageArgs?.autoHideDuration ||
        autoHideDuration
      }
      disableWindowBlurListener={
        lastNotification?.notificationOptions?.undoable
      }
      onClose={handleRequestClose}
      TransitionProps={{
        onExited: handleExited,
      }}
    />
  );
};

export default connect(
  (state: ReducerState) => ({
    lastNotification: notificationsSelectors.last(state),
    count: notificationsSelectors.count(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideNotification: notificationActions.hideNotification,
      },
      dispatch,
    ),
)(Notification);
