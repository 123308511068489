import React from 'react';
import { useFieldWatch } from 'contexts/Form';

interface DependencyFieldProps {
  fields: string[];
  children: React.ReactNode;
  hidden?: boolean;
  match?: (fieldName: string, value: unknown) => boolean;
}

const DependencyField = ({
  fields,
  children,
  hidden,
  match,
}: DependencyFieldProps) => {
  const values = useFieldWatch(fields);
  return Object.keys(values).every((fieldName: string) => {
    const value = (values as Record<string, unknown>)[fieldName];
    return Boolean(match ? match(fieldName, value) : value);
  }) ? (
    (children as React.ReactElement)
  ) : hidden ? (
    <div style={{ display: 'none' }}>{children}</div>
  ) : null;
};

export default DependencyField;
