import React from 'react';
import MuiPaper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useFormValuesControl } from 'contexts';
import Grid from 'components/Grid';
import PaperContainer from 'components/PaperContainer';
import AccessibilityConf from './AccessibilityConf';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

const Paper = styled(MuiPaper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 10,
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  right: theme.spacing(1),
  padding: theme.spacing(1),
}));

const AccessibilityPopover = () => {
  const { setValue } = useFormValuesControl();
  const onClose = React.useCallback(() => {
    setValue('accessibility_mode', false);
  }, [setValue]);
  return (
    <Portal container={document.querySelector('main')}>
      <Paper>
        <PaperContainer noPadding noOverflow>
          <Grid container justifyContent="space-between">
            <Grid item xs={10}>
              <AccessibilityConf />
            </Grid>
            <Grid
              item
              xs={2}
              container
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <IconButton title="Close" onClick={onClose} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </PaperContainer>
      </Paper>
    </Portal>
  );
};

export default AccessibilityPopover;
