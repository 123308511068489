import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Theme } from 'types';

export interface ThemePayload extends PageQuery {
  theme?: string;
  id?: number;
}

const list = (query: ThemePayload, token: string) =>
  request<Empty, PageResponse<Theme>, Empty>(
    `wm-objects/theme/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const themesServices = {
  list,
};

export default themesServices;
