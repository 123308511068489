import React from 'react';

const useFullscreen = <T extends HTMLElement>() => {
  const elementRef = React.useRef<T>(null);
  const [isFullscreenModeEnabled, setFullScreenMode] =
    React.useState<boolean>(false);
  const toggleFullscreenMode = React.useCallback(() => {
    if (!document.fullscreenElement) {
      if (elementRef.current) {
        elementRef.current.requestFullscreen();
        setFullScreenMode(true);
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullScreenMode(false);
    }
  }, [elementRef, setFullScreenMode]);

  return {
    elementRef,
    isFullscreenModeEnabled,
    toggleFullscreenMode,
  };
};

export default useFullscreen;
