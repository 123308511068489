import config from 'config';

const rollbarConfig = {
  accessToken: config.rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: config.codeVersionJs,
        source_map_enabled: true,
      },
    },
  },
};

export default rollbarConfig;
