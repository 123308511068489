import React from 'react';
import { Location } from 'history';
import { Navigation, NavigationItem } from 'types';

interface UseSidebarItemProps {
  location: Location;
  item: NavigationItem;
  pushNavigation: (nav: Navigation) => void;
}

const useNavItem = ({
  location,
  item,
  pushNavigation,
}: UseSidebarItemProps) => {
  const hasItems = React.useMemo(() => item.subItems !== undefined, [item]);
  const [isOpen, setOpenState] = React.useState<boolean>(false);
  const handleClick = React.useCallback(() => {
    if (item.nestedNav) {
      pushNavigation(item.nestedNav);
      return;
    }
    setOpenState((prevState: boolean) => {
      if (item.subItems) {
        return !prevState;
      }
      return prevState;
    });
  }, [pushNavigation, item, setOpenState]);

  const isActive = React.useCallback(
    (url: string | undefined) => {
      if (url && location.pathname.indexOf(url) === 0) {
        const correctLocation =
          location.pathname[location.pathname.length - 1] !== '/'
            ? `${location.pathname}/`
            : location.pathname;
        const correctUrl = url[url.length - 1] !== '/' ? `${url}/` : url;
        return correctUrl.length === correctLocation.length;
      }
      return false;
    },
    [location],
  );

  return {
    hasItems,
    isOpen,
    handleClick,
    isActive,
  };
};

export default useNavItem;
