import { numericality } from 'redux-form-validators';

const isNumber = (value?: number) => {
  return numericality({
    '>=': 0,
    '<=': 2147483647,
    allowBlank: true,
  })(value);
};

export default isNumber;
