import {
  ChangeEvent,
  useCallback,
  useState,
  useMemo,
  KeyboardEvent,
} from 'react';
import { ControllerRenderProps } from 'react-hook-form';

interface UseInput {
  field: ControllerRenderProps;
  useOnEnterChange?: boolean;
  many?: boolean;
  trim?: boolean;
}

const useInput = ({ field, useOnEnterChange, many, trim }: UseInput) => {
  const [inputValue, setInputValue] = useState('');
  const onChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!useOnEnterChange) {
        field.onChange(`${evt.target.value}`);
        return;
      }
      setInputValue(evt.target.value);
    },
    [setInputValue, field, useOnEnterChange],
  );
  const onInputChange = useCallback(
    (evt: Record<string, unknown>) => {
      const value = evt.target
        ? (evt.target as Record<string, unknown>).value
        : evt;
      if (many) {
        const currentValue = field.value || [];
        field.onChange([...currentValue, value]);
        setInputValue('');
        return;
      }
      field.onChange(value);
    },
    [field, many, setInputValue],
  );
  const onKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === 'Enter' && inputValue.trim() !== '') {
        onInputChange({
          target: {
            value: inputValue,
          },
        });
        evt.preventDefault();
      }
    },
    [inputValue, onInputChange],
  );

  const value = useMemo(() => {
    return useOnEnterChange ? inputValue : field.value;
  }, [field, inputValue, useOnEnterChange]);

  const onBlur = useCallback(() => {
    if (trim && field.value && typeof field.value === 'string') {
      field.onChange(field.value.trim());
    }
    field.onBlur();
  }, [trim, field]);

  return {
    onChange,
    onBlur,
    onKeyDown,
    value,
  };
};

export default useInput;
