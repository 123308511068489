import React from 'react';
import { styled } from '@mui/material/styles';
import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { Color } from 'types';
import RadioController from 'components/inputs/Radio/RadioController';

export interface FormControlLabelProps extends MuiFormControlLabelProps {
  noIcon?: boolean;
}

const FormControlLabel = styled(MuiFormControlLabel, {
  shouldForwardProp: (propName: string) => propName !== 'noIcon',
})<FormControlLabelProps>(({ theme, noIcon }) => ({
  flex: 1,
  marginLeft: 0,
  marginRight: 0,
  ...(noIcon && {
    width: '100%',
    margin: 0,
    '& .label': {
      border: `1px solid ${theme.palette.text.disabled}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      width: '100%',
      margin: 0,
    },
  }),
}));

export interface RadioControlLabelProps {
  value: string;
  label: FormControlLabelProps['label'];
  disabled?: boolean;
  variant?: Color;
  noIcon?: boolean;
}

const RadioControlLabel = ({
  value,
  label,
  noIcon,
  disabled = false,
  variant = 'primary',
}: RadioControlLabelProps) => (
  <FormControlLabel
    noIcon={noIcon}
    value={value}
    label={label}
    control={
      <RadioController disabled={disabled} variant={variant} noIcon={noIcon} />
    }
  />
);

export default RadioControlLabel;
