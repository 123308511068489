import React from 'react';
import { default as MuiSlider } from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';
import { useInput } from 'contexts';
import FormInputControl from 'components/inputs/FormInputControl';

const baseStyles = {
  height: 8,
  opacity: 1,
  borderRadius: 4,
};

const StyledSlider = styled(MuiSlider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  '& .MuiSlider-rail': {
    ...baseStyles,
    backgroundColor: theme.palette.grey.A100,
  },
  '& .MuiSlider-track': {
    ...baseStyles,
  },
  '& .MuiSlider-thumb': {
    width: 24,
    height: 24,
    marginTop: theme.spacing(-1),
    boxShadow: `0px 4px 8px ${alpha(theme.palette.common.black, 41)}`,
  },
}));

export interface SliderProps {
  name: string;
  [prop: string]: any;
}

const Slider = ({ name, rules, label, classes, ...props }: SliderProps) => {
  const { field, meta } = useInput({ name, rules });
  return (
    <FormInputControl label={label} name={field.name} meta={meta}>
      <StyledSlider id={name} {...field} {...props} />
    </FormInputControl>
  );
};

export default Slider;
