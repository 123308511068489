import React from 'react';
import { styled } from '@mui/material/styles';
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';

export interface PaperProps extends MuiPaperProps {
  noPadding?: boolean;
  noOverflow?: boolean;
}

const Paper = styled(MuiPaper, {
  shouldForwardProp: (propName: string) =>
    ['noPadding', 'noOverflow'].indexOf(propName) === -1,
})<PaperProps>(({ theme, noPadding, noOverflow }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  position: 'relative',
  flex: 1,
  padding: 0,
  height: '100%',
  borderRadius: 0,
  marginBottom: theme.spacing(1),
  ...(!noPadding && {
    padding: theme.spacing(2),
  }),
  ...(!noOverflow && {
    overflow: 'auto',
  }),
}));

export interface PaperContainerProps {
  noPadding?: boolean;
  noOverflow?: boolean;
  elevation?: number;
  children: React.ReactNode;
  sx?: MuiPaperProps['sx'];
}

const PaperContentContainer = styled('div')({
  borderRadius: '4px',
  overflow: 'hidden',
  height: '100%',
});

const PaperContainer = React.forwardRef<HTMLDivElement, PaperContainerProps>(
  ({ children, noPadding, noOverflow, sx }, ref) => (
    <PaperContentContainer sx={sx}>
      <Paper
        ref={ref}
        elevation={0}
        noPadding={noPadding}
        noOverflow={noOverflow}
        sx={sx}
      >
        {children}
      </Paper>
    </PaperContentContainer>
  ),
);

export default PaperContainer;
