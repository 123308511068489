import moment from 'moment/moment';

const hasYearChanged = (
  currentDate: moment.Moment = moment(),
  selectDate: moment.Moment,
) => {
  let currentDay = currentDate.date();
  let currentMonth = currentDate.month();
  let currentYear = currentDate.year();

  const selectDay = selectDate.date();
  const selectMonth = selectDate.month();
  const selectYear = selectDate.year();

  return currentMonth === selectMonth && currentDay === selectDay
    ? currentYear !== selectYear
    : false;
};

export default hasYearChanged;
