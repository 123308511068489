import omit from 'lodash/omit';
import { Empty, request } from 'services/request';
import { NodesEmail } from 'types';

const create = (payload: NodesEmail, token: string) => {
  return request<NodesEmail, NodesEmail, Empty>(
    `wm-objects/operational-data/${payload.operation_data}/nodes-email/`,
    {
      payload: omit(payload, 'operation_data') as NodesEmail,
      method: 'POST',
    },
    token,
  );
};

const nodesEmailServices = {
  create,
};

export default nodesEmailServices;
