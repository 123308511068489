import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Country } from 'types';

export interface CountryPayload extends PageQuery {
  id: number;
  name?: string;
}

const list = (query: CountryPayload, token: string) =>
  request<Empty, PageResponse<Country>, Empty>(
    `wm-objects/country/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const countriesServices = {
  list,
};

export default countriesServices;
