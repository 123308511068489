import React from 'react';
import { useWatch as useFormWatch } from 'react-hook-form';
import { FormContext } from 'contexts/Form/FormContext';

const useWatch = (name: string, defaultValue?: string) => {
  const { control } = React.useContext(FormContext);
  return useFormWatch({
    name,
    defaultValue,
    control,
  });
};

export default useWatch;
