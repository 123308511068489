import isNil from 'lodash/isNil';
import MuiInput, { InputProps as MuiInputProps } from 'components/ui/Input';
import { styled, Theme } from '@mui/material/styles';
import { StyledComponent } from '@mui/styles';
import { BaseInputProps, Color } from 'types';
import { UseControllerMethods } from 'contexts';
import ValidationAdornment from 'components/inputs/ValidationAdornment';
import { ReactComponent as ChooseIcon } from 'assets/choose.svg';
import useInputField from './useInput';
import FormInputControl, { FormControlProps } from '../FormInputControl';

const StyledInput = styled(MuiInput, {
  shouldForwardProp: (propName: string) => propName !== 'color',
})<InputProps>(({ theme, onClick, color }: InputProps & { theme: Theme }) => ({
  ...(onClick && {
    cursor: 'pointer',
  }),
  ...(color === 'black' && {
    color: theme.palette.common.black,
  }),
  ...(color === 'primary' && {
    color: theme.palette.primary.main,
  }),
  ...(color === 'info' && {
    color: theme.palette.info.main,
  }),
  ...(color === 'success' && {
    color: theme.palette.success.main,
  }),
  ...(color === 'secondary' && {
    color: theme.palette.secondary.main,
  }),
  ...(color === 'warning' && {
    color: theme.palette.warning.main,
  }),
  ...(color === 'secondaryInfo' && {
    color: theme.palette.warning.contrastText,
  }),
  ...(color === 'error' && {
    color: theme.palette.error.main,
  }),
  ...(color === 'white' && {
    color: theme.palette.common.white,
  }),
  '& .Mui-focused': {
    color: `${theme.palette.warning.contrastText} !important`,
    '&:after': {
      borderBottomColor: `${theme.palette.warning.contrastText} !important`,
    },
  },
})) as StyledComponent<Partial<InputProps>>;

export interface InputProps
  extends UseControllerMethods,
    BaseInputProps,
    Omit<MuiInputProps, 'classes' | 'error' | 'margin' | 'color'> {
  endAdornment?: MuiInputProps['endAdornment'];
  margin?: FormControlProps['margin'];
  error?: string | boolean;
  many?: boolean;
  trim?: boolean;
  useOnEnterChange?: boolean;
  isLoading?: boolean;
  formControlProps?: FormControlProps;
  disabledStateValidation?: boolean;
  color?: Color | 'black' | 'white';
  colorLabel?: Color | 'black' | 'white';
}

const Input = ({
  label,
  tip,
  many,
  required,
  helpText,
  onClick,
  className,
  useOnEnterChange,
  field,
  meta,
  type,
  error,
  trim,
  disabledStateValidation,
  isLoading = false,
  margin = 'normal',
  formControlProps = {},
  color = 'info',
  colorLabel = 'info',
  inputProps,
  ...props
}: InputProps) => {
  const { invalid, isTouched } = meta;
  const { onKeyDown, onChange, onBlur, value } = useInputField({
    trim,
    field,
    useOnEnterChange,
    many,
  });
  return (
    <FormInputControl
      tip={tip}
      label={label}
      meta={meta}
      required={required}
      name={field.name}
      color={colorLabel}
      // @ts-ignore
      error={error}
      hidden={type === 'hidden'}
      loading={isLoading}
      margin={margin}
      helpText={helpText}
      disabledStateValidation={disabledStateValidation}
      {...formControlProps}
    >
      <StyledInput
        id={field.name}
        color={color}
        autoComplete="false"
        errorState={isTouched && invalid}
        success={disabledStateValidation ? undefined : isTouched && !invalid}
        clickedInput={isNil(onClick) === false}
        required={required}
        error={
          disabledStateValidation
            ? undefined
            : Boolean((isTouched && invalid) || error)
        }
        endAdornment={
          onClick ? <ChooseIcon /> : <ValidationAdornment {...meta} />
        }
        onClick={onClick}
        type={type}
        inputProps={inputProps}
        {...field}
        {...props}
        value={value === undefined || value === null ? '' : value}
        onKeyDown={useOnEnterChange ? onKeyDown : undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
    </FormInputControl>
  );
};

export default Input;
