import { SignInWithAuth0Payload } from 'store/actions/auth';
import { Empty, request } from './request';

export interface SignUpPayload {
  password: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface TokenResponse {
  refresh: string;
  access: string;
}

const signInWithAuth0 = (payload: SignInWithAuth0Payload) =>
  request<SignInWithAuth0Payload, TokenResponse, Empty>('auth/auth0/signin/', {
    method: 'POST',
    payload,
  });

const signUp = (payload: SignUpPayload) =>
  request<SignUpPayload, TokenResponse, Empty>('auth/signup/', {
    method: 'POST',
    payload,
  });

export interface TokenPayload {
  email: string;
  password: string;
}

const signIn = (payload: TokenPayload) =>
  request<TokenPayload, TokenResponse, Empty>('auth/signin/', {
    method: 'POST',
    payload,
  });

export interface ActivateTokenPayload {
  token: string;
  password: string;
}

const activate = (payload: ActivateTokenPayload) =>
  request<ActivateTokenPayload, TokenResponse, Empty>('auth/confirm-email/', {
    method: 'POST',
    payload,
  });

export interface TokenRefreshPayload {
  refresh: string;
}

const tokenRefresh = (payload: TokenRefreshPayload) =>
  request<TokenRefreshPayload, TokenResponse, Empty>('auth/token/refresh/', {
    method: 'POST',
    payload,
  });

export interface TokenVerifyPayload {
  token: string;
}

const tokenVerify = (payload: TokenVerifyPayload) =>
  request<TokenVerifyPayload, Empty, Empty>('auth/token/verify/', {
    method: 'POST',
    payload,
  });

export interface DetailPayload {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}

const detailGet = (token: string) =>
  request<DetailPayload, DetailPayload, Empty>(
    'auth/detail/',
    { method: 'GET' },
    token,
  );

const detailUpdate = (payload: DetailPayload, token: string) =>
  request<DetailPayload, DetailPayload, Empty>(
    'auth/detail/',
    {
      method: 'PATCH',
      payload,
    },
    token,
  );

export interface PasswordResetPayload {
  email: string;
}

const passwordReset = (payload: PasswordResetPayload) =>
  request<PasswordResetPayload, Empty, Empty>('auth/password_reset/', {
    method: 'POST',
    payload,
  });

export interface PasswordResetConfirmPayload {
  password: string;
  token: string;
}

const passwordResetConfirm = (payload: PasswordResetConfirmPayload) =>
  request<PasswordResetConfirmPayload, Empty, Empty>(
    'auth/password_reset/confirm/',
    {
      method: 'POST',
      payload,
    },
  );

const authServices = {
  signUp,
  signIn,
  signInWithAuth0,
  activate,
  tokenRefresh,
  tokenVerify,
  detailGet,
  detailUpdate,
  passwordReset,
  passwordResetConfirm,
};

export default authServices;
