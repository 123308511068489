import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { styled } from '@mui/material/styles';

import { ReducerState } from 'store/reducers';
import { accessibilitySelectors } from 'store/selectors';
import { accessibilityActions } from 'store/actions';
import { ReactForm, ReactFormProps } from 'contexts';
import { RadioControlLabel, RadioInput } from 'components/inputs';
import { colorsList } from 'containers/Accessibility/components/SelectColorForm';
import { fontsList } from 'containers/Accessibility/components/SelectFontForm';
import Tooltip from 'components/Tooltip';
import Grid from 'components/Grid';
import FontSizeCard from 'components/Accessibility/FontSizeCard';
import ColorCard from 'components/Accessibility/ColorCard';
import Typography from 'components/Typography';

const RadioInputContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  '& label': {
    margin: theme.spacing(0.8),
    '& >span:first-child': {
      display: 'none',
    },
    '& >span:last-child': {
      height: 32,
      width: 32,
      lineHeight: '32px',
      display:
        'flex' /* Radio is lower in tree than root, so do we still need this? */,
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 4,
      backgroundColor: theme.palette.grey[100],
    },
    '& .Mui-checked + span:last-child': {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },
}));

const FONT_SIZES = [16, 24, 32];

type AccessibilityConfProps = Omit<ReactFormProps, 'children'>;

const AccessibilityConf = ({
  defaultValues,
  onSubmit,
}: AccessibilityConfProps) => (
  <ReactForm
    direction="row"
    align="center"
    mode="onChange"
    onSubmit={onSubmit}
    defaultValues={defaultValues}
  >
    <Grid
      item
      container
      md={6}
      alignContent="center"
      justifyContent="center"
      spacing={4}
    >
      <Grid item>
        <RadioInput name="fontSize">
          <RadioInputContainer
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography title>Font size: </Typography>
            </Grid>
            {fontsList.map((item, idx) => (
              <Grid item key={idx}>
                <RadioControlLabel
                  variant="info"
                  value={item.id}
                  label={
                    <Tooltip
                      arrow
                      title={<FontSizeCard item={item} isTopBar />}
                      placement="bottom-start"
                    >
                      <Typography
                        style={{ fontSize: FONT_SIZES[idx] }}
                        component="p"
                        variant="h2"
                      >
                        A
                      </Typography>
                    </Tooltip>
                  }
                />
              </Grid>
            ))}
          </RadioInputContainer>
        </RadioInput>
      </Grid>
      <Grid item>
        <RadioInput name="colorTheme">
          <RadioInputContainer
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography title>Colour palette:</Typography>
            </Grid>
            {colorsList.map((item, idx) => (
              <Grid item key={idx}>
                <RadioControlLabel
                  variant="info"
                  value={item.value}
                  label={
                    <Tooltip
                      arrow
                      title={<ColorCard item={item} isTopBar />}
                      placement="bottom-start"
                    >
                      <Typography
                        style={{ fontSize: '24px' }}
                        component="p"
                        variant="h1"
                      >
                        A
                      </Typography>
                    </Tooltip>
                  }
                />
              </Grid>
            ))}
          </RadioInputContainer>
        </RadioInput>
      </Grid>
    </Grid>
  </ReactForm>
);

export default compose<AccessibilityConfProps, {}>(
  connect(
    (state: ReducerState) => ({
      defaultValues: {
        colorTheme: accessibilitySelectors.colorTheme(state),
        fontSize: accessibilitySelectors.fontSize(state),
      },
    }),
    (dispatch) =>
      bindActionCreators(
        {
          onSubmit: (values) => accessibilityActions.accessibilitySet(values),
        },
        dispatch,
      ),
  ),
)(AccessibilityConf);
