// @ts-nocheck
import { all, takeEvery, takeLatest } from 'redux-saga/effects';
// actions
import { authActions, crudActions } from 'store/actions';
import { authService } from 'services';
// helpers
import { apiSagaFormHandler } from './commonHandlers';
// side effects
import {
  callbackSideEffect,
  notificationsSideEffect,
  redirectionSideEffect,
  serviceSideEffect,
} from './sideEffects';
// sagas
import {
  userDetailGet,
  signInWithAuth0,
  userAuth,
  logout,
  logoutToAuth0,
  activateUserDetail,
} from './auth';
import { watchNotification } from './notifications';
import {
  createHandler,
  getManyHandler,
  listHandler,
  removeHandler,
  retrieveHandler,
  updateHandler,
  updateListHandler,
  removeListHandler,
} from './crud';

export default function* rootSaga() {
  yield all([
    // auth
    yield takeLatest(authActions.logout.trigger, logout),
    yield takeLatest(authActions.logoutToAuth0.trigger, logoutToAuth0),
    yield takeLatest(
      authActions.signUp.trigger,
      apiSagaFormHandler(authActions.signUp, authService.signUp, {
        redirectUrl: '/sign-in',
      }),
    ),
    yield takeLatest(authActions.signInWithAuth0.trigger, signInWithAuth0),
    yield takeLatest(authActions.signIn.trigger, userAuth),
    yield takeLatest(authActions.signIn.success, userDetailGet),
    yield takeLatest(authActions.userDetailGet.trigger, userDetailGet),
    yield takeLatest(
      authActions.userDetailUpdate.trigger,
      apiSagaFormHandler(
        authActions.userDetailUpdate,
        authService.detailUpdate,
        {
          authRequired: true,
        },
      ),
    ),
    yield takeLatest(
      authActions.resetPasswordConfirm.trigger,
      apiSagaFormHandler(
        authActions.resetPasswordConfirm,
        authService.passwordResetConfirm,
        { redirectUrl: '/sign-in' },
      ),
    ),
    yield takeLatest(
      authActions.activateUserDetail.trigger,
      activateUserDetail,
    ),
    // crud
    yield takeEvery(crudActions.list.trigger, listHandler),
    yield takeEvery(crudActions.getMany.trigger, getManyHandler),
    yield takeEvery(crudActions.create.trigger, createHandler),
    yield takeEvery(crudActions.retrieve.trigger, retrieveHandler),
    yield takeEvery(crudActions.update.trigger, updateHandler),
    yield takeEvery(crudActions.remove.trigger, removeHandler),
    yield takeEvery(crudActions.listUpdate.trigger, updateListHandler),
    yield takeEvery(crudActions.listRemove.trigger, removeListHandler),
    // side effects
    watchNotification(),
    callbackSideEffect(),
    redirectionSideEffect(),
    serviceSideEffect(),
    notificationsSideEffect(),
  ]);
}
