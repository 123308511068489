import React from 'react';
import { useInput } from 'contexts';
import SingleCheckbox, { FormCheckboxProps } from './SingleCheckbox';

interface ControlledSingleCheckboxProps
  extends Omit<FormCheckboxProps, 'field' | 'error' | 'meta'> {
  name: string;
}

const ControlledSingleCheckbox = ({
  name,
  ...props
}: ControlledSingleCheckboxProps) => {
  const { field, meta, error } = useInput({ name, defaultValue: false });
  const onChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(evt.target.checked);
    },
    [field],
  );
  return (
    <SingleCheckbox
      name={name}
      field={{ ...field, onChange }}
      meta={meta}
      error={error}
      {...props}
    />
  );
};

export default ControlledSingleCheckbox;
