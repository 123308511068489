import {
  MouseEvent,
  ReactElement,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import debounce from 'lodash/debounce';
import moment, { Moment } from 'moment';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import config from 'config';
import DateRangeDay, { DateRangeDayProps } from './DateRangeDay';
import { DateInputOnClick, GteMode, LteMode, Mode, RenderDay } from './types';

interface UseDateRangeProps {
  field: ControllerRenderProps;
  minDate?: DatePickerProps<moment.Moment, moment.Moment>['minDate'];
  maxDate?: DatePickerProps<moment.Moment, moment.Moment>['maxDate'];
}

interface RangeState {
  lte: moment.Moment | null;
  gte: moment.Moment | null;
}

interface InputState {
  anchor: HTMLElement | null;
  value: moment.Moment | null;
  mode: 'lte' | 'gte';
}

interface Result {
  lte: Moment | null;
  gte: Moment | null;
  anchor: HTMLElement | null;
  handleClick: DateInputOnClick;
  handleClose: () => void;
  onChange: (date: moment.Moment, mode?: Mode) => void;
  renderWrappedWeekDay: RenderDay;
  mode: Mode;
}

const useDateRange = ({ field }: UseDateRangeProps): Result => {
  const lte = useMemo(() => {
    return field.value && field.value.lte
      ? moment(field.value.lte).endOf('day')
      : null;
  }, [field.value]);
  const gte = useMemo(() => {
    return field.value && field.value.gte
      ? moment(field.value.gte).startOf('day')
      : null;
  }, [field.value]);
  const onUpdateRef = useRef<boolean>(false);
  const [rangeState, setRangeState] = useState<RangeState>({
    lte: lte || null,
    gte: gte || null,
  });
  const [inputState, setInputState] = useState<InputState>({
    anchor: null,
    value: null,
    mode: 'gte',
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeDebounce = useCallback(
    debounce((inputValue) => {
      field.onChange(inputValue);
    }, 3e2),
    [field],
  );

  useEffect(() => {
    if (
      onUpdateRef.current === false ||
      inputState.anchor !== null ||
      inputState.value === null
    ) {
      return;
    }
    const mode = inputState.mode;
    const currentValue = inputState.value;
    const inputValue = {} as Record<string, unknown>;
    if (
      mode === 'gte' &&
      (gte === null || !(gte as Moment).isSame(currentValue))
    ) {
      inputValue.gte = currentValue.isValid()
        ? currentValue.startOf('day').format(config.backendDateFormat)
        : null;
    }
    if (
      mode === 'lte' &&
      (lte === null || !(lte as Moment).isSame(currentValue))
    ) {
      inputValue.lte = currentValue.isValid()
        ? currentValue.endOf('day').format(config.backendDateFormat)
        : null;
    }
    onChangeDebounce({
      gte: gte !== null ? gte.format(config.backendDateFormat) : gte,
      lte: lte !== null ? lte.format(config.backendDateFormat) : lte,
      ...inputValue,
    });
    onUpdateRef.current = false;
  }, [inputState, onChangeDebounce, gte, lte, onUpdateRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputState.anchor !== null) {
      return;
    }
    const nextState: Record<string, Moment | null> = {};
    if (
      (lte && (rangeState.lte === null || !rangeState.lte.isSame(lte))) ||
      (lte === null && rangeState.lte !== null)
    ) {
      nextState.lte = lte;
    }

    if (
      (gte && (rangeState.gte === null || !rangeState.gte.isSame(gte))) ||
      (gte === null && rangeState.gte !== null)
    ) {
      nextState.gte = gte;
    }

    if (Object.keys(nextState).length > 0) {
      setRangeState({ gte, lte });
    }
  }, [setRangeState, gte, lte, rangeState, inputState]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, mode: Mode) => {
      setInputState({
        ...inputState,
        anchor: event.currentTarget,
        mode,
      });
    },
    [setInputState, inputState],
  );

  const handleClose = useCallback(() => {
    setInputState({
      ...inputState,
      anchor: null,
    });
  }, [setInputState, inputState]);

  const onMouseLeave = useCallback(() => {
    setRangeState({ lte, gte });
  }, [gte, lte]);

  const onChange = useCallback(
    (date: moment.Moment, mode: Mode | null = null) => {
      const currentMode = mode || inputState.mode;
      switch (currentMode) {
        case LteMode:
          if (
            rangeState.gte &&
            moment(date).isValid() &&
            !moment(date).isSameOrAfter(rangeState.gte)
          ) {
            return;
          }
          break;
        case GteMode:
          if (
            rangeState.lte &&
            moment(date).isValid() &&
            !moment(date).isSameOrBefore(rangeState.lte)
          ) {
            return;
          }
          break;
      }
      setRangeState({ ...rangeState, [currentMode]: date });
      setInputState({
        mode: currentMode,
        anchor: null,
        value: date,
      });
      onUpdateRef.current = true;
    },
    [setInputState, inputState, rangeState],
  );

  const handlePreviewDayChange = useCallback(
    (day: moment.Moment) => {
      if (!inputState.anchor) return;
      switch (inputState.mode) {
        case LteMode:
          if (rangeState.gte && !moment(day).isSameOrAfter(rangeState.gte)) {
            return;
          }
          break;
        case GteMode:
          if (rangeState.lte && !moment(day).isSameOrBefore(rangeState.lte)) {
            return;
          }
          break;
      }
      setRangeState({ ...rangeState, [inputState.mode]: day });
    },
    [inputState, setRangeState, rangeState],
  );

  const renderWrappedWeekDay = useCallback(
    (
      day: unknown,
      selectedDates: unknown[],
      DayComponentProps: PickersDayProps<unknown>,
    ): ReactElement => {
      const selectDay = day as moment.Moment;
      const isStartOfPeriod = gte !== null && selectDay.isSame(gte, 'day');
      const isEndOfPeriod = lte !== null && selectDay.isSame(lte, 'day');
      const isWithinRange =
        gte !== null &&
        selectDay.isSameOrAfter(gte, 'day') &&
        lte !== null &&
        selectDay.isSameOrBefore(lte, 'day');
      const isPreviewing =
        inputState.mode === LteMode &&
        rangeState.lte &&
        selectDay.isSameOrBefore(rangeState.lte, 'day') &&
        rangeState.gte &&
        selectDay.isSameOrAfter(rangeState.gte, 'day');
      const isStartOfPreviewing =
        rangeState.gte && selectDay.isSame(rangeState.gte, 'day');
      const isEndOfPreviewing =
        rangeState.lte && selectDay.isSame(rangeState.lte, 'day');

      return (
        <DateRangeDay
          {...(DayComponentProps as unknown as DateRangeDayProps)}
          day={selectDay}
          onMouseLeave={onMouseLeave}
          isSelected={isStartOfPeriod || isEndOfPeriod}
          isPreviewing={!!isPreviewing}
          isStartOfPreviewing={!!isStartOfPreviewing}
          isEndOfPreviewing={!!isEndOfPreviewing}
          isHighlighting={isWithinRange}
          isStartOfHighlighting={isStartOfPeriod}
          isEndOfHighlighting={isEndOfPeriod}
          onMouseEnter={() => handlePreviewDayChange(selectDay)}
        />
      );
    },
    [gte, lte, inputState, rangeState, onMouseLeave, handlePreviewDayChange],
  );

  return {
    lte,
    gte,
    handleClick,
    handleClose,
    onChange,
    anchor: inputState.anchor,
    renderWrappedWeekDay,
    mode: inputState.mode,
  };
};

export default useDateRange;
