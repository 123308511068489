import React from 'react';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';

export interface TitleRouteProps extends RouteProps {
  title?: string;
}

const TitleRoute = ({ title, ...rest }: TitleRouteProps) => {
  React.useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
  return <Route {...rest} />;
};

export default TitleRoute;
