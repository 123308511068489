import { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import debounce from 'lodash/debounce';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { ListComponentProps, withListController } from 'hocs';
import { Notification, OnSuccessCallback } from 'types';
import { notificationActions } from 'store/actions';
import Typography from 'components/Typography';
import { ReactComponent as DoubleIndicatorIcon } from 'assets/doubleIndicator.svg';
import NotificationItem from './NotificationItem';

const Container = styled(Box)(() => ({
  width: '100%',
}));

const TopBar = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 0, 1.5),
}));

const MarkAll = styled(Typography)(({ theme }) => ({
  borderBottom: '1px solid transparent',
  '&:hover': {
    color: theme.palette.success.main,
    borderBottomColor: theme.palette.success.main,
    cursor: 'pointer',
  },
  '& svg': {
    position: 'relative',
    top: 3,
  },
}));

export interface NotificationsPanelProps
  extends ListComponentProps<Notification> {
  markAllAsRead: () => void;
  updateNotification: (
    item: Notification,
    onSuccessCallback: OnSuccessCallback,
  ) => void;
}

const NotificationsPanel = ({
  results,
  updateNotification,
  markAllAsRead,
  isLoading,
}: NotificationsPanelProps) => {
  const resultsCount = results.filter(
    ({ unread }: Partial<Notification>) => unread,
  ).length;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const update = useCallback(
    debounce((item: Notification, onSuccessCallback: OnSuccessCallback) => {
      updateNotification(item, onSuccessCallback);
    }, 5e2),
    [updateNotification],
  );

  return (
    <Container>
      <TopBar display="flex" justifyContent="space-between">
        <Typography color="primary">{resultsCount} unread</Typography>
        <MarkAll onClick={markAllAsRead}>
          Mark all as read <DoubleIndicatorIcon />
        </MarkAll>
      </TopBar>
      <Box>
        {results.map((item: any, idx: number) => (
          <NotificationItem
            loading={isLoading}
            item={item}
            onClick={update}
            key={`notificationItem-${idx}`}
          />
        ))}
      </Box>
    </Container>
  );
};

export default compose<
  NotificationsPanelProps,
  Partial<NotificationsPanelProps>
>(
  withListController('notifications'),
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        markAllAsRead: notificationActions.markAllNotifications(dispatch),
        updateNotification: notificationActions.markNotification(dispatch),
      },
      dispatch,
    ),
  ),
)(NotificationsPanel);
