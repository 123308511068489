import { createContext, ReactNode } from 'react';

export interface DrawerItemConfig {
  header: string | ReactNode;
  content: ReactNode;
  onClose?: () => void;
}

export interface DrawerContainerProps extends DrawerItemConfig {
  padding?: number;
  width?: string;
  open?: boolean;
}

export interface DrawerContextProps {
  show: (drawerItemConfig: DrawerContainerProps) => void;
  close: () => void;
}

const DrawerContext = createContext<DrawerContextProps>({
  show: () => null,
  close: () => null,
});

export default DrawerContext;
