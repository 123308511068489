import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useMemo,
  FunctionComponent,
  ChangeEvent,
} from 'react';
import moment, { Moment } from 'moment';
import debounce from 'lodash/debounce';
import MuiInput, { InputProps as BaseInputProps } from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import MuiIconButton from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import config from 'config';
import { validateDateString } from 'utils';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { DateInputOnClick, Mode } from './types';
import DateFormatInput from './DateFormatInput';
import FieldHelper from '../FormInputControl/FieldHelper';

export interface KeyboardDateInputProps {
  mode: Mode;
  onClick: DateInputOnClick;
  value: Moment | null;
  onChange: (date: moment.Moment, mode: Mode) => void;
  minValue?: Moment;
  maxValue?: Moment;
  onSetError: (newError: Record<string, string>) => void;
  onClearErrors: (name: string) => void;
  error?: string;
}

export interface InputProps extends BaseInputProps {
  successState: boolean;
  errorState: boolean;
}

const StyledInput = styled(MuiInput, {
  shouldForwardProp: (propName: string) =>
    ['successState', 'errorState'].indexOf(propName) === -1,
})<InputProps>(({ theme, ...ownerState }: InputProps & { theme: Theme }) => ({
  ...(ownerState.successState && {
    '&:before': {
      borderBottomColor: theme.palette.success.main,
    },
  }),
  ...(ownerState.errorState && {
    '&:before': {
      borderBottomColor: theme.palette.error.main,
    },
  }),
  input: {
    '&::placeholder': {
      fontSize: theme.typography.fontSize,
    },
  },
  focused: {
    color: `${theme.palette.warning.contrastText} !important`,
    '&:after': {
      borderBottomColor: `${theme.palette.warning.contrastText} !important`,
    },
  },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const KeyboardDateInput = ({
  mode,
  value,
  onClick,
  onChange,
  minValue,
  maxValue,
  onSetError,
  onClearErrors,
  error,
}: KeyboardDateInputProps) => {
  const onClickHandler = useCallback(
    (evt: ReactMouseEvent<HTMLButtonElement>) => {
      onClick(evt as ReactMouseEvent<HTMLInputElement, MouseEvent>, mode);
    },
    [mode, onClick],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeDebounce = useCallback(
    debounce((date: moment.Moment, mode: Mode) => {
      onChange(date, mode);
    }, 3e2),
    [],
  );

  const checkMinMaxValue = useCallback(
    (date: moment.Moment) => {
      if (minValue && date.startOf('day').isBefore(minValue)) {
        onSetError({
          [mode]: 'Date should not be before minimal date',
        });
        return true;
      }
      if (maxValue && date.endOf('day').isAfter(maxValue)) {
        onSetError({
          [mode]: 'Date should not be after maximal date',
        });
        return true;
      }

      if (date.startOf('day').isBefore(config.minDate)) {
        onSetError({
          [mode]: 'Date must not be greater than minimum allowable date',
        });
        return true;
      }

      return false;
    },
    [maxValue, minValue, mode, onSetError],
  );

  const onInputChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (evt.target.value && !validateDateString(evt.target.value)) {
        onSetError({
          [mode]: 'Incorrect date',
        });
        return;
      }
      const date = moment(evt.target.value, config.dateFormat);
      if (checkMinMaxValue(date)) {
        return;
      }
      onClearErrors(mode);
      onChangeDebounce(date, mode);
    },
    [checkMinMaxValue, onClearErrors, onChangeDebounce, mode, onSetError],
  );

  const formatValue = useMemo(() => {
    if (value) {
      return value.format(config.dateFormat);
    }
    return '';
  }, [value]);

  const onBlur = () => {
    const date = moment(value, config.dateFormat);
    if (checkMinMaxValue(date)) {
      return;
    }
  };

  return (
    <FormControl fullWidth>
      <StyledInput
        autoComplete="false"
        inputComponent={
          DateFormatInput as FunctionComponent<InputBaseComponentProps>
        }
        errorState={!!error}
        successState={!error}
        onChange={onInputChange}
        placeholder={config.dateFormat}
        error={!!error}
        onBlur={onBlur}
        endAdornment={
          <IconButton
            title="Open-calendar"
            onClick={onClickHandler}
            size="large"
          >
            <CalendarIcon />
          </IconButton>
        }
        value={formatValue}
        inputProps={{
          valid: !error,
        }}
      />
      <FieldHelper
        meta={{ isTouched: true, invalid: false, isDirty: false }}
        error={error}
      />
    </FormControl>
  );
};

export default KeyboardDateInput;
