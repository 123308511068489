import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Simf } from 'types';

export interface SimfPayload extends PageQuery {
  id: number;
  simf?: string;
}

const list = (query: SimfPayload, token: string) =>
  request<Empty, PageResponse<Simf>, Empty>(
    `wm-objects/simf/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const simfServices = {
  list,
};

export default simfServices;
