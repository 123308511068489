import {
  Empty,
  PageQuery,
  PageResponse,
  request,
  ResponseData,
} from 'services/request';
import taskServices from 'services/tasks';
import { NodeText, NodeTextWithTasks, TaskInstance } from 'types';

export interface NodesListQuery extends PageQuery {
  document_id?: number;
  has_obligation?: boolean;
  withTasks?: boolean;
  assignee: number;
}

export interface NodesResponse {
  nodes: NodeText[];
}

const list = (
  { document_id, assignee, withTasks, ...query }: NodesListQuery,
  token: string,
) =>
  request<PageQuery, PageResponse<NodeTextWithTasks>, Empty>(
    `wm-objects/operational-data/${document_id}/nodes/`,
    {
      method: 'GET',
      query: {
        ...query,
        has_obligations: true,
      },
    },
    token,
  ).then(async ({ response, error }) => {
    if (error) {
      return { error };
    }
    if (withTasks && response?.data?.results?.length) {
      const results = response!.data!.results!;
      const obligationIds = results.map((node) => node.id);
      const taskResponse = (await taskServices.list(
        {
          document_id,
          is_obligation: true,
          assignee,
          obligation_id: obligationIds.join(','),
        },
        token,
      )) as ResponseData<PageResponse<TaskInstance>>;
      if (taskResponse.error) {
        return {
          error: taskResponse.error,
        } as ResponseData<PageResponse<NodeTextWithTasks>>;
      }
      const groupedTasksByObligationId =
        taskResponse.response?.data?.results.reduce(
          (acc: Record<string, TaskInstance[]>, task: TaskInstance) => {
            const tasksByObligation = acc[task.obligation_id!] || [];
            tasksByObligation.push(task);
            acc[task.obligation_id!] = tasksByObligation;
            return acc;
          },
          {} as Record<string, TaskInstance[]>,
        );
      return {
        error,
        response: {
          ...response,
          data: {
            ...response?.data,
            results: results.map((obligation) => ({
              ...obligation,
              tasks: groupedTasksByObligationId
                ? groupedTasksByObligationId[obligation.id]
                : [],
            })),
          },
        },
      } as ResponseData<PageResponse<NodeTextWithTasks>>;
    }
    return {
      response,
    } as ResponseData<PageResponse<NodeTextWithTasks>>;
  });

const nodeServices = {
  list,
  create: taskServices.create,
};

export default nodeServices;
