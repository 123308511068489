import { styled } from '@mui/material/styles';
import MuiBadge from '@mui/material/Badge';

const Badge = styled(MuiBadge)(({ theme }) => ({
  fontSize: 6,
  lineHeight: 18,
  '& 	.MuiBadge-anchorOriginTopRightRectangular': {
    top: -4,
    right: -8,
  },
}));

export default Badge;
