import { ReducerState } from 'store/reducers';

const routerState = (state: ReducerState) => state.router;

const location = (state: ReducerState) => routerState(state).location;
const action = (state: ReducerState) => routerState(state).action;

const routerSelectors = {
  location,
  action,
};

export default routerSelectors;
