import { useDispatch, useSelector } from 'react-redux';
import { crudActions } from 'store/actions';
import { generateResourceSelectors } from 'store/selectors/utils';

const useList = (resourceName: string) => {
  const dispatch = useDispatch();
  const fetchData = (args: Record<string, unknown>) => {
    dispatch(crudActions.list({ ...args, meta: { resource: resourceName } }));
  };
  const { isLoading, results, count, filters, pagination, sort } =
    generateResourceSelectors(resourceName);

  return {
    fetchData,
    loading: useSelector(isLoading),
    data: useSelector(results),
    count: useSelector(count),
    filters: useSelector(filters),
    pagination: useSelector(pagination),
    sort: useSelector(sort),
  };
};

export default useList;
