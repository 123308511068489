const CONFIRM_DIALOG = 'ConfirmDialog';
const DEACTIVATE_DIALOG = 'DeactivateDialog';
const EMPTY_BASKET_DIALOG = 'EmptyBasketDialog';
const DND_LIST_DIALOG = 'DndListDialog';
const INFO_DIALOG = 'InfoDialog';

const modals = {
  CONFIRM_DIALOG,
  DEACTIVATE_DIALOG,
  EMPTY_BASKET_DIALOG,
  DND_LIST_DIALOG,
  INFO_DIALOG,
};

export default modals;
