import { useContext } from 'react';
import DebugControllerContext from './DebugControllerContext';

const useDebugController = () => {
  const { changeDebugState, isDebugMode } = useContext(DebugControllerContext);

  const show = () => {
    changeDebugState(true);
  };

  const hide = () => {
    changeDebugState(false);
  };

  return {
    isDebugMode,
    show,
    hide,
  };
};

export default useDebugController;
