import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Errors } from 'types';
import useStep from './useStep';

interface UseWizardProps<T extends object> {
  maxStep: number;
  initial?: Partial<T>;
  isLoading?: boolean;
  submit: (item: Partial<T>) => void;
  errors?: Errors;
  isCreate?: boolean;
}

const useWizard = <T extends object>({
  maxStep,
  initial = {},
  submit,
  isLoading,
  errors,
  isCreate = false,
}: UseWizardProps<T>) => {
  const isReset = React.useRef<boolean>(true);
  const { currentStep, incrementStep, setStep, isLastStep } = useStep(maxStep);
  const [data, setData] = React.useState<Partial<T>>({});
  const onSubmit = React.useCallback(
    (newDetails: Record<string, unknown>) => {
      setData((prevData) => ({
        ...prevData,
        ...newDetails,
      }));
      incrementStep();
    },
    [setData, incrementStep],
  );
  useEffect(() => {
    const initialData = initial as Record<string, unknown>;
    if (isEmpty(data) && !isCreate) {
      if (initialData && initialData.users) {
        const users = initialData.users as Record<string, unknown>[];
        setData({
          ...initialData,
          users: users.map((user) => ({
            ...user,
            role: user.role === 'group_admin',
          })),
        } as T);

        return;
      }
      setData(initial);
    }
  }, [data, initial, isCreate]);

  React.useEffect(() => {
    if (isLastStep && !isLoading) {
      if (isReset.current) {
        isReset.current = false;
        submit(data);
      }

      if (!isEmpty(errors)) {
        isReset.current = true;
        setStep(0);
        return;
      }
    }
  }, [isLoading, setStep, data, isLastStep, isReset, submit, errors]);
  return {
    isLastStep,
    onSubmit,
    currentStep,
    setStep,
    data,
  };
};

export default useWizard;
