import React from 'react';

import Grid, { GridProps } from 'components/Grid';

import {
  TableHeaderProps,
  UseSortByColumnProps,
  UseTableColumnProps,
} from 'react-table';

import Typography from 'components/Typography';
import { ReactComponent as SortDownIcon } from 'assets/down.svg';
import { ReactComponent as SortUpIcon } from 'assets/up.svg';
import { ReactComponent as SortIcon } from 'assets/updown.svg';

export interface TableHeaderCellProps extends TableHeaderProps {
  align: GridProps['justifyContent'];
  column: UseTableColumnProps<any> & UseSortByColumnProps<any>;
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  title?: string;
}

const TableHeaderCell = ({
  align,
  column,
  ...headerProps
}: TableHeaderCellProps) => {
  const onKeyDown = (event: React.SyntheticEvent<HTMLElement>) => {
    const key = (event as React.KeyboardEvent<HTMLElement>).key;
    if (key === 'Enter' && headerProps.onClick && column.canSort) {
      event.preventDefault();
      event.stopPropagation();
      headerProps.onClick(event);
    }
  };
  return (
    <Grid
      item
      container
      justifyContent={align}
      alignItems="center"
      id={column.canSort ? column.id : undefined}
      onKeyDown={onKeyDown}
      tabIndex={column.canSort ? 0 : undefined}
      spacing={1}
      wrap="nowrap"
      {...headerProps}
      title={headerProps.title || `${column.render('Header')}`}
    >
      <Grid item zeroMinWidth>
        <Typography noWrap variant="body1">
          <b>{column.render('Header')}</b>
        </Typography>
      </Grid>
      {column.canSort && (
        <Grid item>
          <Typography display="flex" variant="body1" component="span">
            {column.isSorted ? (
              column.isSortedDesc ? (
                <SortDownIcon />
              ) : (
                <SortUpIcon />
              )
            ) : (
              <SortIcon />
            )}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default TableHeaderCell;
