import { Empty, request, PageQuery } from 'services/request';
import { DocumentCounter } from 'types';

const list = ({ ...query }: PageQuery, token: string) =>
  request<PageQuery, DocumentCounter, Empty>(
    `wm-objects/document-count/`,
    { method: 'GET', query },
    token,
  );

const themesAndTagsServices = {
  list,
};

export default themesAndTagsServices;
