import React from 'react';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import { alpha, styled } from '@mui/material/styles';
import { Color } from 'types';
import { ReactComponent as CheckboxIcon } from 'assets/checkbox_check.svg';

export interface StyledCheckboxProps extends MuiCheckboxProps {
  noPadding: boolean;
}

const StyledCheckbox = styled(MuiCheckbox, {
  shouldForwardProp: (propName: string) => propName !== 'noPadding',
})<StyledCheckboxProps>(({ theme, noPadding }) => ({
  padding: noPadding ? 0 : theme.spacing(1.125),
  color: theme.palette.text.disabled,
  borderRadius: 4,
  '&:hover, &:focus': {
    color: alpha(theme.palette.text.disabled, 0.4),
  },
  '& input': {
    cursor: 'inherit',
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    zIndex: 1,
    '&:hover + span, &:focus + span': {
      color: alpha(theme.palette.text.disabled, 0.4),
    },
  },
}));

const OPACITY_VALUE = 0.4;

export interface StyledCheckboxIconProps {
  variant: Color;
}

const StyledCheckboxIcon = styled(CheckboxIcon, {
  shouldForwardProp: (propName: string) => propName !== 'variant',
})<StyledCheckboxIconProps>(({ theme, variant }) => ({
  display: 'flex',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0.25),
  width: theme.spacing(3),
  height: theme.spacing(3),
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  ...(variant === 'primary' && {
    backgroundColor: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.primary.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'info' && {
    backgroundColor: theme.palette.secondary.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.secondary.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'warning' && {
    backgroundColor: theme.palette.success.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.success.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'warning' && {
    backgroundColor: theme.palette.warning.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.warning.main, OPACITY_VALUE),
    },
  }),
  ...(variant === 'error' && {
    backgroundColor: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.error.main, OPACITY_VALUE),
    },
  }),
}));

const EmptyCheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  background: 'currentColor',
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export interface CheckboxProps {
  variant?: Color;
  checked?: boolean;
  id?: MuiCheckboxProps['id'];
  edge?: MuiCheckboxProps['edge'];
  onClick?: MuiCheckboxProps['onClick'];
  disabled?: MuiCheckboxProps['disabled'];
  disableRipple?: MuiCheckboxProps['disableRipple'];
  onChange?: MuiCheckboxProps['onChange'];
  name?: string;
  value?: any;
  noPadding?: boolean;
}

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    { variant = 'primary', noPadding = false, ...props }: CheckboxProps,
    ref,
  ) => (
    <StyledCheckbox
      {...props}
      ref={ref}
      noPadding={noPadding}
      icon={<EmptyCheckboxIcon />}
      checkedIcon={<StyledCheckboxIcon variant={variant} />}
    />
  ),
);

export default Checkbox;
