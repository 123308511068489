import React from 'react';

import MaterialPagination from '@mui/material/Pagination';
import MuiPaginationItem, {
  PaginationItemProps,
} from '@mui/material/PaginationItem';
import { styled } from '@mui/material/styles';

import { Button } from 'components/buttons';
import { ReactComponent as ArrowPrevIcon } from 'assets/arrow_prev.svg';
import { ReactComponent as ArrowNextIcon } from 'assets/arrow_next.svg';
import { Size } from 'types';

const PaginationItem = styled(MuiPaginationItem)<PaginationItemProps>(
  ({ theme }) => ({
    fontSize: 16,
    color: theme.palette.warning.contrastText,
    '&.Mui-selected': {
      backgroundColor: theme.palette.text.disabled,
      fontWeight: 500,
    },
  }),
);

const PaginationButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export interface PaginationProps {
  pageCount: number;
  siblingCount?: number;
  boundaryCount?: number;
  currentPage: number;
  onPageClick: ({ page }: { page: number }) => any;
  size?: Size;
}

const Pagination = ({
  pageCount,
  currentPage,
  onPageClick,
  siblingCount = 3,
  size,
}: PaginationProps) => (
  <MaterialPagination
    aria-label="pagination"
    siblingCount={
      currentPage === 1 || currentPage === pageCount ? 1 : siblingCount
    }
    renderItem={(item) => {
      if (item.type === 'previous') {
        return (
          <PaginationButton
            variant="text"
            color="info"
            onClick={item.onClick}
            disabled={item.disabled}
            startIcon={<ArrowPrevIcon />}
            size={size}
          >
            Prev
          </PaginationButton>
        );
      }
      if (item.type === 'next') {
        return (
          <PaginationButton
            variant="text"
            color="info"
            onClick={item.onClick}
            disabled={item.disabled}
            endIcon={<ArrowNextIcon />}
            size={size}
          >
            Next
          </PaginationButton>
        );
      }
      return <PaginationItem {...item} />;
    }}
    count={pageCount}
    page={currentPage}
    onChange={(evt, page) => onPageClick({ page })}
    size={size}
  />
);

export default Pagination;
