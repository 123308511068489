import { styled } from '@mui/material/styles';
import MuiTooltip, {
  tooltipClasses,
  TooltipProps,
} from '@mui/material/Tooltip';

const Tooltip = styled(({ ...props }: TooltipProps) => {
  return <MuiTooltip classes={{ popper: props.className }} {...props} />;
})(({ theme }) => ({
  [`.${tooltipClasses.popper}`]: {
    zIndex: 9999,
  },
  [`.${tooltipClasses.tooltip}`]: {
    margin: theme.spacing(4),
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  [`.${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

export default Tooltip;
