import services from 'store/services';
import resources from 'store/resources';
import { createRoutine } from './utils';

export const updateDocumentNotesAction = createRoutine('UPDATE_DOCUMENT_NOTES');

const updateDocumentNotes = (payload: Record<string, any>) =>
  updateDocumentNotesAction({
    ...payload,
    meta: {
      resource: resources.NOTES,
      service: services.UPDATE_DOCUMENT_NOTES,
      ...payload.meta,
    },
  });

const documentNoteActions = {
  updateDocumentNotes,
};

export default documentNoteActions;
