import React from 'react';
import { Link } from 'react-router-dom';
import Grid from 'components/Grid';
import ErrorBoundary from 'components/ErrorBoundary';
import { ReactComponent as AscentHorizonLogo } from 'assets/ascent_horizon_logo_dark.svg';

export interface LandingLayoutProps {
  children: React.ReactNode;
}

const LandingLayout = ({ children }: LandingLayoutProps) => (
  <Grid
    container
    overflow="auto"
    component="main"
    justifyContent="center"
    alignItems="center"
    direction="column"
  >
    <Grid item container my={6} justifyContent="center">
      <Link to="/sign-in">
        <AscentHorizonLogo />
      </Link>
    </Grid>
    <Grid xs item flexOnly mb={6} minWidth={690}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Grid>
  </Grid>
);

export default LandingLayout;
