import {
  SyntheticEvent,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import get from 'lodash/get';
import { RefCallBack } from 'react-hook-form';
import useAutocomplete from '@mui/material/useAutocomplete';
import { AutocompleteInputChangeReason } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { Rules } from 'types';
import { useInput } from 'contexts';
import useChoices from 'components/inputs/useChoices';

export interface UseTreeSelectProps {
  name: string;
  rules?: Rules;
  optionText?: string;
  optionValue?: string;
  groupByValue?: string;
  choices: Array<Record<string, any>>;
  setFilterValue?: (value: string) => void;
}

export type GeInputProps = () => {
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  onFocus: (event: any) => void;
  onMouseDown: (event: any) => void;
  value: string;
  ref: RefCallBack;
};

interface OnClickProps {
  onClick: (...args: any) => void;
  [prop: string]: any;
}

const useTreeSelect = ({
  name,
  rules,
  choices,
  optionText,
  optionValue,
  groupByValue,
  setFilterValue,
}: UseTreeSelectProps) => {
  const { getChoiceValue, getChoiceText } = useChoices({
    optionText,
    optionValue,
  });
  const getParent = useCallback(
    (choice: Record<string, unknown>): string =>
      get(choice, groupByValue as string) as string,
    [groupByValue],
  );
  const { field, meta, error } = useInput({ name, rules, defaultValue: [] });
  const [inputValue, setInputValue] = useState<string>('');
  const onInputChange = useCallback(
    (
      event: SyntheticEvent,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') return;
      if (reason === 'clear') {
        setInputValue('');
        return;
      }
      setInputValue(newInputValue);
    },
    [setInputValue],
  );
  const onChangeWrapper = useCallback(
    (
      evt: SyntheticEvent,
      value: Record<string, unknown>[] | Record<string, unknown> | null,
    ) => {
      if (value) {
        setInputValue('');
        field.onChange(
          (value as Record<string, unknown>[]).map(getChoiceValue),
        );
        return;
      }
    },
    [field, setInputValue, getChoiceValue],
  );
  const value = useMemo(() => {
    const mapOfChoices = choices.reduce(
      (choicesMap, choice) => ({
        ...choicesMap,
        [getChoiceValue(choice).toString()]: choice,
      }),
      {} as Record<string, any>,
    );
    return field.value
      ? field.value.map((v: string) => mapOfChoices[v.toString()])
      : [];
  }, [field, choices, getChoiceValue]);
  const sortedChoices = useMemo(() => {
    return choices.sort((opt1, opt2) => {
      if (getParent(opt1) > getParent(opt2)) return 1;
      if (getParent(opt1) < getParent(opt2)) return -1;

      if (getChoiceText(opt1) > getChoiceText(opt2)) return 1;
      if (getChoiceText(opt1) < getChoiceText(opt2)) return -1;

      return 0;
    });
  }, [choices, getParent, getChoiceText]);

  const {
    popupOpen,
    anchorEl,
    setAnchorEl,
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    getClearProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete<Record<string, any>, boolean>({
    onInputChange,
    inputValue,
    getOptionLabel: getChoiceText,
    options: sortedChoices,
    onChange: onChangeWrapper,
    value,
    groupBy: getParent,
    multiple: true,
  });

  useEffect(() => {
    if (inputValue && setFilterValue) {
      setFilterValue(inputValue);
    }
  }, [inputValue, setFilterValue]);

  const { onChange, onFocus, onBlur, onMouseDown, ref, ...restProps } = (
    getInputProps as GeInputProps
  )();

  const mouseDownWrapper = useCallback(
    (evt: unknown) => {
      if (!popupOpen) {
        onMouseDown(evt);
        return;
      }
      onFocus(evt);
    },
    [onFocus, onMouseDown, popupOpen],
  );
  return {
    field: {
      ...field,
      onChange: onChangeWrapper,
      value: inputValue,
    },
    meta,
    error,
    formControlProps: {
      ...getRootProps(),
      ref: setAnchorEl,
    },
    inputProps: {
      ...restProps,
      ref,
      autoComplete: 'off',
      onChange,
      onFocus,
      onMouseDown: mouseDownWrapper,
    },
    popupIndicatorProps: getPopupIndicatorProps() as OnClickProps,
    clearProps: getClearProps() as OnClickProps,
    anchorEl,
    setAnchorEl,
    popupOpen,
    groupedOptions,
    getOptionProps,
  };
};

export default useTreeSelect;
