import { ReactNode } from 'react';
import config from 'config';
import TitleRoute, { TitleRouteProps } from 'routingComponents/TitleRoute';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0Route = ({ children, ...rest }: TitleRouteProps) => {
  return (
    <TitleRoute {...rest}>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          redirect_uri: window.location.href,
          audience: config.auth0.audience,
          scope: config.auth0.scope,
        }}
      >
        {children as ReactNode}
      </Auth0Provider>
    </TitleRoute>
  );
};

export default Auth0Route;
