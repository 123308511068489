import { Dispatch } from 'redux';
import { show } from 'redux-modal';
import { modals } from 'contstants';
import resources from 'store/resources';
import { createRoutine } from './utils';
import crudActions from './crud';

export const applyFiltersConf = createRoutine('APPLY_SEARCH_FILTERS_CONF');

export interface ApplyFiltersConfPayload {
  [filterName: string]: boolean;
}

export const removeDocuments =
  (ids: number[], documentNames: string) => (dispatch: Dispatch) => {
    return dispatch(
      show(modals.CONFIRM_DIALOG, {
        confirmBtnText: 'Delete documents',
        title: `Are you sure you want to delete ${documentNames}?`,
        confirm: () => {
          dispatch(
            crudActions.remove({
              ids,
              meta: {
                resource: resources.SEARCH,
                onSuccess: {
                  runListUpdate: true,
                },
              },
            }),
          );
        },
      }),
    );
  };

const searchActions = {
  applyFiltersConf,
  removeDocuments,
};

export default searchActions;
