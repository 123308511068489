import {
  Empty,
  PageQuery,
  PageResponse,
  request,
  ResponseData,
} from 'services/request';
import { TaskInstance } from 'types';

interface TaskInstanceListQuery extends PageQuery {
  document_id?: number;
  is_obligation?: boolean;
}

const list = ({ ...query }: TaskInstanceListQuery, token: string) =>
  request<PageQuery, PageResponse<TaskInstance> | TaskInstance[], Empty>(
    'tasks/',
    {
      method: 'GET',
      query,
    },
    token,
  ).then((result) => {
    if (query.document_id) {
      const data: TaskInstance[] =
        result.response && result.response.data
          ? (result.response.data as TaskInstance[])
          : [];
      return {
        error: result.error,
        response: {
          ...result.response,
          data: {
            results: data,
            count: data.length,
          },
        },
      } as ResponseData<PageResponse<TaskInstance>>;
    }
    return result;
  });

const create = ({ id, ...payload }: TaskInstance, token: string) => {
  return request<Omit<TaskInstance, 'id'>, TaskInstance, Empty>(
    `tasks/${id}/`,
    {
      method: 'PATCH',
      payload,
    },
    token,
  );
};

const detail = ({ id }: TaskInstance, token: string) =>
  request<Empty, TaskInstance, Empty>(
    `tasks/${id}/`,
    {
      method: 'GET',
    },
    token,
  );

interface TaskInstacesResponse {
  tasks: TaskInstance[];
}

const update = ({ id, ...payload }: Partial<TaskInstance>, token: string) =>
  request<Partial<TaskInstance>, TaskInstacesResponse, Empty>(
    `tasks/reassign/`,
    {
      method: 'POST',
      payload: {
        tasks: [id],
        ...payload,
      },
    },
    token,
  ).then((response) => {
    return {
      ...response,
      response: {
        ...response.response,
        data: {
          ...response.response?.data?.tasks[0],
        },
      },
    } as ResponseData<TaskInstance>;
  });

const taskServices = {
  list,
  create,
  detail,
  update,
};

export default taskServices;
