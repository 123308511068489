import { useContext } from 'react';
import CollapseContext from './CollapseContext';

const useCollapse = () => {
  const { expanded, toggleAll } = useContext(CollapseContext);
  return {
    expanded,
    toggleAll,
  };
};

export default useCollapse;
