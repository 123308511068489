import { Fragment } from 'react';
import { FormControlProps } from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import MuiIconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { BaseInputProps } from 'types';
import { ReactComponent as ArrowIcon } from 'assets/menu.svg';
import useTreeSelect from 'components/inputs/TreeSelect/useTreeSelect';
import ChoicesView, { GetOptionProps, GroupedOption } from './ChoicesView';
import Input from '../Input/Input';

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(1.25, 1),
}));

export interface TreeSelectProps extends BaseInputProps {
  name: string;
  choices?: Array<Record<string, any>>;
  helpText?: string;
  optionText?: string;
  optionValue?: string;
  groupByValue?: string;
  [prop: string]: any;
}

const TreeSelect = ({
  classes,
  choices,
  name,
  groupByValue,
  rules,
  optionValue = 'id',
  optionText = 'name',
  label,
  isLoading,
  setFilterValue,
  disabledStateValidation,
}: TreeSelectProps) => {
  const {
    formControlProps,
    inputProps,
    field,
    meta,
    anchorEl,
    error,
    popupOpen,
    popupIndicatorProps,
    groupedOptions,
    getOptionProps,
  } = useTreeSelect({
    name,
    rules,
    optionValue,
    optionText,
    groupByValue,
    choices: choices || [],
    setFilterValue,
  });
  return (
    <Fragment>
      <Input
        formControlProps={formControlProps as FormControlProps}
        meta={meta}
        error={error}
        field={field}
        onFocus={inputProps.onFocus}
        inputProps={inputProps}
        label={label}
        isLoading={isLoading}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              size="small"
              title={popupOpen ? 'close' : 'open'}
              {...popupIndicatorProps}
            >
              <ArrowIcon />
            </IconButton>
          </InputAdornment>
        }
        disabledStateValidation={disabledStateValidation}
      />
      <ChoicesView
        name={field.name}
        onChange={field.onChange}
        anchor={popupOpen ? anchorEl : null}
        optionValue={optionValue}
        optionText={optionText}
        closeInput={popupIndicatorProps.onClick}
        choices={groupedOptions as GroupedOption[]}
        getOptionProps={getOptionProps as GetOptionProps}
      />
    </Fragment>
  );
};

export default TreeSelect;
