import { REHYDRATE } from 'redux-persist';
import { call, select, take, fork } from 'redux-saga/effects';
import { Notification } from 'types';
import { openfin, pusher } from 'integrations';
import config from 'config';
import { authActions, crudActions } from 'store/actions';
import resources from 'store/resources';
import { authSelectors } from 'store/selectors';

export const NEW_NOTIFICATION_ARRIVED = 'new-notification-arrived';

function* unsubscribeNotification(email: string): IterableIterator<any> {
  yield take(authActions.logout.TRIGGER);
  pusher.unsubscribe(btoa(email));
  yield call(watchNotification, true);
}

export function* watchNotification(skipRehydrate = false) {
  if (!skipRehydrate) {
    yield take(REHYDRATE);
  }

  let email: string = yield select(authSelectors.email);
  if (!email) {
    yield take(authActions.userDetailGet.SUCCESS);
    email = yield select(authSelectors.email);
  }
  const channel = pusher.subscribe(btoa(email));
  yield fork(unsubscribeNotification, email);
  channel.bind(NEW_NOTIFICATION_ARRIVED, (notificationData: Notification) => {
    crudActions.listAppend({
      results: [notificationData],
      meta: {
        resource: resources.NOTIFICATIONS,
      },
    });
    if (config.isOpenFinApp) {
      openfin.createNotification(notificationData);
    }
  });
}
