import { Empty, PageQuery, PageResponse, request } from 'services/request';
import documentReviewed from 'contstants/documentReviewed';

interface SearchPayload extends PageQuery {
  tags?: Array<string>;
  industry?: Array<string>;
  themes?: Array<string>;
  data_source?: Array<string>;
  regulator?: Array<string>;
  topics?: Array<string>;
  doc_type?: Array<string>;
  search_simple_query_string: string;
  commencement_date__lt?: string;
  commencement_date__gt?: string;
  commencement_date__range?: string;
  publication_date__lt?: string;
  publication_date__gt?: string;
  publication_date__range?: string;
  currency?: string;
  penalty__lt?: string;
  penalty__gt?: string;
  penalty__eq?: string;
  reviewed?: string;
}

export interface Metadata {
  id: string;
  guid: string;
  document_level: number | null;
  friendly_name: string | null;
  has_text: boolean | null;
  has_title: boolean | null;
  industry: Array<string>;
  level: number;
  meta: string;
  pad: number;
  title: Array<string>;
  tags: Array<string>;
  label: string | null;
  workflow_theme: Array<string>;
  publication_date: string | null;
  commencement_date: string | null;
  government_department: Array<string>;
}

export interface OperationalData {
  id: string;
  document_name: string;
  doc_type: Array<string>;
  date_added: string;
  published: string;
  commencement_date: string;
  url: string | null;
  data_source: string | null;
  regulator: string | null;
  original_doc_s3: string | null;
  unenriched_doc_s3: string | null;
  issuer: string | null;
  location: string | null;
  tag: Array<string>;
  industry: Array<string>;
  theme: Array<string>;
  nodetext_set: Array<Metadata>;
}

export interface NodeText {
  id: string;
  text: Array<string>;
  text_tagged: string | null;
  text_tagged_high_level: string | null;
}

export interface DataSource {
  id: number;
  codename: string;
  name: string;
  url: string;
}

interface Highlight {
  document_name: Array<string>;
  'node_text.text': Array<string>;
}

export interface ElasticOperationalData {
  id: number;
  data_source: DataSource;
  publication_date: string;
  date_added: string;
  doc_types: Array<Record<string, any>>;
  highlight: Highlight;
  summary_short: Array<string>;
  document_name: string;
  industry: Array<string>;
  location: Array<string> | null;
  node_text: Array<NodeText>;
  principle: Array<string>;
  tags: Array<string>;
  themes: Array<string>;
  topics: Array<string>;
  sanctions: Array<string>;
  url: string;
}

const getQuery = (query: SearchPayload) => {
  const { search_simple_query_string, ...newQuery } = query;

  if (search_simple_query_string) {
    const hasDocumentNameSearch =
      search_simple_query_string.indexOf('document_name:') !== -1;
    if (hasDocumentNameSearch) {
      return {
        ...newQuery,
        search_multi_match: search_simple_query_string,
      };
    }
  }
  return query;
};

const list = ({ sanctions, ...query }: SearchPayload, token: string) => {
  const newQuery = getQuery(query);
  return request<SearchPayload, PageResponse<ElasticOperationalData>, Empty>(
    'wm-objects/operational-data-document/',
    {
      method: 'GET',
      query: {
        ...newQuery,
        reviewed:
          query.reviewed !== documentReviewed.ALL ? query.reviewed : undefined,
        sanctions_firm_id:
          sanctions && sanctions.length ? sanctions : undefined,
        sanctions_individual_id:
          sanctions && sanctions.length ? sanctions : undefined,
      },
    },
    token,
  );
};

interface DetailPayload {
  id: number;
}

const detail = (payload: DetailPayload, token: string) =>
  request<DetailPayload, PageResponse<OperationalData>, Empty>(
    `wm-objects/operational-data/${payload.id}/`,
    {
      method: 'GET',
    },
    token,
  );

const update = (
  { id, ...payload }: Partial<OperationalData>,
  token: string,
  partial?: boolean,
) =>
  request<Partial<OperationalData>, PageResponse<OperationalData>, Empty>(
    `wm-objects/operational-data/${id}/`,
    {
      payload,
      method: partial ? 'PATCH' : 'PUT',
    },
    token,
  );

export interface PayloadOperational {
  ids: number[];
}

const remove = (payload: PayloadOperational, token: string) =>
  request<Empty, Empty, Empty>(
    `wm-objects/delete-operational-data/`,
    {
      payload,
      method: 'POST',
    },
    token,
  );

const searchServices = {
  list,
  detail,
  update,
  remove,
};

export default searchServices;
