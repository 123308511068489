import React from 'react';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';

import Navigation, { NavigationProps } from 'components/Navigation';

export interface DrawerProps extends MuiDrawerProps {
  open: boolean;
  openedWidth: number;
  closedWidth: number;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (propName: string) => {
    return ['open', 'openedWidth', 'closedWidth'].indexOf(propName) === -1;
  },
  overridesResolver: (props, styles) => {
    return Object.keys(styles);
  },
})<DrawerProps>(({ open, openedWidth, closedWidth, theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.text.disabled,
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    whiteSpace: 'nowrap',
    ...(open
      ? {
          width: openedWidth,
        }
      : {
          overflowX: 'hidden',
          width: closedWidth,
        }),
  },
}));

export interface SidebarProps
  extends Omit<NavigationProps, 'extendedMode'>,
    Pick<DrawerProps, 'openedWidth' | 'closedWidth'> {
  isOpen: boolean;
}

const Sidebar = React.memo(
  ({ closedWidth, openedWidth, isOpen, navigation }: SidebarProps) => (
    <Drawer
      open={isOpen}
      closedWidth={closedWidth}
      openedWidth={openedWidth}
      variant="permanent"
    >
      <Toolbar />
      <Navigation navigation={navigation} extendedMode={isOpen} />
    </Drawer>
  ),
);

export default Sidebar;
