import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Paper = styled(MuiPaper, {
  overridesResolver: (props, styles) => Object.keys(styles),
})({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  minWidth: 300,
  flexDirection: 'column',
  width: '100%',
  borderRadius: 0,
  boxShadow: '0px 3px 6px #00000029',
  paddingBottom: 20,
});

export default Paper;
