import Typography from 'components/Typography';
import SubmitButton from 'components/buttons/SubmitButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const NotAvailable = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Modal
      open={true}
      onClose={void 0}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h3" component="p" color="error" align="center">
          Oops. Something went wrong.
        </Typography>
        <Typography variant="h4" component="p" color="info" align="center">
          Please try again in a moment.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <SubmitButton color="secondary" onClick={handleRefresh}>
            Refresh Page
          </SubmitButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotAvailable;
