import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface RouteRedirectProps extends RouteComponentProps {
  urlParam?: string;
  redirectTo?: string;
}

const withRedirectController = <Props extends {}>(
  Component: React.ComponentType<Props>,
) =>
  withRouter((props: RouteRedirectProps & Props) => {
    React.useEffect(() => {
      if (props.urlParam) {
        const urlSearchParams = new URLSearchParams(props.location.search);
        const param = urlSearchParams.get(props.urlParam);
        if (!param && props.redirectTo) {
          props.history.push(props.redirectTo);
        }
      }
    }, [props.urlParam, props.history, props.redirectTo, props.location]);

    return <Component {...props} />;
  });

export default withRedirectController;
