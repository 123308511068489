import { PolicyMapping } from 'types';
import { Empty, request } from 'services/request';
import { getResponseList } from 'services/utils';

interface Payload {
  domain: string;
  policy: string;
}

const list = (payload: Payload, token: string) =>
  request<Empty, PolicyMapping[], Empty>(
    'wm-objects/document-mapping/',
    {
      method: 'POST',
      payload: {
        policy: payload.policy,
        domain: payload.domain,
      },
    },
    token,
  ).then(({ response, error }) =>
    getResponseList<PolicyMapping>({ response, error }),
  );

const policyMappingServices = {
  list,
};

export default policyMappingServices;
