import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Webhook } from 'types';

const list = ({ ...query }: PageQuery, token: string) =>
  request<PageQuery, PageResponse<Webhook>, Empty>(
    'webhooks/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const create = ({ ...payload }: Webhook, token: string) =>
  request<Webhook, Webhook, Empty>(
    'webhooks/',
    {
      payload,
      method: 'POST',
    },
    token,
  );

const detail = ({ id }: Webhook, token: string) =>
  request<Empty, Webhook, Empty>(
    `webhooks/${id}/`,
    {
      method: 'GET',
    },
    token,
  );

const update = ({ id, ...payload }: Partial<Webhook>, token: string) =>
  request<Partial<Webhook>, Webhook, Empty>(
    `webhooks/${id}/`,
    {
      method: 'PATCH',
      payload,
    },
    token,
  );

const remove = ({ id }: Webhook, token: string) =>
  request<Empty, Empty, Empty>(
    `webhooks/${id}/`,
    {
      method: 'DELETE',
    },
    token,
  );

const webhookServices = {
  list,
  create,
  detail,
  update,
  remove,
};

export default webhookServices;
