import { Note } from 'types';
import { Empty, request } from 'services/request';
import { getResponseList } from 'services/utils';

export interface NotesQuery {
  id: string | number;
}

const list = (query: NotesQuery, token: string) =>
  request<NotesQuery, Note[], Empty>(
    `wm-objects/operational-data/${query.id}/notes/`,
    {
      method: 'GET',
    },
    token,
  ).then((result) => getResponseList<Note>(result));

const create = (payload: Note, token: string) => {
  return request<Note, Note, Empty>(
    `wm-objects/operational-data/${payload.operational_data}/notes/`,
    {
      payload,
      method: 'POST',
    },
    token,
  );
};

const update = (payload: Note, token: string) =>
  request<Note, Note, Empty>(
    `wm-objects/operational-data/${payload.operational_data}/notes/${payload.id}`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );

export interface DetailPayload {
  id: string | number;
  operational_data: string | number;
  childNotes?: number[];
}

const remove = (
  { id, operational_data, childNotes }: DetailPayload,
  token: string,
) =>
  request<Record<string, unknown>, Empty, Empty>(
    `wm-objects/operational-data/${operational_data}/notes/${id}`,
    {
      payload: { childNotes },
      method: 'DELETE',
    },
    token,
  );

const updateDocumentNotes = (payload: Record<string, unknown>, token: string) =>
  request<Record<string, unknown>, Empty, Empty>(
    `wm-objects/operational-data/${payload.operational_data_id}/notes/batch-update/`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );

const notesServices = {
  list,
  create,
  update,
  remove,
  updateDocumentNotes,
};

export default notesServices;
