import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from 'components/Typography';
import { Paper } from 'components/ui';

const Wrapper = styled('div')(() => ({
  minWidth: 414,
}));

export interface FormLayoutProps {
  children: React.ReactNode;
  header: string;
}

const FormLayout = ({ header, children }: FormLayoutProps) => (
  <Paper square elevation={3}>
    <Wrapper>
      <Typography
        topSpacing={8}
        bottomSpacing={1}
        component="h1"
        variant="h1"
        align="center"
      >
        {header}
      </Typography>
      {children}
    </Wrapper>
  </Paper>
);

export default FormLayout;
