import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Industry } from 'types';

export interface IndustryPayload extends PageQuery {
  industry?: string;
  id?: number;
}

const list = (query: IndustryPayload, token: string) =>
  request<Empty, PageResponse<Industry>, Empty>(
    'wm-objects/industry/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const industryServices = {
  list,
};

export default industryServices;
