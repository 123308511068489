import { createRoutine } from './utils';

export const setItems = createRoutine('SET_BASKET_ITEMS');
export const removeItems = createRoutine('REMOVE_BASKET_ITEMS');
export const clear = createRoutine('CLEAR_BASKET');

const basketActions = {
  setItems,
  removeItems,
  clear,
};

export default basketActions;
