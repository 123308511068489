import { styled } from '@mui/material/styles';

const SaturationPointerCircle = styled('div')(({ theme }) => ({
  width: 12,
  height: 12,
  borderRadius: 6,
  boxShadow: 'inset 0 0 0 1px #fff',
  transform: 'translate(-6px, -6px)',
}));

export default SaturationPointerCircle;
