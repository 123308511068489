import React from 'react';
import { FormContext } from 'contexts/Form/FormContext';
import { FieldPath, WatchObserver } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

type Watch<TFieldValues = FieldValues> = (
  names?:
    | FieldPath<TFieldValues>
    | ReadonlyArray<FieldPath<TFieldValues>>
    | WatchObserver<TFieldValues>,
  defaultValue?: unknown,
) => any;

const useFieldWatch = <TFieldValues = FieldValues>(
  names?:
    | FieldPath<TFieldValues>
    | ReadonlyArray<FieldPath<TFieldValues>>
    | WatchObserver<TFieldValues>,
  defaultValue?: unknown,
) => {
  const { watch } = React.useContext(FormContext);
  return (watch as unknown as Watch<TFieldValues>)(names, defaultValue);
};

export default useFieldWatch;
