import Immutable from 'immutable';
import { accessibilityActions } from 'store/actions';
import { createReducer } from './utils';

export interface AccessibilityState {
  fontSize: string;
  colorTheme: string;
}
export type AccessibilityRecord = Immutable.Record<AccessibilityState>;
export const AccessibilityInitialState = Immutable.Record<AccessibilityState>({
  fontSize: 'default',
  colorTheme: 'default',
});

const initialState = AccessibilityInitialState();

const update = (state: AccessibilityRecord, payload: AccessibilityState) => {
  return state.merge(payload);
};

export default createReducer<AccessibilityState, AccessibilityRecord>(
  {
    [accessibilityActions.accessibilitySet.TRIGGER]: update,
  },
  initialState,
);
