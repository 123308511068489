import MuiInput, { InputProps as MuiInputProps } from '@mui/material/Input';
import { styled, Theme } from '@mui/material/styles';

export interface InputProps extends MuiInputProps {
  errorState?: boolean;
  success?: boolean;
  clickedInput?: boolean;
}

const Input = styled(MuiInput, {
  shouldForwardProp: (propName: string) => {
    return (
      ['errorState', 'success', 'clickedInput', 'valid'].indexOf(propName) ===
      -1
    );
  },
})<InputProps>(({ theme, ...ownerState }: InputProps & { theme: Theme }) => ({
  ...(ownerState.errorState && {
    '&:before': {
      borderBottomColor: theme.palette.error.main,
    },
  }),
  ...(ownerState.success && {
    '&:before': {
      borderBottomColor: theme.palette.success.main,
    },
  }),
  ...(ownerState.clickedInput && {
    cursor: 'pointer',
  }),
}));

export default Input;
