import { createContext } from 'react';

export interface DebugControllerContextValue {
  isDebugMode: boolean;
  changeDebugState: (value: boolean) => void;
}

const DebugControllerContext = createContext<DebugControllerContextValue>({
  isDebugMode: false,
  changeDebugState: (value: boolean) => null,
});

export default DebugControllerContext;
