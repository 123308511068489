import { ElementType } from 'react';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';
import { StyledComponent } from '@mui/styles';
import { Color } from 'types';
import Tooltip from 'components/Tooltip';

export type IconBtnColor = Color | 'gray';

export interface IconButtonProps extends Omit<MuiIconButtonProps, 'color'> {
  color?: IconBtnColor;
  disabled?: boolean;
  href?: string;
  type?: 'button' | 'reset' | 'submit';
  opacity?: boolean;
  component?: ElementType;
  label?: string;
}

const IconButton = styled(MuiIconButton, {
  shouldForwardProp: (propName: string) =>
    [
      'color',
      'opacity',
      'variant',
      'size',
      'fullWidth',
      'textTransform',
      'classes',
      'disableFocusRipple',
      'disableElevation',
    ].indexOf(propName) === -1,
})<IconButtonProps>(
  ({
    theme,
    color = 'primary',
    opacity = false,
  }: IconButtonProps & { theme: Theme }) => ({
    height: '32px',
    width: '32px',
    color: theme.palette.common.white,
    margin: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.text.disabled,
    padding: theme.spacing(1),
    ...(color === 'primary' && {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(color === 'secondary' && {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main,
      },
    }),
    ...(color === 'error' && {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.error.main,
      },
    }),
    ...(color === 'warning' && {
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.warning.main,
      },
    }),
    ...(color === 'info' && {
      backgroundColor: theme.palette.info.main,
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.info.main,
      },
    }),
    ...(color === 'success' && {
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.success.main,
      },
    }),
    ...(color === 'gray' && {
      backgroundColor: theme.palette.text.disabled,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
      '&:active': {
        backgroundColor: theme.palette.text.disabled,
      },
    }),
  }),
) as StyledComponent<IconButtonProps>;

const IconButtonBase = ({
  children,
  label = '',
  ...other
}: IconButtonProps) => {
  return (
    <Tooltip title={label}>
      <IconButton {...other}>{children}</IconButton>
    </Tooltip>
  );
};

export default IconButtonBase;
