import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { License } from 'types';

export interface LicensePayload extends PageQuery {
  license_tier?: string;
  id?: number;
}

const list = (query: LicensePayload, token: string) =>
  request<Empty, PageResponse<License>, Empty>(
    `license/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const licensesServices = {
  list,
};

export default licensesServices;
