import moment from 'moment';

import config from 'config';

const formatTodayDate = (timestamp: string) =>
  moment(timestamp).isSame(moment(new Date()), 'd')
    ? 'Today'
    : moment(timestamp).format(config.notificationTimeFormat);

export default formatTodayDate;
