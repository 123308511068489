import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';
import { ModalState, reducer as modal } from 'redux-modal';
import { History } from 'history';
import { createResourceReducer } from 'store/reducers/utils';

import resources from 'store/resources';
import {
  BaseItem,
  BreachObserved,
  BreachPrimary,
  BreachSecondary,
  BreachSupporting,
  BurdenClass,
  Country,
  DataSource,
  DocumentType,
  GeographicalExtent,
  GovernmentDepartment,
  License,
  Organisation,
  RecipientType,
  Regulator,
  ResourceRecord,
  Sanction,
  Tag,
  Theme,
  Topic,
  Workflow,
  Simf,
  Smf,
  Cf,
  SourceGovernmentDepartment,
  FileUpload,
  Webhook,
  RelatedDocuments,
  OperationalData,
  PolicyMapping,
  DataSourceParser,
  DocumentComment,
  NodesEmail,
  DocumentCounter,
  SavedSearch,
  Group,
  RegulatoryCalendar,
  PivotTableTemplate,
  TaskInstance,
  User,
  OrganizationLog,
  S3Bucket,
  BackendStatus,
} from 'types';
import { AuthRecord, default as authReducer } from './auth';
import { default as folderReducer, FolderState } from './folder';
import {
  default as notificationReducer,
  NotificationsState,
} from './notifications';
import {
  default as accessibilityReducer,
  AccessibilityRecord,
} from './accessibility';
import { default as basketReducer, BasketRecord } from './basket';
import { default as noteReducer, NoteState } from './notes';

export type { AuthState } from './auth';
export type { AccessibilityState, AccessibilityRecord } from './accessibility';
export type { NotificationsState } from './notifications';

export interface ReducerState extends Record<string, any> {
  router: RouterState;
  auth: AuthRecord;
  notifications: NotificationsState;
  users: ResourceRecord<User>;
  modal: ModalState;
  search: ResourceRecord<OperationalData>;
  groups: ResourceRecord<Group>;
  basket: BasketRecord;
  tasks: ResourceRecord<TaskInstance>;
  accessibility: AccessibilityRecord;
  regulatoryCalendar: ResourceRecord<RegulatoryCalendar>;
  // auto registered resources
  organisations: ResourceRecord<Organisation>;
  dataSources: ResourceRecord<DataSource>;
  documentTypes: ResourceRecord<DocumentType>;
  burdenClasses: ResourceRecord<BurdenClass>;
  licenses: ResourceRecord<License>;
  tags: ResourceRecord<Tag>;
  themes: ResourceRecord<Theme>;
  topics: ResourceRecord<Topic>;
  countries: ResourceRecord<Country>;
  regulators: ResourceRecord<Regulator>;
  recipientTypes: ResourceRecord<RecipientType>;
  governmentDepartments: ResourceRecord<GovernmentDepartment>;
  sourceGovernmentDepartments: ResourceRecord<SourceGovernmentDepartment>;
  geographicalExtents: ResourceRecord<GeographicalExtent>;
  savedSearches: ResourceRecord<SavedSearch>;
  folders: FolderState;
  workflows: ResourceRecord<Workflow>;
  webhooks: ResourceRecord<Webhook>;
  sanctions: ResourceRecord<Sanction>;
  simf: ResourceRecord<Simf>;
  smf: ResourceRecord<Smf>;
  cf: ResourceRecord<Cf>;
  breachesPrimary: ResourceRecord<BreachPrimary>;
  breachesSecondary: ResourceRecord<BreachSecondary>;
  breachesSupporting: ResourceRecord<BreachSupporting>;
  breachesObserved: ResourceRecord<BreachObserved>;
  upload: ResourceRecord<FileUpload>;
  relatedDocuments: ResourceRecord<RelatedDocuments>;
  relatedSearchDetail: ResourceRecord<OperationalData>;
  policyMapping: ResourceRecord<PolicyMapping>;
  themesAndTags: ResourceRecord<DocumentCounter>;
  parsers: ResourceRecord<DataSourceParser>;
  pivotTemplates: ResourceRecord<PivotTableTemplate>;
  documentComments: ResourceRecord<DocumentComment>;
  nodesEmail: ResourceRecord<NodesEmail>;
  notes: NoteState;
  organizationLogs: ResourceRecord<OrganizationLog>;
  documentComparison: ResourceRecord<OperationalData>;
  s3Bucket: ResourceRecord<S3Bucket>;
  backendStatus: ResourceRecord<BackendStatus>;
  folderDocuments: ResourceRecord<OperationalData>;
}

const resourcesToRegister = [
  resources.ORGANISATIONS,
  resources.DATA_SOURCES,
  resources.DOCUMENT_TYPE,
  resources.BURDEN_CLASSES,
  resources.LICENSES,
  resources.TAGS,
  resources.THEMES,
  resources.TOPICS,
  resources.COUNTRIES,
  resources.REGULATORS,
  resources.RECIPIENT_TYPES,
  resources.SOURCE_GOVERNMENT_DEPARTMENTS,
  resources.GOVERNMENT_DEPARTMENTS,
  resources.GEOGRAPHICAL_EXTENTS,
  resources.WORKFLOW,
  resources.WEBHOOKS,
  resources.BREACHES_PRIMARY,
  resources.BREACHES_SECONDARY,
  resources.BREACHES_SUPPORTING,
  resources.BREACHES_OBSERVED,
  resources.SANCTIONS,
  resources.RULES_BREACHES,
  resources.SIMF,
  resources.SMF,
  resources.CF,
  resources.INDUSTRY,
  resources.UPLOAD,
  resources.NODES,
  resources.RELATED_DOCUMENTS,
  resources.RELATED_SEARCH_DETAIL,
  resources.POLICY_MAPPING,
  resources.DOCUMENT_BUNDLE,
  resources.DOCUMENT_COMMENTS,
  resources.NODES_EMAIL,
  resources.THEMES_AND_TAGS,
  resources.SAVED_SEARCHES,
  resources.GROUPS,
  resources.REGULATORY_CALENDAR,
  resources.PIVOT_TEMPLATES,
  resources.SEARCH,
  resources.TASKS,
  resources.USERS,
  resources.ORGANIZATION_LOGS,
  resources.DOCUMENT_COMPARISON,
  resources.S3BUCKET,
  resources.BACKEND_STATUS,
  resources.FOLDER_DOCUMENTS,
];

const registerResourceReducers = (resources: string[]) =>
  resources.reduce(
    (
      reducers: Record<string, Reducer<ResourceRecord<BaseItem>>>,
      resourceName: string,
    ) => ({
      ...reducers,
      [resourceName]: createResourceReducer({
        resource: resourceName,
      }) as Reducer<ResourceRecord<BaseItem>>,
    }),
    {} as Record<string, Reducer<ResourceRecord<BaseItem>>>,
  );

const reducer = (history: History) =>
  combineReducers({
    // ToDo: move modal to context
    modal,
    router: connectRouter(history),
    // ToDo: move auth to context
    auth: authReducer,
    // ToDo: move notifications to context
    [resources.NOTIFICATIONS]: notificationReducer,
    // ToDo: move accessibility to context
    [resources.ACCESSIBILITY]: accessibilityReducer,
    // extra resources
    [resources.BASKET]: basketReducer,
    [resources.FOLDERS]: folderReducer,
    [resources.NOTES]: noteReducer,
    // resources
    ...registerResourceReducers(resourcesToRegister),
  });

export default reducer;
