import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { BreachSecondary } from 'types';

export interface BreachSecondaryPayload extends PageQuery {
  breach_secondary?: string;
  id: number;
}

const list = (query: BreachSecondaryPayload, token: string) =>
  request<Empty, PageResponse<BreachSecondary>, Empty>(
    `wm-objects/breach-secondary/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const breachesSecondaryServices = {
  list,
};

export default breachesSecondaryServices;
