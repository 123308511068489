import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { BaseInputProps } from 'types';

import Input, { FormInputProps } from '../Input';

export const NumberFormatInput = React.forwardRef(
  (
    { onChange, name, ...other }: InputBaseComponentProps & NumberFormatProps,
    ref,
  ) => (
    <NumberFormat
      getInputRef={ref}
      onValueChange={(values) => {
        if (onChange) {
          onChange({
            target: {
              name: name,
              value: values.value,
            },
          } as any);
        }
      }}
      {...other}
    />
  ),
);

interface NumberInputProps extends BaseInputProps, NumberFormatProps {
  name: string;
  postfix?: string;
  [prop: string]: any;
}

const NumberInput = (props: NumberInputProps) => {
  const {
    thousandSeparator = false,
    decimalSeparator,
    thousandsGroupStyle,
    decimalScale = 2,
    fixedDecimalScale = true,
    allowNegative,
    allowEmptyFormatting,
    allowLeadingZeros,
    isNumericString = true,
    format,
    removeFormatting,
    mask,
    customInput,
    isAllowed,
    renderText,
    allowedDecimalSeparators,
    postfix,
    ...restProps
  } = props;
  return (
    <Input
      inputComponent={
        NumberFormatInput as React.ElementType<InputBaseComponentProps>
      }
      endAdornment={postfix ? <span>{postfix}</span> : null}
      inputProps={{
        thousandSeparator,
        decimalSeparator,
        thousandsGroupStyle,
        decimalScale,
        fixedDecimalScale,
        allowNegative,
        allowEmptyFormatting,
        allowLeadingZeros,
        isNumericString,
        format,
        removeFormatting,
        mask,
        customInput,
        isAllowed,
        renderText,
        allowedDecimalSeparators,
      }}
      {...(restProps as FormInputProps)}
    />
  );
};

export default NumberInput;
