import { attachToFolderAction } from 'store/actions/folders';

import Immutable from 'immutable';
import { ResourceRecord, ResourceReducer, Folder, Errors } from 'types';
import resources from 'store/resources';
import { createResourceReducer } from './utils';

export type FolderState = ResourceRecord<Folder>;

export const InitialFolderState = Immutable.Record<ResourceReducer<Folder>>({
  filters: {},
  sort: [],
  isLoading: false,
  isManyLoading: false,
  count: 0,
  pagination: {
    perPage: 25,
    page: 1,
  },
  currentItem: null,
  autocomplete: [],
  results: [],
  errors: {},
});

const attachToFolderFailure = (state: FolderState, errors: Errors) =>
  state.merge({
    errors,
  });

const attachToFolderTrigger = (state: FolderState) =>
  state.merge({
    errors: {},
  });

export default createResourceReducer({
  resource: resources.FOLDERS,
  initialState: new InitialFolderState(),
  handlers: {
    [attachToFolderAction.TRIGGER]: attachToFolderTrigger,
    [attachToFolderAction.FAILURE]: attachToFolderFailure,
  },
});
