import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Group, User } from 'types';

interface MemberProps {
  organization_id: string;
  id?: string;
}

export interface MemberPayload extends PageQuery, MemberProps {
  users: User[];
}

const list = ({ organization_id, ...query }: MemberProps, token: string) =>
  request<Empty, PageResponse<Group>, Empty>(
    organization_id === 'custom'
      ? 'organization-users/'
      : `organization/${organization_id}/users/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const create = ({ organization_id, users }: MemberPayload, token: string) => {
  return request<User[], PageResponse<User>, Empty>(
    organization_id === 'custom'
      ? 'organization-users/'
      : `organization/${organization_id}/users/`,
    {
      method: 'POST',
      payload: users.map(({ organization_id: orgId, ...user }) => {
        if (orgId && orgId.toString() === 'custom') {
          return user;
        }
        return {
          ...user,
          organization_id: orgId,
        };
      }),
    },
    token,
  );
};

const detail = (payload: MemberProps, token: string) =>
  request<Empty, PageResponse<User>, Empty>(
    payload.organization_id === 'custom'
      ? `organization-user/${payload.id}/`
      : `organization/${payload.organization_id}/user/${payload.id}/`,
    {
      method: 'GET',
    },
    token,
  );

const update = (
  { organization_id, ...payload }: MemberPayload,
  token: string,
) =>
  request<Partial<MemberPayload>, User, Empty>(
    organization_id === 'custom'
      ? `organization-user/${payload.id}/`
      : `organization/${organization_id}/user/${payload.id}/`,
    {
      method: 'PATCH',
      payload:
        organization_id === 'custom'
          ? payload
          : { ...payload, organization_id },
    },
    token,
  );

const remove = (
  { organization_id, ...payload }: MemberProps,
  token: string,
) => {
  return request<Empty, Empty, Empty>(
    organization_id === 'custom'
      ? `organization-user/${payload.id}/`
      : `organization/${organization_id}/user/${payload.id}/`,
    {
      method: 'DELETE',
    },
    token,
  );
};

const usersService = {
  list,
  create,
  detail,
  update,
  remove,
};

export default usersService;
