const getObjectWithValues = (
  values: Record<string, any>,
): Record<string, any> => {
  if (!values) return {};
  return Object.keys(values).reduce((acc: Record<string, any>, key: string) => {
    switch (true) {
      case values[key] instanceof Date:
        return {
          ...acc,
          [key]: values[key],
        };
      case Array.isArray(values[key]) && values[key].length > 0:
        acc[key] = values[key].map((value: any) => {
          if (typeof value === 'object') {
            return getObjectWithValues(value);
          }
          return value;
        });
        break;
      case !Array.isArray(values[key]) &&
        typeof values[key] === 'object' &&
        values[key] !== null:
        acc[key] = getObjectWithValues(values[key]);
        break;
      case values[key] !== undefined && values[key] !== null:
        acc[key] = values[key];
        break;
    }
    return acc;
  }, {} as Record<string, any>);
};

export default getObjectWithValues;
