import React from 'react';
import Button from '../base';

interface SubmitButtonProps {
  children: React.ReactNode;

  [prop: string]: any;
}

export const SubmitButton = ({ children, ...props }: SubmitButtonProps) => (
  <Button
    sx={{
      marginTop: 4,
      width: 280,
    }}
    variant="contained"
    color="success"
    {...props}
  >
    {children}
  </Button>
);

export default SubmitButton;
