import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { FileUpload } from 'types';

const create = (payload: FileUpload, token: string) =>
  request<FileUpload, FileUpload, Empty>(
    `upload/files/`,
    {
      method: 'POST',
      payload,
    },
    token,
    false,
  );

const list = (query: PageQuery, token: string) =>
  request<PageQuery, PageResponse<FileUpload>, Empty>(
    'upload/files/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const uploadServices = {
  create,
  list,
};

export default uploadServices;
