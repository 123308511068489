import { MouseEvent, ReactElement } from 'react';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';

export const LteMode = 'lte';
export const GteMode = 'gte';
export type Mode = 'lte' | 'gte';

export type DateInputOnClick = (
  evt: MouseEvent<HTMLInputElement>,
  mode: Mode,
) => any;
export type RenderDay = (
  day: unknown,
  selectedDates: unknown[],
  pickersDayProps: PickersDayProps<unknown>,
) => ReactElement;
