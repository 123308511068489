import { Empty, PageResponse, request, ResponseData } from 'services/request';
import { DocumentComment } from 'types';

export interface DocumentCommentQuery {
  pk: string | number;
}

const list = (query: DocumentCommentQuery, token: string) =>
  request<DocumentCommentQuery, DocumentComment[], Empty>(
    `wm-objects/operational-data/${query.pk}/comments/`,
    {
      method: 'GET',
    },
    token,
  ).then((result) => {
    const results = result.response?.data as DocumentComment[];
    return {
      error: result.error,
      response: {
        ...result.response,
        data: {
          results,
          count: results.length,
        },
      },
    } as ResponseData<PageResponse<DocumentComment>>;
  });

const create = (payload: DocumentComment, token: string) => {
  return request<DocumentComment, DocumentComment, Empty>(
    `wm-objects/operational-data/${payload.operation_data}/comments/`,
    {
      payload,
      method: 'POST',
    },
    token,
  );
};

const update = (payload: DocumentComment, token: string) =>
  request<DocumentComment, DocumentComment, Empty>(
    `wm-objects/operational-data/comments/${payload.id}`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );

export interface DetailPayload {
  id: string | number;
}

const remove = ({ id }: DetailPayload, token: string) =>
  request<DocumentComment, Empty, Empty>(
    `wm-objects/operational-data/comments/${id}`,
    {
      method: 'DELETE',
    },
    token,
  );

const documentCommentsServices = {
  list,
  create,
  update,
  remove,
};

export default documentCommentsServices;
