import { RouteComponentProps } from 'react-router-dom';

import { AppliedFilters } from 'types';
import useListParams, { Query } from './useListParams';

interface UseListControllerProps extends RouteComponentProps {
  isStatic?: boolean;
  baseFilters?: AppliedFilters;
  isLoading: boolean;
  isDoFetch: (values: Query) => boolean;
  count: number;
  perPage: number;
  fetch: (values: any) => any;
}

const useListController = ({
  isStatic,
  isLoading,
  isDoFetch,
  baseFilters,
  fetch,
  count,
  location,
  history,
  perPage,
  ...routeProps
}: UseListControllerProps) => {
  const { updateSort, updatePaginate, updateFilters, query, loading } =
    useListParams({
      isStatic,
      baseFilters,
      fetch,
      isDoFetch,
      location,
      history,
      perPage,
      isLoading,
      count,
      ...routeProps,
    });

  return {
    updateSort,
    updatePaginate,
    updateFilters,
    filters: query.filters,
    sort: query.sort,
    pagination: query.pagination,
    loading,
    count,
  };
};

export default useListController;
