import React from 'react';
import { useRunOnce } from 'hooks';

interface Props {
  isLoading: boolean;
  component: React.ElementType;
  create: (values: any) => any;
  errors: Record<string, string[]>;
  resetError: () => void;
}

export interface ComponentProps {
  isLoading: boolean;
}

const CreateController = ({
  isLoading,
  create,
  resetError,
  component: Component,
  ...restProps
}: Props) => {
  useRunOnce(resetError);

  return <Component {...restProps} isLoading={isLoading} onCreate={create} />;
};

export default CreateController;
