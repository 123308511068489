import Immutable from 'immutable';
import { basketActions } from 'store/actions';
import { createReducer } from './utils';
import { OperationalData } from 'types';

export interface BasketState {
  documents: OperationalData[];
}

export type BasketRecord = Immutable.Record<BasketState>;
export const BasketInitialState = Immutable.Record<BasketState>({
  documents: [],
});

const initialState = BasketInitialState();

const setItems = (
  state: BasketRecord,
  payload: { documents: OperationalData[] },
) => {
  const docs = state.get('documents');
  const filteredDocs = payload.documents.filter(
    (docToFilter: OperationalData) =>
      !docs.some((doc: OperationalData) => doc.id === docToFilter.id),
  );
  return state.mergeDeep({ documents: filteredDocs });
};

const removeItems = (
  state: BasketRecord,
  payload: { documents: OperationalData[] },
) => {
  const docs = state.get('documents');
  const filteredDocs = docs.filter(
    (docToFilter: OperationalData) =>
      !payload.documents.some(
        (doc: OperationalData) => doc.id === docToFilter.id,
      ),
  );
  return state.merge({ documents: filteredDocs });
};

const clear = (state: BasketRecord) => {
  return state.merge({ documents: [] });
};

export default createReducer<BasketState, BasketRecord>(
  {
    [basketActions.setItems.TRIGGER]: setItems,
    [basketActions.removeItems.TRIGGER]: removeItems,
    [basketActions.clear.TRIGGER]: clear,
  },
  initialState,
);
