import React from 'react';
import { useInput } from 'contexts';
import SelectInput, { SelectInputProps } from './SelectInput';

const ControlledSelectInput = ({
  name,
  rules,
  many,
  defaultValue = many ? [] : '',
  isPrefetchLoading,
  ...props
}: Omit<SelectInputProps, 'field' | 'meta' | 'error' | 'rules'>) => {
  const { field, meta, error } = useInput({
    name,
    defaultValue,
    rules,
  });
  return (
    <SelectInput
      name={name}
      field={field}
      meta={meta}
      error={error}
      many={many}
      isLoading={isPrefetchLoading}
      {...props}
    />
  );
};

export default ControlledSelectInput;
