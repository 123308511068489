import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import omit from 'lodash/omit';
import qs from 'query-string';
import config from 'config';
import HttpError from 'services/HttpError';

type Query = Record<string, any>;
type Payload = Record<string, any>;
type Response = Record<string, any>;

export interface PageQuery extends Record<string, any> {
  limit?: number;
  offset?: number;
}

export type Empty = {};

export interface PageResponse<T> {
  count: number;
  next: string;
  previous: string;
  results: Array<T>;
}

interface RequestData<T extends Payload, Q extends Query> extends RequestInit {
  query?: Q;
  payload?: T;
}

interface RequestHeaders<T extends Payload, Q extends Query> {
  data: RequestData<T, Q>;
  token?: string;
  jsonRequest: boolean;
}

interface ResponseBody<T extends Response> {
  data?: T;
  status: number;
  statusText: string;
  headers: Headers;
  code?: number;
}

export interface ResponseData<T> {
  response?: ResponseBody<T>;
  error?: any;
}

const prepareRequestBody = <T extends Payload>(
  payload: T,
  jsonRequest: boolean = false,
): string | FormData => {
  if (jsonRequest) {
    return JSON.stringify(payload);
  }

  const formData = new FormData();
  Object.keys(payload).forEach((property) => {
    if (Array.isArray(payload[property])) {
      let formKey = property;
      payload[property].forEach((element: unknown, index: number) => {
        const tempFormKey = `${formKey}[${index}]`;
        formData.append(tempFormKey, (element as string).toString());
      });
      return;
    }
    formData.append(property, payload[property]);
  });
  return formData;
};

const prepareRequestHeaders = <T extends Payload, Q extends Query>({
  data,
  token,
  jsonRequest = true,
}: RequestHeaders<T, Q>): Record<string, string> => {
  let headers: Record<string, string> = get(data, 'headers', {}) as Record<
    string,
    string
  >;
  if (jsonRequest) {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'X-Request-ID': uuid(),
      ...headers,
    };
  }

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      ...headers,
    };
  }

  return headers;
};

const prepareUrl = (targetUrl: string, query: Query = {}): string => {
  if (!query || isEmpty(query)) {
    return targetUrl;
  }
  return `${targetUrl}?${qs.stringify(query)}`;
};

const getResponse = async <T extends Response>(
  response: Response,
  jsonRequest: boolean = true,
): Promise<ResponseBody<T>> => {
  let data;
  if (response.status !== 204 && response.status < 500) {
    data = jsonRequest ? await response.json() : await response.text();
  }
  return {
    data,
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
  };
};

export const request = async <
  T extends Payload,
  R extends Response,
  Q extends Query,
>(
  url: string,
  data: RequestData<T, Q>,
  token?: string,
  jsonRequest: boolean = true,
): Promise<ResponseData<R>> => {
  if (data.payload) {
    data.body = prepareRequestBody<T>(data.payload, jsonRequest);
  }
  data.mode = 'cors';
  data.credentials = 'same-origin';
  data.headers = prepareRequestHeaders<T, Q>({
    data,
    token,
    jsonRequest,
  });

  const baseUrl = config.baseUrl;
  const requestUrl = prepareUrl(baseUrl + url, data.query);
  let response;
  try {
    response = await fetch(requestUrl, data);
  } catch (e) {
    throw new HttpError(
      'Resource Data Unavailable',
      503,
      'Server did not respons to reeqest',
    );
  }
  console.log('Request URL', requestUrl);
  console.log('Request Error', response.status);
  if (response.status === 404) {
    throw new HttpError(
      'Resource Data Unavailable',
      503,
      'Server did not respons to reeqest at ' + response.url,
    );
  }
  const responseBody = await getResponse<R>(response, jsonRequest);
  if (responseBody.status < 200 || responseBody.status >= 300) {
    throw new HttpError(
      (responseBody.data && responseBody.data.detail) ||
        responseBody.statusText,
      responseBody.status,
      responseBody.data,
    );
  }
  return response.ok
    ? { response: responseBody }
    : { error: responseBody.data };
};

export const chunkRequest = async <R extends Response, Q extends Query>(
  url: string,
  splitBy: 'id',
  queryProps: Q,
  token?: string,
): Promise<ResponseData<PageResponse<R>>> => {
  const query = omit(queryProps, [splitBy]);
  const chunkValue = queryProps[splitBy];
  return Array.isArray(chunkValue) && chunkValue.length > 25
    ? (Promise.all(
        chunk(chunkValue, 25).map((ids) =>
          request<Empty, PageResponse<R>, Q>(
            url,
            {
              method: 'GET',
              query: { ...query, [splitBy]: ids } as unknown as Q,
            },
            token,
          ),
        ),
      ).then((results) => {
        const errorResponse = results.find((result) => result.error);
        if (errorResponse) {
          return errorResponse;
        }
        return results.slice(1).reduce((joinedResults, result) => {
          return {
            response: {
              data: {
                count:
                  joinedResults.response!.data!.count +
                  result.response!.data!.count,
                next: result.response!.data!.next,
                previous: result.response!.data!.previous,
                results: [
                  ...joinedResults.response!.data!.results,
                  ...result.response!.data!.results,
                ],
              },
              status: result.response!.status,
              statusText: result.response!.statusText,
              headers: result.response!.headers,
              code: result.response!.code,
            },
            error: null,
          } as ResponseData<PageResponse<R>>;
        }, results[0]);
      }) as Promise<ResponseData<PageResponse<R>>>)
    : request<Empty, PageResponse<R>, Q>(
        url,
        {
          method: 'GET',
          query: { ...query, [splitBy]: chunkValue } as unknown as Q,
        },
        token,
      );
};
