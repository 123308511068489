import { Theme, alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Notification, OnSuccessCallback } from 'types';
import { formatTodayDate } from 'utils';
import { useDrawer } from 'contexts';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { ReactComponent as StatusColorIcon } from 'assets/statusColor.svg';
import { ReactComponent as DoubleIndicatorIcon } from 'assets/doubleIndicator.svg';

export interface NotificationItemProps {
  item: Notification;
  onClick: (item: Notification, onSuccessCallback: OnSuccessCallback) => void;
  loading?: boolean;
}

interface NotificationItemBodyProps {
  unread: boolean;
  disabled?: boolean;
}

const IconSuccess = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const UnreadIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const NotificationItemBody = styled(Box, {
  shouldForwardProp: (propName: string) => propName !== 'unread',
})<NotificationItemBodyProps>(
  ({ theme, ...ownerState }: NotificationItemBodyProps & { theme: Theme }) => ({
    maxHeight: 220,
    overflow: 'auto',
    padding: theme.spacing(4, 2, 1),
    marginBottom: theme.spacing(0.5),
    background: alpha(theme.palette.text.disabled, 0.19),
    ...(ownerState.unread && {
      cursor: 'pointer',
    }),
    ...(ownerState.disabled && {
      pointerEvents: 'none',
      cursor: 'default',
    }),
  }),
);

const NotificationItem = ({
  item,
  onClick,
  loading = false,
}: NotificationItemProps) => {
  const { close } = useDrawer();
  const { verb, description, timestamp, unread } = item;

  const clickHandler = () => {
    onClick(item, close);
  };

  return (
    <NotificationItemBody
      disabled={loading}
      unread={unread}
      onClick={clickHandler}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography bottomSpacing={2}>
            <b>{verb}</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{formatTodayDate(timestamp)}</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={10}>
          <Typography>{description}</Typography>
        </Grid>
        <Grid item>
          {!unread ? (
            <IconSuccess>
              <DoubleIndicatorIcon />
            </IconSuccess>
          ) : (
            <UnreadIcon>
              <StatusColorIcon />
            </UnreadIcon>
          )}
        </Grid>
      </Grid>
    </NotificationItemBody>
  );
};

export default NotificationItem;
