import Rollbar from 'rollbar';
import {
  getRollbarFromContext,
  Context,
  ContextInterface,
} from '@rollbar/react';
import { Context as ReactContext, useContext } from 'react';

const useLogger = () => {
  const context = useContext(Context);

  let rollbar: Rollbar | undefined;

  if (context) {
    rollbar = getRollbarFromContext(
      context as unknown as ReactContext<ContextInterface>,
    );
  }

  const error = (error: Error) => {
    if (rollbar) {
      rollbar.error(error);
    }

    console.error(error);
  };

  return rollbar ? { ...rollbar, error } : { error };
};

export default useLogger;
