import React from 'react';
import { connectModal } from 'redux-modal';

import { modals } from 'contstants';
import deactivateImage from 'assets/throw_away.svg';
import Grid from 'components/Grid';
import { ConfirmDialog, ConfirmDialogProps } from '../ConfirmDialog';

type DeactivateDialogProps = Omit<
  ConfirmDialogProps,
  'cancelBtnText' | 'confirmBtnText'
>;

const DeactivateDialog = ({
  show,
  handleHide,
  handleDestroy,
  confirm,
  content,
  title = 'Are you sure that you want to deactivate it?',
}: DeactivateDialogProps) => (
  <ConfirmDialog
    show={show}
    handleHide={handleHide}
    handleDestroy={handleDestroy}
    confirm={confirm}
    title={title}
    content={
      <Grid container direction="column" justifyContent="center">
        <Grid item>{content}</Grid>
        <Grid item>
          <img src={deactivateImage} alt="deactivate" />
        </Grid>
      </Grid>
    }
    confirmBtnText="Deactivate"
  />
);

export default connectModal({
  name: modals.DEACTIVATE_DIALOG,
  destroyOnHide: true,
})(DeactivateDialog);
