import React from 'react';
import { styled } from '@mui/material/styles';

import { Navigation as INavigation } from 'types';
import Grid, { GridProps } from 'components/Grid';
import NavList from 'components/NavList';
import BackToMainMenu from './BackToMainMenu';
import useNavigation from './useNavigation';

export interface NavigationGridProps extends GridProps {
  nestedNav: boolean;
}

const NavigationGrid = styled(Grid, {
  shouldForwardProp: (propName: string) => {
    return 'nestedNav' !== propName;
  },
})<NavigationGridProps>(({ theme, nestedNav }) => ({
  ...(nestedNav && {
    backgroundColor: theme.palette.grey.A100,
    color: theme.palette.info.main,
  }),
}));

export interface NavigationProps {
  navigation: INavigation;
  extendedMode?: boolean;
}

const Navigation = ({ navigation, extendedMode = false }: NavigationProps) => {
  const { pop, push, isNestedNavigation, navProps } = useNavigation({
    baseNavigation: navigation,
  });
  return (
    <NavigationGrid
      xs
      item
      container
      nestedNav={isNestedNavigation}
      aria-label="site menu"
      component="nav"
      wrap="nowrap"
      justifyContent="space-between"
      direction="column"
      overflow="y-only"
    >
      {isNestedNavigation && <BackToMainMenu onClick={pop} />}
      {navProps.topNavGroups?.map((navigationGroup, idx) => (
        <NavList
          topNav
          key={`top-nav-${idx}`}
          pushNavigation={push}
          nested={isNestedNavigation}
          extendedMode={extendedMode}
          navItems={navigationGroup}
        />
      ))}
      {navProps.bottomNavGroups?.map((navigationGroup, idx) => (
        <NavList
          bottomNav
          key={`bottom-nav-${idx}`}
          pushNavigation={push}
          nested={isNestedNavigation}
          extendedMode={extendedMode}
          navItems={navigationGroup}
        />
      ))}
    </NavigationGrid>
  );
};

export default Navigation;
