import React from 'react';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import debounce from 'lodash/debounce';
import { AppliedFilters, Query, ResourceRecord, Sort } from 'types';
import { useFieldWatch } from 'contexts';
import usePaginationState from '../usePaginationState';
import useFilterState from '../useFilterState';

interface UseReferenceInputProps {
  resource: ResourceRecord;
  getMany: (query: Query) => void;
  getList: (query: Query) => void;
  valueField: string | null;
  queryField: string | null;
  name?: string;
  filters?: AppliedFilters;
  page?: number;
  perPage?: number;
  sort?: Sort[];
  debounceTimeout?: number;
  onChange?: (value: Record<string, any>) => void;
  prepareChoices?: (choices: Record<string, any>[]) => Record<string, any>[];
}

export const useReferenceInputController = ({
  filters = {},
  valueField,
  queryField,
  page: initialPage = 1,
  perPage: initialPerPage = 100,
  debounceTimeout = 5e2,
  sort = [],
  name,
  getMany,
  getList,
  resource,
  onChange,
  prepareChoices = (choices) => choices,
}: UseReferenceInputProps) => {
  // pagination logic
  const { pagination, setPagination, page, setPage, perPage, setPerPage } =
    usePaginationState({ page: initialPage, perPage: initialPerPage });

  const [value] = useFieldWatch(name ? [name] : [], name ? [''] : []);
  const initialLoading = React.useRef<boolean>(false);
  const prefetchLoading = React.useRef<string>('');
  // filter logic
  const { filter: currentFilters, setFilterValue } = useFilterState({
    permanentFilters: filters,
    queryField,
    page,
    setPage,
  });

  const isLoading = resource.get('isLoading');
  const isManyLoading = resource.get('isManyLoading');
  const count = resource.get('count');
  const choices = resource.get('results');

  const onChangeHandler = React.useCallback(
    (value: string | number, optionValue: string) => {
      if (onChange) {
        onChange(
          choices.find(
            (record) =>
              get(record, optionValue).toString() === value.toString(),
          )!,
        );
      }
    },
    [choices, onChange],
  );

  const getManyDebounce = React.useMemo(
    () =>
      debounce((query: Query) => {
        getMany(query);
      }, debounceTimeout),
    [debounceTimeout, getMany],
  );

  React.useEffect(() => {
    const isSame =
      prefetchLoading.current ===
      JSON.stringify({
        currentFilters,
        pagination,
        sort,
      });
    if (!isLoading && !isManyLoading && initialLoading.current && !isSame) {
      prefetchLoading.current = JSON.stringify({
        currentFilters,
        pagination,
        sort,
      });
      getManyDebounce({
        filters: currentFilters,
        pagination,
        sort,
      });
    }
  }, [
    currentFilters,
    getManyDebounce,
    isLoading,
    isManyLoading,
    pagination,
    sort,
  ]);

  // retrieve data by input value changes
  React.useEffect(() => {
    if (!name || isLoading || !initialLoading.current || !valueField) {
      return;
    }

    if (Array.isArray(value) || (!isObject(value) && value)) {
      const values = Array.isArray(value)
        ? value.reduce(
            (prev, value) => ({
              ...prev,
              [value.toString()]: true,
            }),
            {},
          )
        : { [value.toString()]: true };
      const items = choices.filter(
        (item: Record<string, any>) => values[item[valueField].toString()],
      );
      if (items.length !== Object.keys(values).length) {
        getMany({
          filters: { ...currentFilters, [valueField]: value },
          pagination,
          sort,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value, isLoading, initialLoading]);

  // retrieve data by filters changes
  const requestSignature = JSON.stringify({ pagination, sort, filters });
  React.useEffect(() => {
    if (!isLoading && !initialLoading.current) {
      getList({ pagination, sort, filters: currentFilters });
      initialLoading.current = true;
      prefetchLoading.current = JSON.stringify({
        currentFilters,
        pagination,
        sort,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestSignature]);

  return {
    name,
    // pagination
    pagination,
    setPagination,
    perPage,
    page,
    setPage,
    setPerPage,
    // sort
    // sort,
    // filters
    filters: currentFilters,
    setFilterValue,
    // data
    choices: prepareChoices(choices),
    isLoading,
    isPrefetchLoading: isManyLoading,
    count,
    onChange: onChangeHandler,
  };
};
