import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const LoaderContainer = styled('div', {
  name: 'LoaderContainer',
})(({ theme }) => ({
  padding: theme.spacing(3, 0, 0.5),
  margin: 0,
}));

export interface LoadingIndicatorProps {
  loading?: boolean;
}

const LoadingIndicator = ({ loading }: LoadingIndicatorProps) =>
  loading ? (
    <LoaderContainer>
      <LinearProgress />
    </LoaderContainer>
  ) : null;

export default LoadingIndicator;
