import { OnSuccessCallback } from 'types';
import services from 'store/services';
import { createRoutine } from './utils';

export const shareSavedSearchAction = createRoutine('SHARE_SAVED_SEARCH');

const shareSavedSearch = (
  payload: Record<string, unknown>,
  onSuccessCallback: OnSuccessCallback,
) =>
  shareSavedSearchAction({
    ...payload,
    meta: {
      service: services.SHARE_SEARCH,
      onSuccess: {
        notification: {
          level: 'success',
          message: 'Saved search was shared.',
        },
        callback: onSuccessCallback,
      },
      onFailure: {
        notification: {
          level: 'error',
          message: 'Something wrong happen during share the search',
        },
      },
    },
  });

const savedSearchActions = {
  shareSavedSearch,
};

export default savedSearchActions;
