import { User } from 'types';

interface Role {
  id: string;
  title: string;
  value: boolean;
}

const getRoles = (record: User) => {
  const roles = [
    {
      id: 'is_group_admin',
      title: 'Group admin',
      value: record.is_group_admin,
    },
    {
      id: 'is_org_admin',
      title: 'Org admin',
      value: record.is_org_admin,
    },
    {
      id: 'is_workflow_admin',
      title: 'Workflow admin',
      value: record.is_workflow_admin,
    },
  ];
  const listArr = roles
    .reduce(
      (acc: any, item: Role) => [...acc, ...[item.value && item.title]],
      [],
    )
    .filter((item) => item);
  return listArr.length ? listArr.join(', ') : 'User';
};

export default getRoles;
