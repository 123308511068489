import { useState, useCallback } from 'react';
import { BaseItem } from 'types';

const useSelectedItems = <T extends BaseItem = BaseItem>(initialItems: T[]) => {
  const [selectedItems, onSetSelectedItems] = useState<T[]>(initialItems);

  const setSelectedItems = useCallback(
    (items: T[]) => {
      onSetSelectedItems(items);
    },
    [onSetSelectedItems],
  );

  const setSelectedItem = useCallback(
    (item: T) => {
      onSetSelectedItems([...selectedItems, item]);
    },
    [selectedItems, onSetSelectedItems],
  );

  const unsetSelectedItem = useCallback(
    (selectedItem: T) => {
      onSetSelectedItems(
        selectedItems.filter((item) => item.id !== selectedItem.id),
      );
    },
    [selectedItems, onSetSelectedItems],
  );

  return {
    selectedItems,
    setSelectedItems,
    setSelectedItem,
    unsetSelectedItem,
  };
};

export default useSelectedItems;
