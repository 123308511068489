import Grid from 'components/Grid';
import { styled } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import BtnContainer from 'layouts/AppLayout/BtnContainer';
import AccessibilityMode from 'layouts/AppLayout/AccessibilityMode';
import Badge from 'components/Badge';
import { ReactComponent as BellIcon } from 'assets/bell.svg';
import UserMenu from 'components/UserMenu';
import IconButton from 'components/ui/IconButton';

const MenuBlock = styled(Grid)(() => ({
  minWidth: 0,
}));

const UserMenuBlock = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  minWidth: 0,
  maxWidth: '1200px',
}));

const NotificationErrorBtn = styled(IconButton)(({ theme }) => ({
  padding: 0,
  height: 22,
  width: 22,
  color: theme.palette.error.main,
}));

export interface MenuProps {
  onNotificationClick: () => void;
  notificationsCount: number;
  backendStatus: boolean;
  loadingBackendStatus: boolean;
  firstName: string | null;
  lastName: string | null;
  loadingNotifications: boolean;
}

const Menu = ({
  onNotificationClick,
  backendStatus,
  loadingBackendStatus,
  notificationsCount,
  firstName,
  lastName,
  loadingNotifications,
}: MenuProps) => {
  let iconComponent =
    loadingBackendStatus && !backendStatus ? (
      <NotificationErrorBtn
        title="Notifications are currently unavailable"
        size="large"
      >
        <ErrorIcon />
      </NotificationErrorBtn>
    ) : (
      <IconButton
        loading={loadingNotifications}
        title="Notifications"
        onClick={onNotificationClick}
        size="large"
      >
        <Badge badgeContent={notificationsCount} color="error">
          <BellIcon />
        </Badge>
      </IconButton>
    );

  return (
    <MenuBlock container fullWidth wrap="nowrap" alignItems="center" mx={2}>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-end"
        wrap="nowrap"
      >
        <BtnContainer item>
          <AccessibilityMode />
        </BtnContainer>
        <BtnContainer item>{iconComponent}</BtnContainer>
      </Grid>
      <UserMenuBlock item actualSize>
        <UserMenu firstName={firstName} lastName={lastName} />
      </UserMenuBlock>
    </MenuBlock>
  );
};

export default Menu;
