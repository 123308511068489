import {
  KeyboardEvent,
  useEffect,
  useCallback,
  useState,
  SyntheticEvent,
} from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import { AutocompleteInputChangeReason } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { useToggle } from 'hooks';
import { ConfirmCallback } from 'hooks/useConfirm';
import { AppliedFilters } from 'types';
import useMany from '../useMany';

interface UseAutocompleteProps {
  optionValue: string;
  optionText: string;
  defaultEmptyOptionText?: string;
  choices: Record<string, any>[];
  setFilterValue?: (value: string) => void;
  currentFilters?: AppliedFilters;
  many?: boolean;
  field: ControllerRenderProps;
  filterOptions: (conf: {
    options: Array<any>;
    inputValue: string;
    getOptionLabel: (value: any) => string;
  }) => any[];
  count?: number;
  setPage?: (page: number) => void;
  page?: number;
  shouldConfirm?: boolean | ((value: number | string) => boolean);
  confirm: (confirm: ConfirmCallback) => void;
  isLoading?: boolean;
  onChange?: (value: unknown) => void;
  totalCountResult?: number;
}

const useAutocomplete = ({
  optionText,
  optionValue,
  defaultEmptyOptionText,
  field,
  many = false,
  filterOptions,
  choices,
  setFilterValue,
  count,
  setPage,
  page,
  shouldConfirm,
  confirm,
  isLoading,
  totalCountResult,
  onChange: customChange,
}: UseAutocompleteProps) => {
  const { isMarked: isOpen, toggle } = useToggle();
  const [inputValue, setInputValue] = useState<string>('');

  const getOptionLabel = useCallback(
    (option: any) => {
      const value = isString(option)
        ? option
        : get(option, optionText, defaultEmptyOptionText) ||
          defaultEmptyOptionText;

      return isLoading && value === defaultEmptyOptionText ? '' : value;
    },
    [optionText, defaultEmptyOptionText, isLoading],
  );
  const getOptionValue = useCallback(
    (option: unknown) => {
      return isObject(option) ? get(option, optionValue) : option;
    },
    [optionValue],
  );

  const onChangeOverride = useCallback(
    (value: unknown) => {
      if (value && Array.isArray(value)) {
        const lastIndex = value.length - 1;
        if (
          isFunction(shouldConfirm)
            ? shouldConfirm(value[lastIndex])
            : Boolean(shouldConfirm)
        ) {
          confirm(() => {
            field.onChange(value);
            if (customChange) {
              customChange(value);
            }
          });
          return;
        }
      }
      field.onChange(value);
      if (customChange) {
        customChange(value);
      }
    },
    [confirm, customChange, field, shouldConfirm],
  );

  const { onChange, value } = useMany({
    field: { ...field, onChange: onChangeOverride },
    many,
    getValue: (evt: any, value: any) => {
      if (getOptionValue(evt) !== undefined) {
        return getOptionValue(evt);
      }
      return getOptionValue(value);
    },
    choices,
    defaultValue: null,
  });
  const onInputChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') return;
      if (reason === 'clear') {
        setInputValue('');
        onChange(null);
        return;
      }

      setInputValue(newInputValue);
    },
    [onChange, setInputValue],
  );
  // callbacks
  const onChangeWrapper = useCallback(
    (evt: SyntheticEvent<Element, Event>, value: unknown) => {
      if (value) {
        setInputValue(
          many ? '' : getOptionValue(value) ? getOptionLabel(value) : '',
        );
        onChange(value);
        return;
      }
    },
    [onChange, setInputValue, getOptionLabel, many, getOptionValue],
  );
  const onKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === 'Enter') {
        toggle();
        const filteredOptions = filterOptions({
          options: choices,
          inputValue,
          getOptionLabel,
        });
        onChangeWrapper(evt, filteredOptions[0]);
      }
    },
    [
      filterOptions,
      inputValue,
      toggle,
      choices,
      getOptionLabel,
      onChangeWrapper,
    ],
  );

  const open = useCallback(() => {
    toggle(true);
  }, [toggle]);

  const close = useCallback(() => {
    toggle(false);
  }, [toggle]);

  const loadMore = useCallback(() => {
    const countResult =
      count !== undefined && totalCountResult !== undefined
        ? count >= totalCountResult
          ? count
          : totalCountResult
        : count !== undefined
        ? count
        : totalCountResult;

    if (
      countResult !== undefined &&
      page !== undefined &&
      setPage !== undefined &&
      choices.length < countResult
    ) {
      setPage(page + 1);
    }
  }, [count, totalCountResult, page, setPage, choices]);

  useEffect(() => {
    if (field.value && choices && choices.length && !isOpen && !isLoading) {
      const option = choices.find((option: any) =>
        getOptionValue(option)
          ? getOptionValue(option).toString() === field.value.toString()
          : getOptionValue(option) === field.value,
      );
      if (
        !many &&
        !isLoading &&
        getOptionLabel(option) !== inputValue &&
        ((inputValue !== defaultEmptyOptionText &&
          getOptionLabel(option) !== defaultEmptyOptionText) ||
          !inputValue ||
          inputValue === defaultEmptyOptionText)
      ) {
        setInputValue(getOptionLabel(option));
      }
    }
  }, [
    many,
    choices,
    field.value,
    isOpen,
    inputValue,
    setInputValue,
    getOptionLabel,
    getOptionValue,
    value,
    isLoading,
    defaultEmptyOptionText,
  ]);

  useEffect(() => {
    if (inputValue && setFilterValue && inputValue !== defaultEmptyOptionText) {
      setFilterValue(inputValue);
    }
  }, [defaultEmptyOptionText, inputValue, setFilterValue]);

  useEffect(() => {
    if (isLoading && inputValue === defaultEmptyOptionText) {
      setInputValue('');
    }
  }, [defaultEmptyOptionText, inputValue, isLoading]);

  const onBlur = () => {
    const findElement = inputValue
      ? choices?.find(
          (item) => inputValue.toLowerCase() === item[optionText].toLowerCase(),
        )
      : '';

    if (!findElement) {
      setInputValue('');
    }
  };

  return {
    isOpen,
    value,
    inputValue,
    open,
    close,
    onKeyDown,
    loadMore,
    onChange: onChangeWrapper,
    onInputChange,
    getOptionLabel,
    onBlur,
  };
};

export default useAutocomplete;
