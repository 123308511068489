import { useInput } from 'contexts';
import { Color } from 'types';
import Input, { InputProps } from './Input';

export interface FormInputProps
  extends Omit<InputProps, 'field' | 'meta' | 'error' | 'color'> {
  name: string;
  color?: Color | 'black' | 'white';
  colorLabel?: Color | 'black' | 'white';
}

const FormInput = ({
  name,
  rules,
  many,
  defaultValue = many ? [] : '',
  type = 'text',
  ...props
}: FormInputProps) => {
  const { field, error, meta } = useInput({
    name,
    rules,
    defaultValue,
  });
  return (
    <Input
      {...props}
      type={type}
      many={many}
      field={field}
      meta={meta}
      error={error}
    />
  );
};

export default FormInput;
