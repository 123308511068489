import { ReducerState } from 'store/reducers';

const accessibilityState = (state: ReducerState) => state.accessibility;

const colorTheme = (state: ReducerState) =>
  accessibilityState(state).get('colorTheme');
const fontSize = (state: ReducerState) =>
  accessibilityState(state).get('fontSize');

const accessibilitySelectors = {
  accessibilityState,
  colorTheme,
  fontSize,
};

export default accessibilitySelectors;
