import { ReactElement, ReactNode } from 'react';
import { ErrorBoundary } from 'contexts/Loggers';

const ReactErrorBoundary = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary title="Something went wrong :-(" labelAction="Try again">
    {children as ReactElement}
  </ErrorBoundary>
);

export default ReactErrorBoundary;
