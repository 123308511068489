import React from 'react';
import { FormContext } from './FormContext';

const useFormState = () => {
  const { formState, trigger, watch, getValues } =
    React.useContext(FormContext);
  return { formState, trigger, watch, getValues };
};

export default useFormState;
