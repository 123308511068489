import {
  useCallback,
  SyntheticEvent,
  useMemo,
  HTMLAttributes,
  useState,
  useRef,
  useEffect,
} from 'react';
import MuiInput, { InputProps as MuiInputProps } from '@mui/material/Input';
import LinearProgress from '@mui/material/LinearProgress';
import { styled, Theme } from '@mui/material/styles';
import { StyledComponent } from '@mui/styles';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { useConfirm } from 'hooks';
import { AppliedFilters } from 'types';
import { UseControllerMethods } from 'contexts';
import CircularProgress from 'components/CircularProgress';
import { ReactComponent as ArrowDownIcon } from 'assets/menu.svg';
import useAutocomplete from './useAutocomplete';
import FormInputControl from '../FormInputControl';

export interface InputProps extends MuiInputProps {
  valid?: boolean;
}

const Input = styled(MuiInput, {
  shouldForwardProp: (propName: string) => propName !== 'valid',
})<InputProps>(({ valid, theme }: InputProps & { theme: Theme }) => ({
  '& .Mui-focused': {
    color: `${theme.palette.warning.contrastText} !important`,
    '&:after': {
      borderBottomColor: `${theme.palette.warning.contrastText} !important`,
    },
  },
  ...(valid !== undefined && {
    '&:before': {
      borderBottomColor: valid
        ? theme.palette.success.main
        : theme.palette.error.main,
    },
  }),
})) as StyledComponent<InputProps>;

const PopupIcon = styled(ArrowDownIcon)(({ theme }) => ({
  margin: theme.spacing(1.2, 1),
}));

const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  color: theme.palette.warning.contrastText,
}));

export interface ExternalProps {
  optionValue?: string;
  optionText?: string;
  isLoading?: boolean;
  choices?: Record<string, any>[];
  setFilterValue?: (value: string) => void;
  currentFilters?: AppliedFilters;
  disabled?: boolean;
  count?: number;
  setPage?: (page: number) => void;
  page?: number;
  many?: boolean;
  helpText?: string;
  blurOnSelect?: MuiAutocompleteProps<
    any,
    boolean,
    boolean,
    boolean
  >['blurOnSelect'];
  clearOnBlur?: MuiAutocompleteProps<
    any,
    boolean,
    boolean,
    boolean
  >['clearOnBlur'];
  [prop: string]: any;
}

export interface AutocompleteProps extends UseControllerMethods, ExternalProps {
  name: string;
  sort?: (
    firstChoice: Record<string, any>,
    secondChoice: Record<string, any>,
  ) => number;
  format?: (value: string | number) => string | number;
  error?: string;
  defaultEmptyOptionText?: string;
  shouldConfirm?: boolean | ((value: number | string) => boolean);
  confirmMessage?: string;
  breakSymbol?: string;
}

const defaultFilterOptions = createFilterOptions();

export const filterOptions = ({
  options,
  inputValue,
  getOptionLabel,
}: {
  options: Array<any>;
  inputValue: string;
  getOptionLabel: (value: any) => string;
}): Array<any> => {
  return defaultFilterOptions(
    options,
    // we use the empty string to manipulate `filterOptions` to not filter any options
    // i.e. the filter predicate always returns true
    { inputValue, getOptionLabel },
  );
};

const AutocompleteInput = ({
  label,
  resource,
  autocomplete,
  field,
  meta,
  error,
  name,
  helpText,
  required,
  isLoading,
  isPrefetchLoading,
  setFilterValue,
  currentFilters,
  getOptionDisabled,
  choices = [],
  sort,
  format,
  setPage,
  page,
  count,
  shouldConfirm,
  renderOption,
  breakSymbol,
  disabledStateValidation,
  totalCountResult,
  onChange: customChange,
  many = false,
  optionValue = 'id',
  optionText = 'name',
  margin = 'normal',
  blurOnSelect = true,
  clearOnBlur = true,
  disabled = false,
  defaultEmptyOptionText = 'N/A',
  confirmMessage = 'Confirm your selection',
}: AutocompleteProps) => {
  const { isTouched, invalid } = meta;
  const confirm = useConfirm({ title: confirmMessage });
  const formattedChoices = format
    ? choices.map((option: Record<string, string | number>) => ({
        ...option,
        [optionText]: format(option[optionText]),
      }))
    : choices;
  const options: Record<string, any>[] = useMemo(() => {
    const values = (resource ? autocomplete : formattedChoices) || [];
    if (sort) {
      return values.sort(sort);
    }
    return values;
  }, [resource, autocomplete, formattedChoices, sort]);
  const {
    isOpen,
    value,
    inputValue,
    close,
    open,
    onKeyDown,
    onChange,
    getOptionLabel,
    onInputChange,
    loadMore,
    onBlur,
  } = useAutocomplete({
    choices: formattedChoices,
    setFilterValue,
    currentFilters,
    many,
    optionValue,
    optionText,
    field,
    defaultEmptyOptionText,
    filterOptions,
    setPage,
    page,
    count,
    shouldConfirm,
    confirm,
    isLoading: isLoading || isPrefetchLoading,
    onChange: customChange,
    totalCountResult,
  });

  const [position, setPosition] = useState(0);
  const listElem = useRef<HTMLUListElement>();
  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    if (position && listElem.current) {
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
    }
  }, [position]);

  const defaultRenderOption = useCallback(
    (renderProps: HTMLAttributes<HTMLLIElement>, option: unknown) => {
      if (breakSymbol) {
        const optionsLabels = getOptionLabel(option).split(breakSymbol);

        return (
          <li {...renderProps} key={renderProps.id}>
            {optionsLabels.map((element: string, index: number) =>
              index + 1 === optionsLabels.length ? (
                element
              ) : (
                <>
                  {element}
                  <wbr />
                  {breakSymbol}
                </>
              ),
            )}
          </li>
        );
      }

      return (
        <li {...renderProps} key={renderProps.id}>
          {getOptionLabel(option)}
        </li>
      );
    },
    [breakSymbol, getOptionLabel],
  );

  const onScroll = useCallback(
    (event: SyntheticEvent) => {
      const listBoxNode = event.currentTarget;
      const scrollPosition = listBoxNode.scrollTop + listBoxNode.clientHeight;

      if (listBoxNode.scrollHeight - scrollPosition <= 1) {
        setPosition(scrollPosition);
        loadMore();
      }
    },
    [loadMore],
  );

  return (
    <Autocomplete
      fullWidth
      onBlur={onBlur}
      blurOnSelect={blurOnSelect}
      clearOnBlur={clearOnBlur}
      id={name}
      key={`autocomplete-${name}`}
      open={isOpen}
      onOpen={open}
      onClose={close}
      disabled={disabled}
      renderOption={renderOption || defaultRenderOption}
      filterOptions={defaultFilterOptions}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      options={options}
      value={value}
      inputValue={inputValue}
      onChange={onChange}
      loading={isPrefetchLoading}
      noOptionsText="No search results"
      loadingText={<LinearProgress />}
      popupIcon={
        isLoading || isPrefetchLoading ? (
          <CircularProgress size={25} />
        ) : (
          <PopupIcon />
        )
      }
      onInputChange={onInputChange}
      ListboxProps={
        {
          ref: listElem,
          onScroll,
        } as HTMLAttributes<HTMLUListElement>
      }
      renderInput={({
        InputLabelProps,
        InputProps,
        inputProps,
        fullWidth,
        id,
      }) => (
        <FormInputControl
          key={`input-control-${name}`}
          fullWidth={fullWidth}
          margin={margin}
          name={field.name}
          label={label}
          required={required}
          loading={isLoading}
          error={error}
          meta={meta}
          helpText={helpText}
          InputLabelProps={InputLabelProps}
          disabledStateValidation={disabledStateValidation}
        >
          <Input
            id={id}
            key={`input-${name}`}
            valid={
              disabledStateValidation
                ? undefined
                : isTouched
                ? !invalid
                : undefined
            }
            disabled={disabled}
            error={Boolean(isTouched && invalid)}
            inputProps={inputProps}
            {...InputProps}
            onKeyDown={onKeyDown}
          />
        </FormInputControl>
      )}
    />
  );
};

export default AutocompleteInput;
