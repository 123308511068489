import { DetailGroup, Group, Member } from 'types';
import { Empty, PageQuery, PageResponse, request } from './request';

interface FetchGroupPayload extends PageQuery {
  organization_id: string;
}

const list = (
  { organization_id, ...query }: FetchGroupPayload,
  token: string,
) =>
  request<Empty, PageResponse<Group>, Empty>(
    organization_id === 'custom'
      ? 'organization-groups/'
      : `organization/${organization_id}/groups/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const preparePayload = (payload: Partial<DetailGroup>) => {
  return {
    ...payload,
    users: payload.users
      ? payload.users.map((member: Member) => ({
          user: member.email,
          role: member.role,
        }))
      : [],
  };
};

interface CreateDetailGroup extends Omit<DetailGroup, 'organization_id'> {
  organization_id: number | string;
}

const create = (
  { organization_id, ...payload }: Partial<CreateDetailGroup>,
  token: string,
) =>
  request<Partial<Group>, Group, Empty>(
    organization_id === 'custom'
      ? 'organization-groups/'
      : `organization/${organization_id}/groups/`,
    {
      method: 'POST',
      payload: preparePayload({
        ...payload,
      }),
    },
    token,
  );

const detail = ({ id }: Group, token: string) =>
  request<Empty, PageResponse<Group>, Empty>(
    `group/${id}/`,
    {
      method: 'GET',
    },
    token,
  );

const update = (payload: Partial<DetailGroup>, token: string) =>
  request<Partial<Group>, Group, Empty>(
    `group/${payload.id}/`,
    {
      method: 'PATCH',
      payload: preparePayload(payload),
    },
    token,
  );

const remove = (payload: Group, token: string) =>
  request<Empty, Empty, Empty>(
    `group/${payload.id}/`,
    {
      method: 'DELETE',
    },
    token,
  );

const groupsServices = {
  list,
  create,
  detail,
  update,
  remove,
};

export default groupsServices;
