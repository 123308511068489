import React from 'react';

const useStep = (maxStep: number) => {
  const [currentStep, setStep] = React.useState<number>(0);
  const incrementStep = React.useCallback(() => {
    setStep((step) => {
      if (step !== maxStep) {
        return step + 1;
      }
      return step;
    });
  }, [setStep, maxStep]);
  const decrementStep = React.useCallback(() => {
    setStep((step) => {
      if (step > 0) {
        return step - 1;
      }
      return step;
    });
  }, [setStep]);

  return {
    isFirstStep: currentStep === 0,
    isLastStep: currentStep === maxStep,
    incrementStep,
    decrementStep,
    currentStep,
    setStep,
  };
};

export default useStep;
