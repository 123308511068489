import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Country, Organisation } from 'types';

export interface OrganizationPayload {
  id: number | string;
  name: string;
  website: string;
  owners_ids: Array<number>;
  legal_entity: string;
  legislation_area: string;
  email: string;
  country: Country;
  city: string;
  street: string;
  state: string;
  zip_code: string;
  house_number: string;
  suite: string;
  s3_bucket: string;
}

const list = (query: PageQuery, token: string) =>
  request<Empty, PageResponse<Organisation>, Empty>(
    `organization/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const create = (payload: OrganizationPayload, token: string) =>
  request<OrganizationPayload, PageResponse<Organisation>, Empty>(
    `organization/`,
    {
      method: 'POST',
      payload,
    },
    token,
  );

const detail = (payload: OrganizationPayload, token: string) =>
  request<Empty, PageResponse<Organisation>, Empty>(
    payload.id === 'custom'
      ? 'organization-details/'
      : `organization/${payload.id}/`,
    {
      method: 'GET',
    },
    token,
  );

const update = (
  payload: OrganizationPayload,
  token: string,
  partial: boolean = false,
) =>
  request<OrganizationPayload, Organisation, Empty>(
    `organization/${payload.id}/`,
    {
      method: partial ? 'PATCH' : 'PUT',
      payload,
    },
    token,
  );

const remove = (payload: Organisation, token: string) =>
  request<Empty, Empty, Empty>(
    `organization/${payload.id}/`,
    {
      method: 'DELETE',
    },
    token,
  );

const organisationsServices = {
  list,
  create,
  detail,
  update,
  remove,
};

export default organisationsServices;
