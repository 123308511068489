import { modals } from 'contstants';
import { ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { show } from 'redux-modal';

export type ConfirmCallback = () => void;

export interface UseConfirmProps {
  title?: ReactNode;
  content?: ReactNode;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

const useConfirm = ({
  title = 'Are you sure that you want to delete it?',
  content,
  confirmBtnText,
  cancelBtnText,
}: UseConfirmProps) => {
  const dispatch = useDispatch();

  return useCallback(
    (confirm: ConfirmCallback) => {
      dispatch(
        show(modals.CONFIRM_DIALOG, {
          title,
          confirm,
          content,
          confirmBtnText,
          cancelBtnText,
        }),
      );
    },
    [dispatch, title, content, confirmBtnText, cancelBtnText],
  );
};

export default useConfirm;
