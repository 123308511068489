import { ElementType } from 'react';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';
import CircularProgress from 'components/CircularProgress';
import Tooltip from 'components/Tooltip';

export interface IconButtonProps extends MuiIconButtonProps {
  toggleButton?: boolean;
  component?: ElementType;
  loading?: boolean;
  label?: string;
}

const StyledIconButton = styled(MuiIconButton, {
  shouldForwardProp: (propName: string) => {
    return ['toggleButton'].indexOf(propName) === -1;
  },
})<IconButtonProps>(
  ({ theme, ...ownerState }: IconButtonProps & { theme: Theme }) => ({
    ...(ownerState.toggleButton && {
      padding: 0,
    }),
  }),
);

const IconButton = ({
  loading = false,
  label = '',
  ...props
}: IconButtonProps) => {
  return loading ? (
    <CircularProgress size={15} />
  ) : (
    <Tooltip title={label}>
      <>
        <StyledIconButton {...props} />
      </>
    </Tooltip>
  );
};

export default IconButton;
