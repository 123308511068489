type Callback = (...args: any) => void;

const fallbackCopyToClipboard = (
  text: string,
  successCallback: Callback,
  failCallback: Callback,
) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      successCallback();
      return;
    }
    failCallback();
  } catch (err) {
    failCallback();
  }

  document.body.removeChild(textArea);
};

const copyToClipboard = (text: string) => {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      fallbackCopyToClipboard(text, resolve, reject);
      return;
    }
    navigator.clipboard.writeText(text).then(resolve, reject);
  });
};

export default copyToClipboard;
