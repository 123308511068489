import React from 'react';
import { TableProps } from 'react-table';
import Grid from 'components/Grid';

export const Table = ({
  className,
  children,
  ...tableProps
}: React.PropsWithChildren<TableProps>) => (
  <Grid xs item container direction="column">
    <Grid
      sx={{ flexGrow: 1 }}
      container
      direction="column"
      className={className}
      wrap="nowrap"
      {...tableProps}
    >
      {children}
    </Grid>
  </Grid>
);

export default Table;
