import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Regulator } from 'types';

export interface RegulatorPayload extends PageQuery {
  regulator?: string;
  id?: number;
}

const list = (query: RegulatorPayload, token: string) =>
  request<Empty, PageResponse<Regulator>, Empty>(
    `wm-objects/regulator/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const regulatorsServices = {
  list,
};

export default regulatorsServices;
