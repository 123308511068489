import { createRoutine } from './utils';

export const fetchOrganisations = createRoutine('FETCH_ORGANISATIONS');
export const applyFilters = createRoutine('APPLY_ORGANISATION_FILTERS');

export interface FetchOrganisationsPayload {
  filters: Array<{ id: string; value: any }>;
  sortBy: Array<{ id: string; desc: boolean }>;
  page: number;
  itemsPerPage: number;
}

export interface FiltersPayload {
  filters: Array<{ id: string; value: any }>;
}

const organisationsActions = {
  applyFilters,
  fetchOrganisations,
};

export default organisationsActions;
