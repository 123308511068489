import { RelatedDocuments } from 'types';
import { Empty, PageResponse, request, ResponseData } from 'services/request';
import { getResponseList } from 'services/utils';

interface DetailPayload {
  id: number;
}

const list = (query: DetailPayload, token: string) =>
  request<Empty, RelatedDocuments, Empty>(
    `wm-objects/related-document/${query.id}`,
    {
      method: 'GET',
    },
    token,
  ).then(({ response, error }) =>
    getResponseList<RelatedDocuments>({
      response: {
        ...response,
        data: response?.data?.related_op_data_docs,
      } as ResponseData<RelatedDocuments[]>['response'],
      error,
    }),
  );

interface Payload extends DetailPayload {
  document_name: string;
  related_op_data_docs: number[];
}

const update = (payload: Payload, token: string) => {
  return request<Empty, PageResponse<RelatedDocuments>, Empty>(
    `wm-objects/related-document/${payload.id}`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );
};

const relatedDocumentsServices = {
  list,
  update,
};

export default relatedDocumentsServices;
