import { ComponentType } from 'react';
import { Property } from 'csstype';
import { Theme, styled } from '@mui/material/styles';
import MuiGrid, {
  GridProps as MuiGridProps,
  GridSpacing,
  GridDirection,
  GridSize,
  GridWrap,
} from '@mui/material/Grid';
import { CSSObject } from '@mui/material';
import { StyledComponent } from '@mui/styles';

type Overflow = 'auto' | 'x-only' | 'y-only' | 'hidden';
type GridJustification = Property.JustifyContent;
type GridItemsAlignment = Property.AlignItems;
type GridContentAlignment = Property.AlignContent;

export interface GridProps extends MuiGridProps {
  overflow?: Overflow;
  component?: ComponentType | string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  flexOnly?: boolean;
  stickyTop?: boolean;
  stickyBottom?: boolean;
  actualSize?: boolean;
  [propName: string]: any;
}

const generateOverflow = (overflow: Overflow) => {
  switch (overflow) {
    case 'x-only':
      return {
        overflowX: 'auto',
        overflowY: 'hidden',
      };
    case 'y-only':
      return {
        overflowY: 'auto',
        overflowX: 'hidden',
      };
    case 'auto':
      return {
        overflowX: 'auto',
        overflowY: 'auto',
      };
    case 'hidden':
      return {
        overflow: 'hidden',
      };
  }
};

const StyledGrid = styled(MuiGrid, {
  shouldForwardProp: (prop: string) => {
    return (
      [
        'flexOnly',
        'fullHeight',
        'fullWidth',
        'actualSize',
        'overflow',
        'stickyBottom',
        'stickyBottomMargin',
        'stickyTop',
        'stickyTopMargin',
      ].indexOf(prop) === -1
    );
  },
})<GridProps>(
  ({
    theme,
    stickyTopMargin = 0,
    stickyBottomMargin = -18,
    backgroundColor,
    ...ownerState
  }: GridProps & { theme: Theme }) => ({
    ...(ownerState.flexOnly && {
      display: 'flex',
      width: 'initial',
    }),
    ...(ownerState.fullHeight && {
      height: '100%',
    }),
    ...(ownerState.fullWidth && {
      width: '100%',
    }),
    ...(ownerState.actualSize && {
      flex: '0 0 auto',
    }),
    ...(ownerState.stickyTop && {
      position: 'sticky',
      backgroundColor: backgroundColor || theme.palette.background.paper,
      top: stickyTopMargin,
      left: 0,
      right: 0,
      zIndex: 1,
      marginBottom: theme.spacing(2),
    }),
    ...(ownerState.stickyBottom && {
      position: 'sticky',
      backgroundColor: backgroundColor || theme.palette.background.paper,
      bottom: stickyBottomMargin,
      left: 0,
      right: 0,
      zIndex: 1,
    }),
    ...(ownerState.overflow &&
      (generateOverflow(ownerState.overflow) as CSSObject)),
  }),
);

export default StyledGrid as StyledComponent<GridProps>;

export type {
  GridSize,
  GridWrap,
  GridJustification,
  GridItemsAlignment,
  GridContentAlignment,
  GridDirection,
  GridSpacing,
};
