import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import qs from 'query-string';
import { AppliedFilters, Pagination, Sort } from 'types';

interface StringifyQueryProps {
  filters: AppliedFilters;
  sort?: Array<Sort>;
  pagination?: Pagination;
}

type StringifyRecord = Record<string, string | number>;

const stringifyQuery = ({
  filters = {},
  sort = [],
  pagination = { perPage: 25, page: 1 },
}: StringifyQueryProps): string => {
  return qs.stringify({
    ...Object.keys(filters || {}).reduce((joinedFilters, filterName) => {
      const filterValue = filters[filterName];
      if (
        !isEmpty(filterValue) &&
        !isArray(filterValue) &&
        isObject(filterValue)
      ) {
        return {
          ...joinedFilters,
          ...Object.keys(filterValue).reduce(
            (formattedValues: StringifyRecord, property: string) => ({
              ...formattedValues,
              [`${filterName}[${property}]`]: (
                filterValue as Record<string, any>
              )[property],
            }),
            {} as StringifyRecord,
          ),
        };
      }
      if (filterValue) {
        return {
          ...joinedFilters,
          [filterName]: filterValue,
        };
      }
      return joinedFilters;
    }, {}),
    page: pagination.page,
    per_page: pagination.perPage,
    sort: sort
      .map((sort: Sort) => {
        return `${sort.order === 'desc' ? '-' : ''}${sort.field}`;
      })
      .join(','),
  });
};

export default stringifyQuery;
