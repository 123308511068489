import { ReactNode, useCallback, useMemo, useState } from 'react';
import isBoolean from 'lodash/isBoolean';
import CollapseContext from './CollapseContext';

export interface CollapseProviderProps {
  children: ReactNode;
}

const CollapseProvider = ({ children }: CollapseProviderProps) => {
  const [expandState, setExpandState] = useState<Record<string, boolean>>({});
  const expanded = useMemo(
    () => Object.values(expandState).some((expanded) => Boolean(expanded)),
    [expandState],
  );

  const append = (uuid: string, defaultValue?: boolean) => {
    setExpandState((prevState) =>
      prevState[uuid] !== undefined
        ? prevState
        : {
            ...prevState,
            [uuid]: Boolean(defaultValue),
          },
    );
  };

  const toggle = useCallback(
    (uuid: string) =>
      (nextState: unknown = null) => {
        setExpandState((prevState) => ({
          ...prevState,
          [uuid]:
            nextState !== null && isBoolean(nextState)
              ? nextState
              : !prevState[uuid],
        }));
      },
    [],
  );

  const isExpanded = useCallback(
    (uuid: string) =>
      Boolean(expandState[uuid] === undefined ? true : expandState[uuid]),
    [expandState],
  );

  const toggleAll = useCallback(
    (nextState?: unknown) => {
      setExpandState((prevState: Record<string, boolean>) => {
        return Object.fromEntries(
          Object.entries(prevState).map(([key, _]) => [
            key,
            nextState !== undefined && isBoolean(nextState)
              ? nextState
              : !expanded,
          ]),
        ) as Record<string, boolean>;
      });
    },
    [expanded],
  );
  return (
    <CollapseContext.Provider
      value={{ expanded, append, toggle, isExpanded, toggleAll }}
    >
      {children}
    </CollapseContext.Provider>
  );
};

export default CollapseProvider;
