import { ReactNode, memo, useMemo } from 'react';
import get from 'lodash/get';
import { Redirect } from 'react-router-dom';
import { useUserDetails } from 'hooks';
import { JWTToken } from 'types';
import { AppLayout } from 'layouts';
import TitleRoute, { TitleRouteProps } from 'routingComponents/TitleRoute';

type AuthPermission =
  | 'is_org_admin'
  | 'is_staff'
  | 'is_group_admin'
  | 'is_workflow_admin'
  | 'is_superuser'
  | 'is_org_creator'
  | 'are_workflows_allowed';

type FeatureFlag = 'workflow';

interface PrivateRouteProps extends TitleRouteProps {
  permissions?: AuthPermission | AuthPermission[];
  featureFlags?: FeatureFlag | FeatureFlag[];
  test?: (email: string, jwtToken: JWTToken) => boolean;
  nestedRoute?: boolean;
}

const PrivateRoute = memo(
  ({
    children,
    permissions = [],
    featureFlags = [],
    test,
    location,
    nestedRoute = false,
    ...rest
  }: PrivateRouteProps) => {
    const { isAuthenticated, email, auth: jwtToken } = useUserDetails();
    const hasAccess = useMemo(() => {
      const authPermissions = Array.isArray(permissions)
        ? permissions
        : [permissions];
      const accessFeatureFlags = Array.isArray(featureFlags)
        ? featureFlags
        : [featureFlags];
      if (isAuthenticated) {
        const userEnabledFeatures = Object.fromEntries(
          jwtToken.payload.permissions.map((featureFlag) => [
            featureFlag,
            true,
          ]),
        );
        if (test) {
          return test(email || '', jwtToken);
        }
        const hasPermissionAccess =
          authPermissions.length !== 0
            ? authPermissions.findIndex(
                (permission) =>
                  get(jwtToken.payload, permission, false) === true,
              ) !== -1
            : true;
        const hasFeatureAccess =
          accessFeatureFlags.length !== 0
            ? accessFeatureFlags.findIndex(
                (featureFlag) => userEnabledFeatures[featureFlag] === true,
              ) !== -1
            : true;
        return accessFeatureFlags.length !== 0 && authPermissions.length !== 0
          ? hasFeatureAccess || hasPermissionAccess
          : hasFeatureAccess && hasPermissionAccess;
      }
      return false;
    }, [featureFlags, permissions, isAuthenticated, test, email, jwtToken]);

    const path = location?.search
      ? encodeURIComponent(`${location?.pathname}${location?.search}`)
      : location?.pathname;
    return hasAccess ? (
      <TitleRoute {...rest}>
        {nestedRoute ? (
          children
        ) : (
          <AppLayout>{children as ReactNode}</AppLayout>
        )}
      </TitleRoute>
    ) : (
      <Redirect
        to={{
          pathname: isAuthenticated
            ? jwtToken.payload.file_uploads_access_only
              ? '/upload'
              : '/dashboard'
            : '/sign-in',
          search: isAuthenticated ? undefined : `next=${path}`,
        }}
      />
    );
  },
);

export default PrivateRoute;
