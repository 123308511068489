import { put, takeEvery } from 'redux-saga/effects';
import { notificationActions } from 'store/actions';
import { NotificationType } from 'store/actions/notification';

export interface NotificationSideEffect {
  message: string;
  level: NotificationType;
  messageArgs?: any;
}

interface ActionWithSideEffect {
  type: string;
  payload?: any;
  error: string | { message: string };
  meta: {
    notification: NotificationSideEffect;
    optimistic?: boolean;
  };
}

/**
 * Notification Side Effects
 */
function* handleNotification({
  error,
  meta: { notification, optimistic },
}: ActionWithSideEffect) {
  const { message, level, messageArgs = {} } = notification;
  if (error) {
    // @ts-ignore
    return yield put(
      notificationActions.showNotification(
        typeof error === 'string' ? error : error.message || message,
        level || 'warning',
        {
          undoable: false,
          messageArgs,
        },
      ),
    );
  }
  yield put(
    notificationActions.showNotification(message, level || 'info', {
      undoable: optimistic,
      messageArgs,
    }),
  );
}

function* notificationsSideEffect() {
  yield takeEvery(
    (action: any) => action.meta && action.meta.notification,
    handleNotification,
  );
}

export default notificationsSideEffect;
