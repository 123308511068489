import { useEffect, useRef } from 'react';

const useRunOnce = (functionToRun: () => void) => {
  const once = useRef<boolean>(false);

  useEffect(() => {
    if (functionToRun && !once.current) {
      once.current = true;
      functionToRun();
    }
  }, [functionToRun, once]);
};

export default useRunOnce;
