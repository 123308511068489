import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Navigation } from 'types';

interface UseNavigationProps {
  baseNavigation: Navigation;
}

const useNavigation = ({ baseNavigation }: UseNavigationProps) => {
  const oldInitialValue = useRef<Navigation>(baseNavigation);
  const [navGroups, setNavGroups] = useState<Array<Navigation>>([
    baseNavigation,
  ]);

  const isNestedNavigation = useMemo(() => {
    return navGroups.length > 1;
  }, [navGroups]);

  useEffect(() => {
    if (
      !isNestedNavigation &&
      !isEmpty(navGroups) &&
      !isEqual(baseNavigation, navGroups[0]) &&
      !isEqual(baseNavigation, oldInitialValue.current)
    ) {
      setNavGroups([baseNavigation]);
      oldInitialValue.current = baseNavigation;
    }
  }, [baseNavigation, navGroups, oldInitialValue, isNestedNavigation]);

  const push = useCallback(
    (navProps: Navigation) => {
      setNavGroups((prevNavs) => {
        return [navProps, ...prevNavs];
      });
    },
    [setNavGroups],
  );

  const pop = useCallback(() => {
    setNavGroups((prevNavGroups) => {
      if (prevNavGroups.length > 1) {
        return prevNavGroups.slice(1);
      }
      return prevNavGroups;
    });
  }, [setNavGroups]);

  const navProps = useMemo(() => {
    return navGroups[0];
  }, [navGroups]);

  return {
    push,
    pop,
    navProps,
    isNestedNavigation,
  };
};

export default useNavigation;
