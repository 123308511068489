import { Filter, Options as ReportOptions, Slice } from 'flexmonster';

export interface BaseItem extends Record<string, any> {
  id: string | number;
}

export interface JWTPayload {
  token_type: string;
  exp: number;
  jti: string;
  user_id: number;
  is_org_creator: boolean;
  is_org_admin: boolean;
  is_group_admin: boolean;
  is_workflow_admin: boolean;
  is_superuser: boolean;
  is_staff: boolean;
  are_workflows_allowed: boolean;
  obligation_tracking: boolean;
  is_mfa_enabled: boolean;
  file_uploads_access_only: boolean;
  is_sso_only: boolean;
  permissions: string[];
  groups: number[];
  organization?: number;
  features: string[];
  horizon_scanning?: boolean;
  // Temporary fix to support older tokens, to be removed after 38 days
  horizontal_scanning?: boolean;
}

export interface JWTHeader {
  typ: string;
  alg: string;
}

export interface JWTToken {
  header: JWTHeader;
  payload: JWTPayload;
}

export interface Metadata extends BaseItem {
  id: string;
  guid: string;
  document_level: number | null;
  friendly_name: string | null;
  has_text: boolean | null;
  has_title: boolean | null;
  industry: Array<string>;
  level: number;
  meta: string;
  pad: number;
  title: Array<string>;
  tags: Array<string>;
  label: string | null;
  workflow_theme: Array<string>;
  publication_date: string | null;
  commencement_date: string | null;
  government_department: Array<string>;
}

export interface NodeText extends BaseItem {
  document_id: string;
  key: string;
  text: string[];
  successors: string[];
  predecessors: string[];
  burden_class: string;
  has_obligation: boolean;
  level: number;
  meta: string;
  burden_subclass: null | string;
  id: string;
  text_tagged: null | string;
  text_tagged_high_level: null | string;
}

export interface NodeTextWithTasks extends NodeText {
  tasks?: TaskInstance[];
}

export interface DocStructItem {
  id: string;
  label: string;
  sublevels: DocStructItem[];
}

export interface Tag extends BaseItem {
  tag: string;
}

export interface Theme extends BaseItem {
  theme: string;
}

export interface OperationalData extends BaseItem {
  id: number;
  document_name: string;
  doc_types: Array<DocumentType>;
  date_added: string;
  published: string;
  commencement_date: string;
  url: string | null;
  data_source: DataSource | null;
  regulator: string | null;
  signed_original_doc_url: string | null;
  signed_current_doc_url: string | null;
  signed_unenriched_doc_url: string | null;
  issuer: string | null;
  location: string | null;
  nodetext_set: Array<NodeText>;
  burden: BurdenClass[];
  document_structure: DocStructItem[];
  tags: Array<Tag | string | number>;
  themes: Array<Theme | string | number>;
  industry?: Array<Industry>;
}

export interface RelatedDocuments extends BaseItem {
  document_name: string;
  related_op_data_docs: (OperationalData | number)[];
}

interface DatePeriod {
  lte?: string;
  gte?: string;
}

interface NumberRange {
  lte?: number;
  gte?: number;
}

export interface SearchCriteria extends Record<string, any> {
  data_sources?: Array<string>;
  tags?: Array<string>;
  themes?: Array<string>;
  industries?: Array<string>;
  regulators?: Array<string>;
  government_departments?: Array<string>;
  locations?: Array<string>;
  document_types?: Array<string>;
  publication_date?: DatePeriod;
  commencement_date?: DatePeriod;
  fine_amount?: NumberRange;
}

export interface HumanReadableQuery extends Record<string, any> {
  data_sources?: Array<string>;
  tags?: Array<string>;
  themes?: Array<string>;
  industries?: Array<string>;
  regulators?: Array<string>;
  government_departments?: Array<string>;
  locations?: Array<string>;
  document_types?: Array<string>;
  publication_date?: string;
  commencement_date?: string;
  fine_amount?: string;
}

export interface SavedSearchRequest {
  query: Record<string, any>;
  search_criteria: SearchCriteria;
  human_readable_query: HumanReadableQuery;
}

export enum AlertFrequency {
  None = 'NONE',
  Immediately = 'IMMEDIATELY',
  Hourly = 'HOURLY',
  Every3Hour = '3HOURLY',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
}

export enum AlertMethod {
  NotApplicable = 'NOTAPPLICABLE',
  OpenFin = 'OPENFIN',
  Email = 'EMAIL',
  WebHook = 'WEBHOOK',
}

export interface SavedSearch extends BaseItem {
  id: number;
  title: string;
  request: SavedSearchRequest;
  alert_frequency: AlertFrequency;
  alert_method: AlertMethod;
  number_of_results: number;
  workflow: Workflow;
  webhook: Webhook;
  user?: User;
  created_at: string;
}

export interface Folder extends BaseItem {
  id: number;
  user?: User;
  name: string;
  documents: OperationalData[];
  workflow: Workflow;
  webhook: Webhook;
  groups: Group[];
  created_at: string;
}

export interface Country extends BaseItem {
  id: number;
  code: string;
  name: string;
  unicode_flag?: string;
  alpha3?: string;
  numeric?: number;
  ioc_code?: string;
}

export interface Group extends BaseItem {
  id: number;
  name: string;
  organization_id: number | string;
  parent?: Group;
  number_of_users: number;
  number_of_child_groups: number;
}

export interface DetailGroup extends Group {
  organization: Organisation;
  parent_id?: number;
  users: Array<Member>;
  children: Array<Group>;
}

export interface ShortUser extends BaseItem {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

export interface User extends ShortUser {
  organization_id?: number;
  groups: Array<Group>;
  is_group_admin: boolean;
  is_org_admin: boolean;
  is_workflow_admin: boolean;
  last_login: null | string;
}

export type Role = 'member' | 'group_admin';

export interface Member extends ShortUser {
  role?: Role;
  user?: string;
  is_group_admin: boolean;
  is_org_admin: boolean;
  is_workflow_admin: boolean;
  last_login: null | string;
}

export interface License {
  id: number;
  license_tier: string;
  number_of_data_sources: number;
}

export interface Organisation extends BaseItem {
  id: number;
  name: string;
  is_active: true;
  groups: Array<Group>;
  owners_ids: Array<number>;
  number_of_users: number;
  license: License;
  are_workflows_allowed: boolean;
  license_start_date: string;
  license_end_date: string;
  license_renewal_date: string;
  data_sources: Array<DataSource>;
  obligation_tracking: boolean;
  horizon_scanning: boolean;
  permitted_email_suffixes: Array<string>;
  registration_date: string;
  owners: Array<User>;
  features: string[];
  s3_bucket: string;
}

export interface Webhook extends BaseItem {
  name: string;
  url: string;
  created_by: User;
}

export interface DataSource extends BaseItem {
  id: number;
  name: string;
  codename: string;
  url: string;
}

export interface DocumentType extends BaseItem {
  id: number;
  doc_type_high_level: string;
  doc_type: string;
}

export interface BurdenClass extends BaseItem {
  name: string;
  count?: number;
  hidden?: boolean;
  burden_subclasses?: BurdenClass[];
}

export interface Industry extends BaseItem {
  industry: string;
}

export interface Tag extends BaseItem {
  tag: string;
}

export interface Theme extends BaseItem {
  theme: string;
}

export interface Topic extends BaseItem {
  text: string;
}

export interface Regulator extends BaseItem {
  regulator: string;
}

export interface GovernmentDepartment extends BaseItem {
  government_department: string;
}

export interface SourceGovernmentDepartment extends BaseItem {
  government_department: string;
  ground_truth: number;
}

export interface GeographicalExtent extends BaseItem {
  geographical_extent: string;
}

export interface Cf extends BaseItem {
  cf: string;
}

export interface Smf extends BaseItem {
  smf: string;
}

export interface Simf extends BaseItem {
  simf: string;
}

export interface FileUpload extends BaseItem {
  file: File;
  file_name: string;
  title: string;
  description?: string;
  commencement_date?: Date;
  publication_date?: Date;
  regulator?: string;
  industry_type?: string;
  range_pages?: string;
  document_types?: [number];
}

export type SetDatePeriod = 'before' | 'after';
export type SetType =
  | 'publication_date'
  | 'effective_date'
  | 'submission_date'
  | 'completion';
export type TaskFieldType = 'text_field' | 'url_field' | 'dropdown';
export type RequirenceType = 'mandatory' | 'optional';

export interface TaskResponse {
  field_type: TaskFieldType;
  requirence_type: RequirenceType;
}

export type AnchorDateType =
  | 'publication_date'
  | 'effective_date'
  | 'consultation_close_date'
  | 'previous_task_completion';
export type AnchorDirectionType = 'before' | 'after';

export interface DueDateRule extends BaseItem {
  day_offset: number;
  anchor_date: AnchorDateType;
  anchor_direction: AnchorDirectionType;
}

export type TaskType = 'document' | 'obligation';
export const OBLIGATION_TASK = 'obligation';
export const DOCUMENT_TASK = 'document';

export interface Task extends BaseItem {
  sequence_number: number;
  task_name: string;
  task_details: string;
  task_type: TaskType;
  assignee: User;
  folder?: Folder;
  due_date_rule: DueDateRule;
  task_fields: TaskField[];
}

export interface TaskInstanceWorkflow extends BaseItem {
  document_id: number;
  document_name: string;
  name: string;
}

export interface ResponseOption extends BaseItem {
  name: string;
  does_response_end_workflow: boolean;
}

export const TASK_STATUS_IN_QUEUE = 'in_queue';
export const TASK_STATUS_COMPLETE = 'complete';
export const TASK_STATUS_CLOSED = 'closed';

export const TASK_STATUS_HUMAN_READABLE_LABEL_MAP = {
  [TASK_STATUS_IN_QUEUE]: 'In Queue',
  [TASK_STATUS_COMPLETE]: 'Completed',
  [TASK_STATUS_CLOSED]: 'Closed',
};

export const TEXT_FIELD = 'text_field';
export const URL_FIELD = 'url_field';
export const FOLDER_FIELD = 'folder_field';
export const DROPDOWN_FIELD = 'dropdown_field';

export const TASK_FIELD_TYPE: Record<string, string> = {
  [TEXT_FIELD]: 'Text Field',
  [URL_FIELD]: 'URL Field',
  [DROPDOWN_FIELD]: 'Dropdown Field',
  [FOLDER_FIELD]: 'Folder Field',
};

export const TASK_FIELD_TYPE_CHOICES = [
  {
    id: TEXT_FIELD,
    value: TASK_FIELD_TYPE[TEXT_FIELD],
  },
  {
    id: URL_FIELD,
    value: TASK_FIELD_TYPE[URL_FIELD],
  },
  {
    id: DROPDOWN_FIELD,
    value: TASK_FIELD_TYPE[DROPDOWN_FIELD],
  },
  {
    id: FOLDER_FIELD,
    value: TASK_FIELD_TYPE[FOLDER_FIELD],
  },
];

export const TASK_STATUS_CHOICES = [
  {
    id: TASK_STATUS_IN_QUEUE,
    name: TASK_STATUS_HUMAN_READABLE_LABEL_MAP[TASK_STATUS_IN_QUEUE],
  },
  {
    id: TASK_STATUS_COMPLETE,
    name: TASK_STATUS_HUMAN_READABLE_LABEL_MAP[TASK_STATUS_COMPLETE],
  },
  {
    id: TASK_STATUS_CLOSED,
    name: TASK_STATUS_HUMAN_READABLE_LABEL_MAP[TASK_STATUS_CLOSED],
  },
];

export interface TaskField {
  id: number;
  type: string;
  mandatory: boolean;
  question: string;
  response_options: ResponseOption[];
  sequence_number: number;
  slug: string;
}

export interface TaskAnswer {
  response_options: ResponseOption[];
  response_folders: Folder[];
  response: string;
  task_field?: TaskField;
}

export interface TaskInstance extends BaseItem {
  name: string;
  status: 'in_queue' | 'complete' | 'closed';
  assignee: ShortUser;
  completed_by: ShortUser | null;
  created_date: string;
  due_date: string;
  obligation_id: string | null;
  completion_date: string;
  is_enabled: boolean;
  is_obligation: boolean;
  workflow: TaskInstanceWorkflow;
  task_fields: TaskField[];
  task_answer: TaskAnswer;
}

export interface Workflow extends BaseItem {
  id: number;
  name: string;
  description: string;
  allow_email_notifications: boolean;
  organization: Organisation;
  organization_id?: number | string;
  created_by: number;
  published: boolean;
  tasks: Array<Task>;
  has_attached_documents?: boolean;
}

type Level = 'success' | 'info' | 'warning' | 'error';

export interface Notification extends BaseItem {
  id: string;
  level: Level;
  unread: boolean;
  verb: string;
  description: string;
  timestamp: string;
  data: Record<string, any>;
  recipient: User;
  actor: SavedSearch;
  target: string | null;
  action_object: string | null;
}

export interface BreachPrimary extends BaseItem {
  breach_primary: string;
}

export interface BreachSecondary extends BaseItem {
  breach_secondary: string;
}

export interface BreachSupporting extends BaseItem {
  breach_supporting: string;
}

export interface BreachObserved extends BaseItem {
  breach_observed: string;
}

export interface RuleBreach extends BaseItem {
  rule_id: string | number;
  source_document: string | number;
  rule_name: string;
  rule_level: number;
}

export interface RecipientType extends BaseItem {
  recipient_type: string;
}

export interface Sanction extends BaseItem {
  sanction: string;
}

export interface Mapping {
  query: string;
  ids: number[];
  scores: number[];
  texts: string[];
}

export interface PolicyMapping extends BaseItem {
  'document-name': string;
  level: number;
  mapping: Mapping[];
  missing: string[];
}

export interface RegulatoryCalendar extends BaseItem {
  date: string;
  document_name: string;
  tags: Array<string>;
  themes: Array<Theme | string | number>;
  data_source: DataSource | null;
  date_added: string;
  doc_types: Array<DocumentType>;
}

export interface DocumentBundle extends BaseItem {
  name: string;
  description: string;
  documents: OperationalData[];
}

export interface CreateOrEditDocumentBundle extends BaseItem {
  name: string;
  description: string;
  documents: number[];
}

export type DocumentCounterType = 'theme' | 'tag' | 'topic';
export interface DocumentCounter extends BaseItem {
  name: string;
  type: DocumentCounterType;
  count: number;
  object_id: string;
}

export const ASYNC_TYPE_REPORT = 'async';

export interface DataSourceParser extends BaseItem {
  code_name: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface Filters {
  fields: string;
  operator: string;
  value: string;
}

export type ConditionLogicType = 'all' | 'any';

export interface PivotFilters {
  condition_logic?: ConditionLogicType;
  pivot_filters?: Filters[];
  organization?: number;
}

export type PivotTemplateKind = 'pivot' | 'workflow';

export interface RowFilterAndSort {
  uniqueName: string;
  filters?: Filter;
  sort?: string;
}

export interface Options extends ReportOptions {
  slice: Slice;
}

export interface PivotTableTemplate extends BaseItem {
  name: string;
  options: Options;
  filters: PivotFilters;
  kind: PivotTemplateKind;
}

export type PivotTemplateWithoutName = Omit<PivotTableTemplate, 'name'>;

export type PivotTableTemplateHandlers = (
  newPivotTemplate: PivotTableTemplate,
) => void;

export type PivotTemplateStateHandlers = (value: boolean) => void;

export interface DocumentComment extends BaseItem {
  node_id: string;
  created_at: string;
  comment_text: string;
  created_by: User;
}

export interface NodesEmail extends BaseItem {
  email: string;
}

export interface Comments extends BaseItem {
  comment_text: string;
  created_by: User;
  updated_at: string | Date;
}

export interface Content {
  selector: string;
  offset: number;
  childNodesIndex: number;
  content: string;
  nestedElementId?: string;
  dependsElement?: string[];
}

export interface Note extends BaseItem {
  operational_data: string;
  comments?: Comments[];
  content: string;
  comment_text?: string;
  start_content: Content | null;
  end_content: Content | null;
  node_id: string | null;
  version: number;
  color: string;
}

export interface ShortNote extends BaseItem {
  content: string;
  start_content: Content;
  end_content: Content;
  comments?: Comments[];
  color?: string;
}

export const DOCUMENT_NOTES = 'document_notes';
export const CSV_DOWNLOAD = 'csv_download';
export const ORGANIZATION_LOGS = 'organization_logs';
export const DOCUMENT_COMPARISON = 'document_comparison';

export const ORGANIZATION_FEATURES_TYPE: Record<string, string> = {
  [DOCUMENT_NOTES]: 'Document Notes',
  [CSV_DOWNLOAD]: 'CSV Download',
  [ORGANIZATION_LOGS]: 'Organisation Logs',
  [DOCUMENT_COMPARISON]: 'Document Comparison',
};

export const ORGANIZATION_FEATURES_TYPE_CHOICES = [
  {
    id: DOCUMENT_NOTES,
    name: ORGANIZATION_FEATURES_TYPE[DOCUMENT_NOTES],
  },
  {
    id: CSV_DOWNLOAD,
    name: ORGANIZATION_FEATURES_TYPE[CSV_DOWNLOAD],
  },
  {
    id: ORGANIZATION_LOGS,
    name: ORGANIZATION_FEATURES_TYPE[ORGANIZATION_LOGS],
  },
  {
    id: DOCUMENT_COMPARISON,
    name: ORGANIZATION_FEATURES_TYPE[DOCUMENT_COMPARISON],
  },
];

export type Changes =
  | 'created'
  | 'changed_group'
  | 'changed_first_name'
  | 'changed_last_name'
  | 'changed_email'
  | 'changed_role'
  | 'removed_group'
  | 'removed_role'
  | 'removed_user';

export const CHANGES_VALUE = {
  created: 'User created',
  changed_group: 'Changed group',
  changed_first_name: 'Changed first name',
  changed_last_name: 'Changed last name',
  changed_email: 'Changed email',
  changed_role: 'Changed role',
  removed_group: 'Removed group',
  removed_role: 'Removed role',
  removed_user: 'Removed user',
};

export interface OrganizationLog extends BaseItem {
  created_at: string;
  old_value: string;
  new_value: string;
  changes: Changes;
  changed_by: User | null;
  user: User | null;
}

export interface S3Bucket extends BaseItem {
  bucket: string;
}

export interface BackendStatus extends BaseItem {
  status: boolean;
}
