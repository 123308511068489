import { ReactNode } from 'react';
import { connectModal, InjectedProps } from 'redux-modal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { modals } from 'contstants';

export interface DndListDialogProps extends InjectedProps {
  confirm: () => void;
  title?: string;
  content?: ReactNode;
}

const DialogContainer = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

const DialogContentContainer = styled(DialogContent)({
  textAlign: 'start',
});

const DialogTitleContent = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1, 2),
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'capitalize',
  fontSize: '24px !important',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const DndListDialog = ({
  show,
  handleHide,
  content,
  title = 'Related content editor',
}: DndListDialogProps) => (
  <DialogContainer fullScreen open={show} onClose={handleHide}>
    <DialogTitleContent>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Title variant="h5">{title}</Title>
        </Grid>
        <Grid item>
          <CloseButton onClick={handleHide}>
            <CloseIcon />
          </CloseButton>
        </Grid>
      </Grid>
    </DialogTitleContent>
    <DialogContentContainer>{content}</DialogContentContainer>
  </DialogContainer>
);

export default connectModal({
  name: modals.DND_LIST_DIALOG,
  destroyOnHide: true,
})(DndListDialog);
