import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Topic } from 'types';

export interface TopicPayload extends PageQuery {
  id?: number;
  text?: string;
}

const list = (query: TopicPayload, token: string) =>
  request<Empty, PageResponse<Topic>, Empty>(
    `wm-objects/topic/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const topicsServices = {
  list,
};

export default topicsServices;
