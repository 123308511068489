import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { SourceGovernmentDepartment } from 'types';

export interface SourceGovernmentDepartmentPayload extends PageQuery {
  government_department?: string;
  id?: number;
}

const list = (query: SourceGovernmentDepartmentPayload, token: string) =>
  request<Empty, PageResponse<SourceGovernmentDepartment>, Empty>(
    `wm-objects/source-government-department/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const sourceGovernmentDepartmentsServices = {
  list,
};

export default sourceGovernmentDepartmentsServices;
