import { call, put, race, select, take, delay, fork } from 'redux-saga/effects';
import { replace, push } from 'connected-react-router';

import { authActions, crudActions, SimpleAction } from 'store/actions';
import HttpError from 'services/HttpError';
import { authSelectors } from 'store/selectors';
import { authService } from 'services';
import { JWTToken } from 'types';
import config from 'config';

function* jwtExpiration() {
  let auth: JWTToken = yield select(authSelectors.jwtToken);
  return auth.payload.exp * 1000;
}

function* waitForTokenTimeout() {
  const refreshThresholdMilliseconds =
    config.tokenRefreshThresholdMinutes * 60 * 1000;
  const expiration = yield* jwtExpiration();

  while (expiration - new Date().getTime() > refreshThresholdMilliseconds) {
    yield delay(
      expiration - new Date().getTime() - refreshThresholdMilliseconds + 1000,
    );
  }
}

export function* tokenAlive() {
  let exit = false;
  while (!exit) {
    const { failure } = yield race({
      success: call(waitForTokenTimeout),
      failure: take(authActions.logout.trigger().type),
    });
    if (failure) {
      exit = true;
      continue;
    }
    yield call(refresh);
  }
}

export function* userDetailGet() {
  yield put(authActions.userDetailGet.request());
  try {
    const accessToken: string = yield select(authSelectors.accessToken);
    const { response, error } = yield call(authService.detailGet, accessToken);
    if (error) {
      yield put(replace('/sign-in'));
      return;
    }
    yield put(authActions.userDetailGet.success(response.data));
  } catch (error) {
    if (error instanceof HttpError) {
      if (error.status === 403 || error.status === 401) {
        if (!((yield call(refresh)) as boolean)) {
          throw error;
        }
      }
    }
    yield put(authActions.userDetailGet.failure(error));
  }
}

export function* activateUserDetail(
  action: SimpleAction<{
    static_codes?: string[];
  }>,
) {
  try {
    const { payload } = action;
    const accessToken: string = yield select(authSelectors.accessToken);
    const { response, error } = yield call(authService.detailGet, accessToken);
    const auth: JWTToken = yield select(authSelectors.jwtToken);

    if (error) {
      yield put(replace('/sign-in'));
      return;
    }
    yield put(authActions.userDetailGet.success(response.data));
    let redirectUrl = '/dashboard';

    if (auth.payload.file_uploads_access_only) {
      redirectUrl = '/upload';
    }

    if (payload.static_codes) {
      yield put(
        push('/static-codes', {
          staticCodes: payload.static_codes,
          redirectUrl,
        }),
      );
      return;
    }
  } catch (error) {
    yield put(authActions.userDetailGet.failure(error));
  }
}

export function* refresh() {
  const isAuthenticated: boolean = yield select(authSelectors.isAuthenticated);

  if (!isAuthenticated) return false;

  const refreshToken: string = yield select(authSelectors.refreshToken);
  const accessToken: JWTToken = yield select(authSelectors.jwtToken);

  // TODO: we can remove the is_sso_only check after existing SSO only refresh tokens have expired
  if (refreshToken == null || accessToken.payload.is_sso_only) {
    yield put(authActions.logoutToAuth0.trigger());
    return false;
  }

  const isRefreshing: boolean = yield select(authSelectors.isRefreshing);
  if (isRefreshing) {
    const { success } = yield race({
      success: take(authActions.signIn.success().type),
      failure: take(authActions.signIn.failure().type),
    });

    return !!success;
  }

  try {
    yield put(authActions.refresh.trigger());
    const { response, error } = yield call(authService.tokenRefresh, {
      refresh: refreshToken,
    });
    if (error) {
      yield put(authActions.logout.trigger());
      return false;
    }
    const { access, refresh } = response.data;
    yield put(authActions.signIn.success({ access, refresh }));
    return true;
  } catch (error) {
    yield put(authActions.logout.trigger());
    yield put(authActions.signIn.failure(error));
  }
}

export function* signInWithAuth0(
  action: SimpleAction<{
    token: string;
  }>,
) {
  const { payload } = action;
  const { ...submitData } = payload;
  try {
    const {
      response: { data },
    } = yield call(authService.signInWithAuth0, submitData);
    yield put(authActions.signIn.success(data));
    const auth: JWTToken = yield select(authSelectors.jwtToken);
    const finalRedirectUri = auth.payload.file_uploads_access_only
      ? 'upload'
      : '/dashboard';
    yield put(push(finalRedirectUri));
    yield fork(tokenAlive);
  } catch (error) {
    if ((error as HttpError).body) {
      yield put(
        authActions.signIn.failure({
          ...(error as HttpError).body,
          _error:
            (error as HttpError).body.detail ||
            (error as HttpError).body.status,
        }),
      );
      return;
    }
    yield put(authActions.signIn.failure({ _error: (error as Error).message }));
  }
}

export function* userAuth(
  action: SimpleAction<{
    email: string;
    password: string;
    redirectUrl?: string;
  }>,
) {
  const { payload } = action;
  const { redirectUrl, ...submitData } = payload;
  try {
    const {
      response: { data },
    } = yield call(authService.signIn, submitData);
    yield put(authActions.signIn.success(data));
    const { static_codes: staticCodes } = data;
    const auth: JWTToken = yield select(authSelectors.jwtToken);
    const finalRedirectUri = auth.payload.file_uploads_access_only
      ? 'upload'
      : redirectUrl || '/dashboard';
    if (staticCodes) {
      yield put(
        push('/static-codes', { staticCodes, redirectUrl: finalRedirectUri }),
      );
      return;
    }
    yield put(push(finalRedirectUri));
    yield fork(tokenAlive);
  } catch (error) {
    if ((error as HttpError).body) {
      const body = (error as HttpError).body;
      if (body.mfa_required) {
        yield put(
          replace(`/mfa-registration`, {
            code: body.mfa_required.otp_code,
            redirectUrl,
            ...payload,
          }),
        );
      }
      if (body.totp_code_required) {
        yield put(
          replace(`/mfa-sign-in`, {
            redirectUrl,
            ...payload,
          }),
        );
      }
    }
    if ((error as HttpError).body) {
      yield put(
        authActions.signIn.failure({
          ...(error as HttpError).body,
          _error:
            (error as HttpError).body.detail ||
            (error as HttpError).body.status,
        }),
      );
      return;
    }
    yield put(authActions.signIn.failure({ _error: (error as Error).message }));
  }
}

export function* logoutToAuth0() {
  yield put(replace('/auth0'));
  yield put(crudActions.cleanup.trigger());
}

export function* logout() {
  yield put(replace('/sign-in'));
  yield put(crudActions.cleanup.trigger());
}
