import { useCallback, isValidElement, cloneElement, ReactElement } from 'react';
import get from 'lodash/get';

export type OptionTextElement = ReactElement<{
  record: Record<string, any>;
}>;
export type OptionText = (
  choice: Record<string, any>,
) => string | OptionTextElement;

interface UseChoicesOptions {
  optionValue?: string;
  optionText?: OptionTextElement | OptionText | string;
  disableValue?: string;
  defaultValue?: string;
}

const useChoices = ({
  optionText = 'name',
  optionValue = 'id',
  disableValue = 'disabled',
  defaultValue = 'N/A',
}: UseChoicesOptions) => {
  const getChoiceText = useCallback(
    (choice: unknown) => {
      if (isValidElement<{ record: any }>(optionText)) {
        return cloneElement<{ record: any }>(optionText, {
          record: choice,
        });
      }
      return typeof optionText === 'function'
        ? optionText(choice as Record<string, unknown>)
        : get(choice, optionText, defaultValue);
    },
    [optionText, defaultValue],
  );

  const getChoiceValue = useCallback(
    (choice: unknown) => get(choice, optionValue, ''),
    [optionValue],
  );

  const getDisableValue = useCallback(
    (choice: unknown) => get(choice, disableValue),
    [disableValue],
  );

  return {
    getChoiceText,
    getChoiceValue,
    getDisableValue,
  };
};

export default useChoices;
