import React from 'react';
import {
  UseTableHeaderGroupProps,
  TableHeaderProps as ReactTableHeaderProps,
} from 'react-table';
import { styled } from '@mui/material/styles';
import Grid from 'components/Grid';

import TableHeaderCell, { TableHeaderCellProps } from './TableHeaderCell';

const HeaderGroupGrid = styled(Grid)(({ theme }) => ({
  color: theme.palette.warning.contrastText,
  padding: theme.spacing(1, 1.125),
  backgroundColor: theme.palette.common.white,
}));

export interface TableHeaderGroupProps extends ReactTableHeaderProps {
  headerGroup: UseTableHeaderGroupProps<any>;
  align: TableHeaderCellProps['align'];
}

const TableHeaderGroup = ({
  align,
  headerGroup,
  ...headerGroupProps
}: TableHeaderGroupProps) => (
  <HeaderGroupGrid item container wrap="nowrap" {...headerGroupProps}>
    {headerGroup.headers.map((column: any) => (
      <TableHeaderCell
        align={align}
        column={column}
        {...column.getHeaderProps(
          column.canSort
            ? column.getSortByToggleProps({
                title: `Sort by ${column.render('Header')}`,
              })
            : undefined,
        )}
      />
    ))}
  </HeaderGroupGrid>
);

export default TableHeaderGroup;
