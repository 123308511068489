const arrayRange = (start: number, end: number, step: number = 1) => {
  function* rangeGen(start: number, end: number, step: number) {
    while (start < end) {
      yield start;
      start += step;
    }
  }
  return Array.from(rangeGen(start, end, step));
};

export default arrayRange;
