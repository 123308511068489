import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import isFunction from 'lodash/isFunction';
import { routerSelectors } from 'store/selectors';

type RedirectToFunction = (
  type: string,
  payload?: any,
  resource?: string,
  location?: string,
  response?: any,
) => string;

export type RedirectionSideEffect = string | RedirectToFunction;

interface ActionWithSideEffect {
  type: string;
  payload?: any;
  response?: any;
  meta: {
    redirectTo: RedirectionSideEffect;
    relatedRedirect: boolean;
    resource?: string;
  };
}

/**
 * Redirection Side Effects
 */
export function* handleRedirection({
  type,
  payload,
  response,
  meta: { resource, redirectTo, relatedRedirect },
}: ActionWithSideEffect) {
  const location: Record<string, any> = yield select(routerSelectors.location);
  if (isFunction(redirectTo)) {
    yield put(
      push(redirectTo(type, payload, resource, location.pathname, response)),
    );
    return;
  }
  if (relatedRedirect) {
    const redirectTo =
      location.pathname[location.pathname.length - 1] === '/'
        ? location.pathname.split('/').slice(0, -2).join('/')
        : location.pathname.split('/').slice(0, -1).join('/');
    yield put(push(redirectTo));
  }
  yield put(push(redirectTo));
}

function* redirectionSideEffect() {
  yield takeEvery(
    (action: any) =>
      action.meta &&
      (action.meta.redirectTo !== undefined ||
        action.meta.relatedRedirect !== undefined),
    handleRedirection,
  );
}

export default redirectionSideEffect;
