import { ReducerState } from 'store/reducers';

const burdenClassesState = (state: ReducerState) => state.burdenClasses;

const isLoading = (state: ReducerState) =>
  burdenClassesState(state).get('isLoading');
const results = (state: ReducerState) =>
  burdenClassesState(state).get('results');

const burdenClassesSelectors = {
  isLoading,
  results,
};

export default burdenClassesSelectors;
