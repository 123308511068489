import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { RecipientType } from 'types';

export interface CountryPayload extends PageQuery {
  id: number;
  recipient_type?: string;
}

const list = (query: CountryPayload, token: string) =>
  request<Empty, PageResponse<RecipientType>, Empty>(
    `wm-objects/recipient-type/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const recipientTypeServices = {
  list,
};

export default recipientTypeServices;
