import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { BreachObserved } from 'types';

export interface BreachObservedPayload extends PageQuery {
  breach_observed?: string;
  id: number;
}

const list = (query: BreachObservedPayload, token: string) =>
  request<Empty, PageResponse<BreachObserved>, Empty>(
    `wm-objects/breach-observed/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const breachesObservedServices = {
  list,
};

export default breachesObservedServices;
