import {
  ReactNode,
  ComponentType,
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LinkProps } from 'react-router-dom';
import { StyledComponent } from '@mui/styles';
import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { styled, Theme } from '@mui/material/styles';
import { Color } from 'types';

export interface TypographyProps<Props = unknown>
  extends Omit<MuiTypographyProps, 'color' | 'title'> {
  bottomSpacing?: number;
  topSpacing?: number;
  leftSpacing?: number;
  rightSpacing?: number;
  children: ReactNode;
  fullWidth?: boolean;
  color?: Color | 'text' | 'textSecondary' | 'white';
  display?: 'flex' | 'block' | 'inline';
  component?: ComponentType<Props> | ReactNode;
  title?: boolean;
  removeTextDecoration?: boolean;
  weight?: 'bold' | 'normal' | 'medium' | 'default';
  to?: LinkProps['to'];
  rel?: string;
  target?: string;
  label?: string;
}

export const sanitizeTypographyProps = ({
  title,
  weight,
  bottomSpacing,
  topSpacing,
  leftSpacing,
  rightSpacing,
  color = 'textSecondary',
  flex,
  display,
  variant,
  component,
  align,
  gutterBottom,
  paragraph,
  noWrap,
  rel,
  target,
  padding,
}: Record<string, any>) => ({
  rel,
  target,
  title,
  weight,
  bottomSpacing,
  topSpacing,
  leftSpacing,
  rightSpacing,
  color,
  flex,
  display,
  variant,
  component,
  align,
  gutterBottom,
  paragraph,
  noWrap,
  padding,
});

const Typography = styled(MuiTypography, {
  shouldForwardProp: (propName: string) => {
    return (
      [
        'removeTextDecoration',
        'bottomSpacing',
        'topSpacing',
        'leftSpacing',
        'rightSpacing',
        'display',
        'color',
        'weight',
        'title',
        'fullWidth',
      ].indexOf(propName) === -1
    );
  },
})<TypographyProps>(
  ({
    fullWidth,
    theme,
    bottomSpacing,
    topSpacing,
    leftSpacing,
    rightSpacing,
    title,
    noWrap,
    weight = 'default',
    removeTextDecoration,
    display = 'block',
    color = 'secondaryInfo',
  }: TypographyProps & { theme: Theme }) => ({
    ...(fullWidth && { width: '100%' }),
    ...(topSpacing && { paddingTop: theme.spacing(topSpacing) }),
    ...(bottomSpacing && { paddingBottom: theme.spacing(bottomSpacing) }),
    ...(leftSpacing && { paddingLeft: theme.spacing(leftSpacing) }),
    ...(rightSpacing && { paddingRight: theme.spacing(rightSpacing) }),
    ...(display && {
      display: display,
    }),
    ...(!noWrap && {
      whiteSpace: 'break-spaces',
    }),
    ...(removeTextDecoration && {
      textDecoration: 'none',
    }),
    ...(color === 'text' && {
      color: theme.palette.text.primary,
    }),
    ...(color === 'textSecondary' && {
      color: theme.palette.common.black,
    }),
    ...(color === 'primary' && {
      color: theme.palette.primary.main,
    }),
    ...(color === 'info' && {
      color: theme.palette.info.main,
    }),
    ...(color === 'success' && {
      color: theme.palette.success.main,
    }),
    ...(color === 'secondary' && {
      color: theme.palette.secondary.main,
    }),
    ...(color === 'warning' && {
      color: theme.palette.warning.main,
    }),
    ...(color === 'secondaryInfo' && {
      color: theme.palette.warning.contrastText,
    }),
    ...(color === 'error' && {
      color: theme.palette.error.main,
    }),
    ...(color === 'white' && {
      color: theme.palette.common.white,
    }),
    ...(title === true && {
      fontSize: theme.typography.h4.fontSize,
    }),
    ...(weight === 'bold' && {
      fontWeight: 600,
    }),
    ...(weight === 'medium' && {
      fontWeight: 500,
    }),
    ...(weight === 'normal' && {
      fontWeight: 400,
    }),
  }),
) as StyledComponent<TypographyProps>;

const WrappedTypography = forwardRef<HTMLElement, TypographyProps>(
  (
    { children, label, ...props }: TypographyProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const typographyRef = useRef<HTMLElement>(null);
    const [title, setTitle] = useState(label || '');

    useEffect(() => {
      if (label && title !== label) {
        setTitle(label);
      }

      if (!label) {
        if (
          typographyRef.current &&
          typeof children === 'string' &&
          (typographyRef.current.scrollWidth >
            typographyRef.current.clientWidth ||
            typographyRef.current.scrollHeight >
              typographyRef.current.clientHeight)
        ) {
          setTitle(children);
        } else {
          setTitle('');
        }
      }
      if (typographyRef.current && ref) {
        if (typeof ref === 'function') {
          ref(typographyRef.current);
          return;
        }
        ref.current = typographyRef.current;
      }
    }, [ref, children, typographyRef, title, label]);

    return (
      <Tooltip title={title}>
        <Typography ref={typographyRef} {...props}>
          {children}
        </Typography>
      </Tooltip>
    );
  },
);

export default WrappedTypography;
