import React from 'react';
import isString from 'lodash/isString';
import { FileRejection, useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { useInput } from 'contexts';
import config from 'config';
import Grid, { GridProps } from 'components/Grid';
import FieldHelper from '../FormInputControl/FieldHelper';
import { ReactComponent as Camera } from 'assets/camera.svg';

const ImagePreview = styled('img')(() => ({
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '100%',
}));

export interface ImageContainerProps extends GridProps {
  dragActive?: boolean;
  dragAccept?: boolean;
  dragReject?: boolean;
}

const ImageContainer = styled(Grid, {
  name: 'FileUpload',
  slot: 'ImageContainer',
  overridesResolver: (props, styles) => {
    return [styles.imageContainer];
  },
  shouldForwardProp: (propName: string) =>
    ['dragActive', 'dragAccept', 'dragReject'].indexOf(propName) === -1,
})<ImageContainerProps>(({ dragActive, dragAccept, dragReject, theme }) => ({
  width: '100%',
  cursor: 'pointer',
  border: `1px dashed ${theme.palette.grey['600']}`,
  '&:hover, &:focus': {
    borderColor: theme.palette.secondary.main,
    outline: 'none',
  },
  '&:before': {
    content: '""',
    float: 'left',
    paddingTop: '100%',
  },
  ...(dragActive && {
    borderColor: theme.palette.primary.main,
  }),
  ...(dragAccept && {
    borderColor: theme.palette.success.main,
  }),
  ...(dragReject && {
    borderColor: theme.palette.error.main,
  }),
}));

export interface FileUploaderProps {
  maxSize?: number;
  innerContent?: React.ReactNode;
  helpText?: React.ReactElement;
  value?: string | File;
  accept?: string;
  previewMode?: boolean;
  [prop: string]: any;
}

const FileUploader = ({
  name,
  rules,
  maxSize = config.dataUploadMaxMemorySize,
  helpText,
  value,
  innerContent = <Camera />,
  accept = 'image/*',
  previewMode = false,
  ...props
}: FileUploaderProps) => {
  const { field, error, meta } = useInput({ name, rules });
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    multiple: false,
    maxSize,
    onDropRejected: (fileRejections: FileRejection[]) => {
      alert(fileRejections[0].errors.map((error) => error.message).join('\n'));
    },
  });

  const imgRef = React.useRef<HTMLImageElement>(null);
  React.useEffect(() => {
    if (previewMode && acceptedFiles.length && imgRef.current) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        if (evt.target && evt.target.result && imgRef.current) {
          imgRef.current.src = evt.target.result as string;
        }
      };
    }
    if (acceptedFiles.length && field.value !== acceptedFiles[0]) {
      field.onChange(acceptedFiles[0]);
    }
  }, [imgRef, field, acceptedFiles, previewMode]);
  return (
    <Grid container>
      <ImageContainer
        item
        container
        className={props.className}
        alignItems="center"
        justifyContent="center"
        dragAccept={isDragAccept}
        dragActive={isDragActive}
        dragReject={isDragReject}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {acceptedFiles.length > 0 ? (
          previewMode && isString(value) ? (
            <ImagePreview
              ref={imgRef}
              src={isString(value) ? value : undefined}
              alt="avatar"
            />
          ) : (
            acceptedFiles.map((f) => f.name).join(', ')
          )
        ) : (
          innerContent
        )}
      </ImageContainer>
      <FieldHelper meta={meta} error={error} helpText={helpText} />
    </Grid>
  );
};

export default FileUploader;
