import { createContext, MutableRefObject, ReactNode } from 'react';
import { PopoverOrigin, PopoverPosition } from '@mui/material/Popover';

export interface PopoverItem {
  name: string;
  ref?: MutableRefObject<HTMLElement | null | undefined>;
  content: ReactNode;
  onClose?: () => void;
  onShow?: () => void;
  anchorOrigin?: PopoverOrigin;
  anchorPosition?: PopoverPosition;
  transformOrigin?: PopoverOrigin;
  width?: string;
  pointerEvents?: boolean;
  disableRestoreFocus?: boolean;
}

export interface PopoverContextProps {
  showPopover: (popover: PopoverItem) => void;
  closePopover: (popover: PopoverItem) => void;
}

const PopoverContext = createContext<PopoverContextProps>({
  showPopover: () => null,
  closePopover: () => null,
});

export default PopoverContext;
