import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from 'components/Grid';
import { Button } from 'components/buttons';
import { FileUploader as UnstyledFileUploader } from 'components/inputs';
import { FileUploaderProps } from 'components/inputs/FileUploader';
import { ReactComponent as UploadIcon } from 'assets/upload.svg';

const FileUploader = styled(UnstyledFileUploader)(({ theme }) => ({
  height: 100,
  backgroundColor: theme.palette.grey.A100,
  color: theme.palette.secondary.main,
  borderRadius: 4,
  margin: theme.spacing(1, 0),
  padding: theme.spacing(0, 5),
  border: `1px dashed ${theme.palette.secondary.main}`,
  '&:before': {
    paddingTop: 0,
  },
}));

export interface DropzoneInputProps {
  name: string;
  dropzoneText?: string;
  maxSize?: FileUploaderProps['number'];
  innerContent?: FileUploaderProps['innerContent'];
  helpText?: FileUploaderProps['helpText'];
  accept?: FileUploaderProps['accept'];
  previewMode?: FileUploaderProps['previewMode'];
  [prop: string]: any;
}

const DropzoneInput = ({
  classes,
  dropzoneText,
  accept = 'csv',
  ...props
}: DropzoneInputProps) => (
  <FileUploader
    {...props}
    accept={accept}
    innerContent={
      <Grid container justifyContent="space-between">
        <Grid
          xs
          item
          container
          alignItems="center"
          alignContent="center"
          spacing={4}
        >
          <Grid item>
            <UploadIcon />
          </Grid>
          <Grid item>{dropzoneText}</Grid>
        </Grid>
        <Grid item md={2}>
          <Button color="secondary">choose a file</Button>
        </Grid>
      </Grid>
    }
  />
);

export default DropzoneInput;
