import {
  createRoutine as sagaCreateRoutine,
  UnifiedRoutine,
} from 'redux-saga-routines';
import { Action } from 'redux-actions';

const payloadCreator = (
  payload: Record<string, any> = {},
): Record<string, any> => {
  const { meta, ...finalPayload } = payload;
  return finalPayload;
};

const metaCreator = (payload?: Record<string, any>): Record<string, any> => {
  return payload ? payload.meta : {};
};

type TActionCreator<Payload> = (
  payload?: Payload,
) => Action<Payload | undefined>;

export const createRoutine = <Payload = any>(
  typePrefix: string,
): UnifiedRoutine<TActionCreator<Payload>> =>
  sagaCreateRoutine(typePrefix, payloadCreator, metaCreator) as UnifiedRoutine<
    TActionCreator<Payload>
  >;
