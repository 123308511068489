import React from 'react';
import { styled } from '@mui/material/styles';
import { useInput } from 'contexts';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { Button } from 'components/buttons';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/minus.svg';
import useStepper from './useStepper';
import FormInputControl, { FormInputControlProps } from '../FormInputControl';

const StepButton = styled(Button)(({ theme }) => ({
  minWidth: 32,
  padding: theme.spacing(1),
  borderRadius: 4,
  backgroundColor: theme.palette.grey['300'],
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: theme.palette.grey['500'],
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: theme.palette.grey['500'],
    boxShadow: 'none',
  },
}));

const Value = styled(Typography)(() => ({
  minWidth: 60,
}));

export interface StepInputProps extends Pick<FormInputControlProps, 'margin'> {
  name: string;
  label?: string;
  defaultValue: number;
  [prop: string]: any;
}

const StepInput = ({
  label,
  name,
  rules,
  defaultValue = 0,
  helpText,
  required,
  margin = 'normal',
}: StepInputProps) => {
  const { field, meta, error } = useInput({ name, rules, defaultValue });
  const { increment, decrement } = useStepper({ field, defaultValue });
  return (
    <FormInputControl
      label={label}
      meta={meta}
      required={required}
      name={field.name}
      error={error}
      margin={margin}
      helpText={helpText}
      sx={{ display: 'flex' }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid container item justifyContent="flex-end" xs={4}>
          <Grid item>
            <StepButton onClick={decrement} variant="contained">
              <MinusIcon />
            </StepButton>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" xs={4}>
          <Value align="center" variant="body1">
            {field.value}
          </Value>
        </Grid>
        <Grid container item justifyContent="flex-start" xs={4}>
          <Grid item>
            <StepButton onClick={increment} variant="contained">
              <PlusIcon />
            </StepButton>
          </Grid>
        </Grid>
      </Grid>
    </FormInputControl>
  );
};

export default StepInput;
