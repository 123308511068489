import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import find from 'lodash/find';
import omit from 'lodash/omit';
import { useToggle } from 'hooks';
import { ButtonProps } from 'components/buttons/base';
import { Handler } from 'types';

export interface UseInteractionGroupButtonProps {
  id: string;
  children: ReactNode;
  onClick: (selectValue: string) => Handler | undefined;
}

const useInteractionGroupButton = ({
  id,
  children,
  onClick,
}: UseInteractionGroupButtonProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { isMarked, toggle } = useToggle(false);

  const getPropsAction = (): Partial<ButtonProps> => {
    const selectMode = Array.isArray(children)
      ? children.find(
          (child) => (child as ReactElement).props?.value === selectedValue,
        )
      : (children as ReactElement);
    if (selectMode) {
      return {
        ...omit(selectMode.props, 'onModeClick', 'selected', 'icon'),
        endIcon: selectMode.props.icon,
        onClick: onClick(selectMode.props.value),
      };
    }
    return {};
  };

  const handleMenuItemClick = useCallback(
    (value: string) => {
      setSelectedValue(value);
      localStorage.setItem(id, value);
      toggle(false);
    },
    [id, toggle],
  );

  useEffect(() => {
    const props = localStorage.getItem(id);
    if (children) {
      if (props) {
        const propsButton = props as string;
        const child = Array.isArray(children)
          ? find(children as Array<ReactElement>, ['props.value', propsButton])
          : (children as ReactElement).props.value;
        if (child) {
          setSelectedValue(propsButton);
          return;
        }
      }
      const value = Array.isArray(children)
        ? children.find((item: ReactElement) => item.props?.value)?.props.value
        : (children as ReactElement).props?.value;

      setSelectedValue(value);
    }
  }, [children, id]);

  return {
    anchor: anchorRef,
    open: isMarked,
    selectedValue,
    handleMenuItemClick,
    getPropsAction,
    handleClose: toggle,
    handleToggle: toggle,
  };
};

export default useInteractionGroupButton;
