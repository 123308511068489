import { styled, Theme } from '@mui/material/styles';

export interface DateRangeWeekdayProps {
  weekendDay: boolean;
}

const DateRangeWeekday = styled('div', {
  shouldForwardProp: (propName: string) => {
    return ['weekendDay'].indexOf(propName) === -1;
  },
})<DateRangeWeekdayProps>(
  ({ theme, ...ownerState }: DateRangeWeekdayProps & { theme: Theme }) => ({
    padding: theme.spacing(0.25, 0),
    ...(ownerState.weekendDay && {
      backgroundColor: theme.palette.text.disabled,
      borderRadius: 0,
    }),
  }),
);

export default DateRangeWeekday;
