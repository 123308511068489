import { call, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'store/actions';

export type CallbackSideEffect = (args: {
  payload: any;
  response?: Record<string, any>;
  requestPayload?: Record<string, any>;
  extra?: any;
}) => any;

interface ActionWithSideEffect {
  type: string;
  payload: any;
  response?: Record<string, any>;
  requestPayload?: Record<string, any>;
  meta: {
    callback: CallbackSideEffect;
    extra?: any;
  };
}

type ActionCreator = (...args: any) => Action;

/**
 * Callback Side Effects
 */
function* handleCallback({
  payload,
  response,
  requestPayload,
  meta: { callback, extra },
}: ActionWithSideEffect) {
  if (extra && extra.isAction) {
    yield put((callback as ActionCreator)(payload, requestPayload));
    return;
  }

  yield call(callback, { payload, response, requestPayload, extra });
}

function* callbackSideEffect() {
  yield takeEvery(
    (action: any) => action.meta && action.meta.callback,
    handleCallback,
  );
}

export default callbackSideEffect;
