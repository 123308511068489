import React from 'react';
import { styled } from '@mui/material/styles';
import MuiRadioGroup from '@mui/material/RadioGroup';
import { useInput } from 'contexts';
import { Color } from 'types';
import FormInputControl, { FormInputControlProps } from '../FormInputControl';

export interface FormControlProps {
  name: string;
  variant?: Color;
  helpText?: string;
  children?: React.ReactNode;
  margin?: FormInputControlProps['margin'];
  required?: boolean;
  [prop: string]: any;
}

const RadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const FormControl = ({
  children,
  label,
  name,
  rules,
  helpText,
  required,
  defaultValue = '',
  margin = 'normal',
}: FormControlProps) => {
  const { field, error, meta } = useInput({ name, rules, defaultValue });
  return (
    <FormInputControl
      label={label}
      meta={meta}
      margin={margin}
      required={required}
      name={field.name}
      error={error}
      helpText={helpText}
    >
      <RadioGroup id={name} aria-label={label} {...field}>
        {children}
      </RadioGroup>
    </FormInputControl>
  );
};

export default FormControl;
