import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Smf } from 'types';

export interface SmfPayload extends PageQuery {
  id: number;
  smf?: string;
}

const list = (query: SmfPayload, token: string) =>
  request<Empty, PageResponse<Smf>, Empty>(
    `wm-objects/smf/`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const smfServices = {
  list,
};

export default smfServices;
