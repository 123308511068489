import { ReactNode } from 'react';
import { styled, Theme } from '@mui/material/styles';

export interface PolygonProps {
  topLeft?: string;
  topRight?: string;
  bottomRight?: string;
  bottomLeft?: string;
}

export interface DevelopIconProps {
  left?: number;
  top?: number;
  polygonProps?: PolygonProps;
  pb?: number;
  pt?: number;
  pl?: number;
  pr?: number;
  children: ReactNode;
  align?: 'right' | 'left';
  textSize?: number;
}

const DevelopmentIcon = styled('div')({
  position: 'relative',
});

const DevelopIconContainer = styled('div', {
  shouldForwardProp: (propName: string) =>
    ['top', 'left', 'polygonProps'].indexOf(propName) === -1,
})<DevelopIconProps & { theme?: Theme }>(
  ({ theme, top, left, polygonProps, pb, pt, pl, pr, align, textSize }) => ({
    '--f': `${left}px`,
    '--t': `${top}px`,
    position: 'absolute',
    inset: 'var(--t) calc(-1*var(--f)) auto auto',
    padding: `${pt}px ${pr}px ${pb}px ${pl}px`,
    clipPath: `polygon(${polygonProps?.topLeft}, ${polygonProps?.topRight}, ${polygonProps?.bottomRight}, ${polygonProps?.bottomLeft})`,
    background: theme.palette.primary.main,
    transform: align === 'left' ? 'rotate(-43deg)' : 'rotate(58deg)',
    fontSize: textSize,
    color: theme.palette.primary.contrastText,
    zIndex: 1,
  }),
);

const DevelopmentRibbon = ({
  children,
  left = 32,
  top = -12,
  polygonProps = {},
  pt = 5,
  pr = 16,
  pb = 10,
  pl = 15,
  textSize = 8,
  align = 'left',
}: DevelopIconProps) => {
  const defaultPolygonProps = {
    topLeft: '0% 0%',
    topRight: '74% 5%',
    bottomRight: '102% 75%',
    bottomLeft: '0% 68%',
  };
  return (
    <DevelopmentIcon>
      <DevelopIconContainer
        pb={pb}
        pr={pr}
        pt={pt}
        pl={pl}
        left={left}
        top={top}
        align={align}
        textSize={textSize}
        polygonProps={{ ...defaultPolygonProps, ...polygonProps }}
      >
        In development
      </DevelopIconContainer>
      {children}
    </DevelopmentIcon>
  );
};

export default DevelopmentRibbon;
